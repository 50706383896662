import {
  FormControl,
  Grid,
  InputLabel,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material';
import { useFormikContext } from 'formik';
import { ErrorMessage } from 'components';
import { CargoStatus, Currencies, ICargo } from 'global';
import { useTranslation } from 'hooks/useTranslation';
import { useGetUsers } from 'hooks/useGetUsers';
import { Documents } from './_Documents';
import { ClientData } from './_ClientData';
import { Accessories } from './_Accessories';
import { MainInfoProps } from './_partials.types';

export const MainInfo = ({ edit }: MainInfoProps) => {
  const {
    values: {
      guardian,
      status,
      description,
      price: { currency, value },
      distance
    },
    handleChange,
    setFieldValue,
    errors
  } = useFormikContext<ICargo>();
  const { t } = useTranslation();
  const { data } = useGetUsers({});
  const users = data || [];

  // const onChange = (event: React.ChangeEvent) => {
  //   const target = event.target as HTMLInputElement;
  //   const file: File = (target.files as FileList)[0];
  //   setFieldValue('order', file);
  // };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5" component="span">
          {t('subheaders.mainInfo')}
        </Typography>
      </Grid>
      <Grid item xs={12} md={3}>
        <TextField
          fullWidth
          size="small"
          label={t('inputs.price')}
          name="price.value"
          value={value}
          onChange={handleChange}
          error={!!errors.price?.value}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <FormControl sx={{ width: '100px' }} size="small">
                  <InputLabel>{t('inputs.currency')}</InputLabel>
                  <Select
                    value={currency}
                    name="price.currency"
                    label={t('inputs.currency')}
                    onChange={handleChange}
                  >
                    {Object.values(Currencies).map((el) => (
                      <MenuItem key={el} value={el}>
                        {el}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </InputAdornment>
            )
          }}
        />
        <ErrorMessage name="price.value" />
      </Grid>
      <Grid item xs={12} md={3}>
        <FormControl fullWidth size="small">
          <InputLabel>{t('inputs.guardian')}</InputLabel>
          <Select
            value={guardian.id}
            name="guardian"
            error={!!errors.guardian?.id}
            label={t('inputs.guardian')}
            onChange={(e) => {
              const guardianUser = users.find((el) => el.id === e.target.value);
              if (guardianUser) {
                setFieldValue('guardian', {
                  id: guardianUser.id,
                  name: guardianUser.details.name
                });
              }
            }}
          >
            {users.map((el) => (
              <MenuItem key={el.userId} value={el.id}>
                {el.details.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <ErrorMessage name="guardian.id" />
      </Grid>
      <Grid item xs={12} md={3}>
        <FormControl fullWidth size="small">
          <InputLabel>{t('inputs.status')}</InputLabel>
          <Select value={status} name="status" label={t('inputs.status')} onChange={handleChange}>
            {Object.values(CargoStatus).map((el) => (
              <MenuItem key={el} value={el}>
                {el}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      {/* <Grid item xs={12} md={6}>
            <Input type="file" name="order" onChange={onChange} />
            {order?.name || 'Brak pliku'}
          </Grid> */}
      <Grid item xs={12} md={3}>
        <TextField
          fullWidth
          label={t('inputs.distance')}
          name="distance"
          size="small"
          value={distance}
          onChange={handleChange}
          error={!!errors.distance}
        />
        <ErrorMessage name="distance" />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          size="small"
          label={t('inputs.description')}
          multiline
          minRows={2}
          name="description"
          value={description}
          onChange={handleChange}
        />
        <ErrorMessage name="description" />
      </Grid>
      <Accessories />
      <Documents />
      <ClientData />
    </Grid>
  );
};
