import { Card, Grid, Stack, Box } from '@mui/material';

interface StandardLayoutProps {
  search?: JSX.Element;
  button?: JSX.Element;
  content: JSX.Element;
  breadcrumbs?: JSX.Element;
}

export const StandardLayout = ({
  search = undefined,
  button = undefined,
  content,
  breadcrumbs = undefined
}: StandardLayoutProps): JSX.Element => (
  <Stack rowGap={1}>
    {breadcrumbs}
    <Card variant="outlined">
      <Stack rowGap={1} p={1}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" pt={1}>
          <Box>{button}</Box>
          <Stack direction="row">{search}</Stack>
        </Stack>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            {content}
          </Grid>
        </Grid>
      </Stack>
    </Card>
  </Stack>
);
