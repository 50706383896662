import { FormControl, Grid, MenuItem, Select, InputLabel, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { AdditionalCarStatus, CarStatus, ICar } from 'global';
import { useTranslation } from 'hooks/useTranslation';

export const Status = () => {
  const {
    values: { status, additionalStatus },
    handleChange
  } = useFormikContext<ICar>();
  const { t } = useTranslation();

  return (
    <Grid container rowGap={2}>
      <Grid item xs={12}>
        <Typography component="span" variant="h5">
          {t('subheaders.status')}
        </Typography>
      </Grid>
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth size="small">
            <InputLabel>{t('carStatus.title')}</InputLabel>
            <Select
              value={status}
              name="status"
              label={t('carStatus.title')}
              onChange={handleChange}
            >
              {Object.keys(CarStatus).map((el) => (
                <MenuItem key={el} value={el}>
                  {t(`carStatus.${el}`)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth size="small">
            <InputLabel>{t('additionalCarStatus.title')} </InputLabel>
            <Select
              value={additionalStatus}
              name="additionalStatus"
              label={t('additionalCarStatus.title')}
              onChange={handleChange}
            >
              {Object.keys(AdditionalCarStatus).map((el) => (
                <MenuItem key={el} value={el}>
                  {t(`additionalCarStatus.${el}`)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </Grid>
  );
};
