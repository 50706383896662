import { useEffect } from 'react';
import { nanoid } from '@reduxjs/toolkit';
import {
  Grid,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  InputAdornment,
  RadioGroup,
  FormControlLabel,
  Radio,
  MenuItem,
  Divider
} from '@mui/material';
import { useFormikContext } from 'formik';
import { AppCard, ErrorMessage, LoadingUnloadingInfo } from 'components';
import { Currencies, IOrder, ICargo, CargoEvent } from 'global';
import { useTranslation } from 'hooks/useTranslation';
import { useAppSelector } from 'store/hooks';
import { MultipleAutocomplete } from './MultipleAutocomplete';

interface MainInfoProps {
  edit?: boolean;
}

export const MainInfo = ({ edit = false }: MainInfoProps) => {
  const {
    values: {
      number,
      createdAt,
      cargo,
      orderSettings: {
        paymentComments,
        paymentTerm: { term, toChange }
      }
      // car: { plates, type, ownCar, carrier }
    },
    handleChange,
    errors,
    setFieldValue
  } = useFormikContext<IOrder>();
  const { t } = useTranslation();

  // const company = useAppSelector((store) => store.company.company);

  // const settings = company.orderSettings;

  // useEffect(() => {
  //   const numberPrice = Number(price);
  //   const pricesSum = cargo.reduce(
  //     (accumulator, currentValue) => accumulator + Number(currentValue.price),
  //     0
  //   );
  //   setFieldValue('comission', pricesSum - numberPrice);
  // }, [cargo, price]);

  // const sumCash = cargo.reduce(
  //   (accumulator, currentValue) => accumulator + Number(currentValue.price),
  //   0
  // );

  useEffect(() => {
    setFieldValue('id', nanoid());
    // setFieldValue('createdBy', loggedUser.details.name);
    // setFieldValue('user', loggedUser.id);
    // setFieldValue('orderSettings', settings);
    setFieldValue('number', getOrderNumber());
  }, []);

  const getOrderNumber = () => {
    const date = new Date();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    // const { prefix } = settings.numberSystem;

    // const number = orders.filter((el) => el.number.includes(`/${month}/${year}`)).length;

    // return `${prefix}/${number + 1}/${month}/${year}`;
    return `ZL/${1}/${month}/${year}`;
  };

  return (
    <AppCard>
      <>
        <Typography fontWeight="bold">{t('details.mainInfo')}</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <TextField
              fullWidth
              disabled
              label="createdAt"
              value={createdAt}
              onChange={handleChange}
            />
          </Grid>
          {/* <Grid item xs={12} md={3}>
            <TextField
              fullWidth
              disabled
              label="user"
              value={userData.details.name}
              onChange={handleChange}
            />
          </Grid> */}
          <Grid item xs={12} md={3}>
            <TextField
              fullWidth
              label="number"
              name="number"
              value={number}
              onChange={handleChange}
              required
              disabled
              // error={!!errors.number}
            />
            {/* <ErrorMessage name="id" /> */}
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              minRows={2}
              label="paymentComments"
              name="paymentComments"
              value={paymentComments}
              onChange={handleChange}
              required

              // error={!!errors.price}
            />
            {/* <ErrorMessage name="price" /> */}
          </Grid>
          {/* <Grid item xs={12} md={3}>
            <TextField
              fullWidth
              disabled={!settings.paymentTerm.toChange}
              label="paymentTermin"
              name="orderSettings.paymentTerm.term"
              value={term}
              onChange={handleChange}
              required
              helperText="to set up by admin or to cahnge if needed/ when user got premission"
              // error={!!errors.price}
            />
            <ErrorMessage name="price" />
          </Grid> */}
          {/* <AssignCar /> */}
          <Grid item xs={12}>
            <MultipleAutocomplete setCargos={(items: ICargo[]) => setFieldValue('cargo', items)} />
          </Grid>
          {/* <Grid item xs={12} container rowGap={1}> */}
          {/* {cargo.map((el, index) => (
              <Grid key={el.id} item xs={12} container spacing={2}>
                <Grid item xs={2}>
                  {el.id}
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    disabled
                    size="small"
                    label="Client price"
                    value={`${el.prices.cargo.value} ${el.prices.cargo.currency}`}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    size="small"
                    label="car price"
                    name={`cargo[${index}].prices.car.value`}
                    value={cargo[index].prices.car.value}
                    onChange={(e) => {
                      if (cargo[index].prices.cargo.currency === cargo[index].prices.car.currency) {
                        setFieldValue(
                          `cargo[${index}].prices.comission.value`,
                          Number(cargo[index].prices.cargo.value) - Number(e.target.value)
                        );
                      }
                      handleChange(e);
                    }}
                    required
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <FormControl variant="filled" size="small" sx={{ width: '100px' }}>
                            <InputLabel>Waluta</InputLabel>
                            <Select
                              value={cargo[index].prices.car.currency}
                              name={`cargo[${index}].prices.car.currency`}
                              label="Waluta"
                              onChange={handleChange}
                            >
                              {Object.values(currencies).map((el) => (
                                <MenuItem key={el} value={el}>
                                  {el}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </InputAdornment>
                      )
                    }} */}
          {/* // error={!!errors.price}
                  /> */}
          {/* </Grid> */}
          {/* </Grid>
            ))} */}
          <Grid item xs={12}>
            suma kosztow i prowizja - TODO
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        {/* <Grid item xs={12}>
          {cargo.length > 0 && (
            <Grid container>
              <Grid item xs={12}>
                <Typography>Załadunki</Typography>
                <Grid container spacing={1}>
                  {cargo.map((el) =>
                    el.events
                      .filter((el) => el.type === CargoEvent.loading)
                      .map((el, index) => (
                        <Grid key={el.id} item xs={12} md={4}>
                          <AppCard>
                            <LoadingUnloadingInfo data={el} />
                          </AppCard>
                        </Grid>
                      ))
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography>Rozładunki</Typography>
                <Grid container spacing={1}>
                  {cargo.map((el) =>
                    el.events
                      .filter((el) => el.type === CargoEvent.unloading)
                      .map((el, index) => (
                        <Grid key={el.id} item xs={12} md={4}>
                          <AppCard>
                            <LoadingUnloadingInfo data={el} />
                          </AppCard>
                        </Grid>
                      ))
                  )}
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid> */}
        {/* </Grid> */}
      </>
    </AppCard>
  );
};
