import { Button, Stack } from '@mui/material';
import { useTranslation } from 'hooks/useTranslation';
import { useFormikContext } from 'formik';
import { ICar } from 'global';
import { AddCarForm } from './AddCarFrom';

export const AddCar = (): JSX.Element => {
  const { t } = useTranslation();
  const { isSubmitting } = useFormikContext<ICar>();

  return (
    <Stack>
      <Stack rowGap={2}>
        <AddCarForm />
        <Button disabled={isSubmitting} variant="contained" form="carAddForm" type="submit">
          {t('buttons.save')}
        </Button>
      </Stack>
    </Stack>
  );
};
