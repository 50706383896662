import { useLocation } from 'react-router';
import { FullDialog, Loader, AlertMessage, AppCard, StandardView } from 'components';
import {
  Grid,
  Typography,
  Button,
  Stack,
  Breadcrumbs,
  Link as MuiLink,
  ButtonGroup
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'hooks/useTranslation';
import { Form, FormikProvider, useFormik } from 'formik';
import { IRound, initialRound } from 'global';
import { toast } from 'react-hot-toast';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import roundService, { EditRoundProps } from 'services/round.service';
import { RoundActions, roundActions } from './Round.helpers';
import { roundValidationSchema } from '../Rounds/components/AddRound/AddRound.conts';
import { Shipments } from './components/Shipments';
import { BillsCollapse } from './components/BillsCollapse';

export const Round = (): JSX.Element => {
  const [edit, setEdit] = useState<RoundActions | null>(null);
  const location = useLocation();
  const roundId = location.pathname.replace('/rounds/', '');
  const { t, d } = useTranslation();

  const { data, isLoading, error, isFetching, refetch } = useQuery(
    ['round', roundId],
    () => roundService.getRound(roundId),
    {
      refetchOnWindowFocus: false,
      onSuccess(res) {
        if (res) {
          formik.setValues(res);
        }
      },
      onError() {
        toast.error(t('errors.getData'));
      }
    }
  );

  const hadleOpen = (action: RoundActions) => setEdit(action);
  const handleClose = () => {
    refetch();
    setEdit(null);
  };

  const formik = useFormik<IRound>({
    initialValues: data || initialRound,
    validationSchema: roundValidationSchema(t),
    onSubmit: async (values) => {
      const editedRound: EditRoundProps = {
        ...values,
        shipments: values.shipments.map((el) => el.id)
      };

      const res = await roundService.editRound(editedRound).catch(() => {
        toast.error(t('errors.editRound'));
      });
      if (!res) return;

      toast.success(t('success.roundEdited'));
      formik.resetForm();
      handleClose();
      refetch();
    }
  });

  if (isLoading || isFetching) return <Loader />;
  if (error || !data) return <AlertMessage message={t('errors.getData')} status="error" />;
  const roundActionsItems = roundActions(t, formik.isSubmitting, data, handleClose);
  const dialogProps = roundActionsItems.find((el) => el.type === edit);

  const {
    createdAt,
    updatedAt,
    driver: { name },
    car: { plates },
    inRoadFrom,
    inRoadTo,
    shipments,
    bills
  } = data;

  const howManyDays = Math.floor((Date.parse(inRoadTo) - Date.parse(inRoadFrom)) / 86400000) + 1;

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit} id="roundEditForm">
        <StandardView
          breadcrumbs={
            <Breadcrumbs>
              <MuiLink
                fontWeight="bold"
                component={Link}
                underline="hover"
                color="inherit"
                to="/rounds"
              >
                {t('headers.rounds')}
              </MuiLink>
              <Typography color="text.primary">{data.id}</Typography>,
            </Breadcrumbs>
          }
          createdAt={createdAt}
          updatedAt={updatedAt}
          buttons={
            <ButtonGroup variant="contained">
              {roundActionsItems.map(({ type, title }) => (
                <Button key={title} onClick={() => hadleOpen(type)} variant="contained">
                  {title}
                </Button>
              ))}
            </ButtonGroup>
          }
          content={
            <>
              <Grid item xs={12} md={6}>
                <AppCard fullHeight>
                  <Stack>
                    <Typography>
                      {t('inputs.driver')}: {name}
                    </Typography>
                    <Typography>
                      {t('inputs.car')}: {plates}
                    </Typography>
                  </Stack>
                </AppCard>
              </Grid>
              <Grid item xs={12} md={6}>
                <AppCard fullHeight>
                  <Stack>
                    <Typography>
                      {t('inputs.timeMin')}:{d(inRoadFrom)}
                    </Typography>
                    <Typography>
                      {t('inputs.timeMin')}:{d(inRoadTo)}
                    </Typography>
                    <Typography>
                      {t('inputs.daysQuantity')}: {howManyDays}
                      {t('inputs.days')}
                    </Typography>
                  </Stack>
                </AppCard>
              </Grid>
              <Shipments shipments={shipments} />
              <BillsCollapse bills={bills} />
              zliczone zyski z shipments, zliczone wydatki z bills
              {dialogProps && (
                <FullDialog
                  handleClose={handleClose}
                  open={Boolean(edit)}
                  title={dialogProps.title}
                >
                  <Stack rowGap={3}>{dialogProps.chlidren}</Stack>
                </FullDialog>
              )}
            </>
          }
        />
      </Form>
    </FormikProvider>
  );
};
