import { Values } from 'global';

export const companyRoles = {
  spedition: 'spedition',
  transport: 'transport',
  both: 'both'
};

export type CompanyRole = Values<typeof companyRoles>;

export const userRoles = {
  driver: 'driver',
  user: 'user',
  admin: 'admin',
  accountant: 'accountant'
};

export type UserRole = Values<typeof userRoles>;

export const policies = {
  canView: 'can-view',
  canCreate: 'can-create',
  canDelete: 'can-delete',
  canEdit: 'can-edit'
} as const;

export type Policy = Values<typeof policies>;

export const permissions: Permissions = {
  [companyRoles.spedition]: [
    policies.canView,
    policies.canCreate,
    policies.canDelete,
    policies.canEdit
  ],
  [companyRoles.transport]: [
    policies.canView,
    policies.canCreate,
    policies.canDelete,
    policies.canEdit
  ],
  [companyRoles.both]: [policies.canView, policies.canCreate, policies.canDelete, policies.canEdit],
  [userRoles.admin]: [policies.canView, policies.canCreate, policies.canDelete, policies.canEdit],
  [userRoles.user]: [policies.canView, policies.canCreate, policies.canDelete, policies.canEdit],
  [userRoles.driver]: [policies.canView, policies.canCreate, policies.canDelete, policies.canEdit],
  [userRoles.accountant]: [
    policies.canView,
    policies.canCreate,
    policies.canDelete,
    policies.canEdit
  ]
};

export type Permissions = Record<UserRole, Policy[]>;

// import { Values } from 'global';

// export const roles = {
//   admin: 'admin',
//   speditior: 'speditor',
//   dispo: 'dispo',
//   both: 'both',
//   invoices: 'invocies'
// };

// export type UserRole = Values<typeof roles>;

// export const policies = {
//   canView: 'can-view',
//   canCreate: 'can-create',
//   canDelete: 'can-delete',
//   canEdit: 'can-edit'
// } as const;

// export type Policy = Values<typeof policies>;

// export const permissions: Permissions = {
//   [roles.admin]: [policies.canView, policies.canCreate, policies.canDelete, policies.canEdit],
//   [roles.speditior]: [policies.canView, policies.canCreate, policies.canDelete, policies.canEdit],
//   [roles.dispo]: [policies.canView, policies.canCreate, policies.canDelete, policies.canEdit],
//   [roles.both]: [policies.canView, policies.canCreate, policies.canDelete, policies.canEdit],
//   [roles.invoices]: [policies.canView, policies.canCreate, policies.canDelete, policies.canEdit]
// };

// export type Permissions = Record<UserRole, Policy[]>;
