import { useFormikContext } from 'formik';
import { ICompany } from 'global';
import { useTranslation } from 'hooks/useTranslation';
import { Grid, TextField, Typography, Button } from '@mui/material';
import { AppCard } from 'components';

interface MainInfoProps {
  disabled: boolean;
}

export const Tiers = ({ disabled }: MainInfoProps) => {
  const {
    values: { tier, type, payment, paidTill },
    handleChange
  } = useFormikContext<ICompany>();
  const { t } = useTranslation();

  return (
    <Grid item xs={12}>
      <AppCard>
        <>
          <Typography mb={3} fontWeight="bold">
            {t('subheaders.tiers')}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                size="small"
                label={t('inputs.tier')}
                name="tier"
                value={t(`tiers.${tier}`)}
                onChange={handleChange}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                size="small"
                label={t('inputs.companyType')}
                value={t(`companyType.${type}`)}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                size="small"
                label={t('inputs.payment')}
                value={t(`paymentTerm.${payment}`)}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                size="small"
                label={t('inputs.paidTill')}
                value={paidTill}
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <Button variant="outlined">{t('inputs.changeOrUpgrade')}</Button>
            </Grid>
          </Grid>
        </>
      </AppCard>
    </Grid>
  );
};
