import {
  Collapse as MuiCollapse,
  Grid,
  Stack,
  Typography,
  TableRow,
  TableCell,
  useTheme
} from '@mui/material';
import { StandardTable } from 'components';
import { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { IRound } from 'global';
import { useTranslation } from 'hooks/useTranslation';
import Card from '@mui/material/Card';
import { tableRowsColor } from 'global/utils/_colors';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { Link } from 'react-router-dom';
import { tableHeaders } from './Collapse.const';

interface CollapseProps {
  positions: IRound[];
}

export const Collapse = ({ positions }: CollapseProps): JSX.Element => {
  const [open, setOpen] = useState(false);
  const { t, d } = useTranslation();
  const theme = useTheme();

  return (
    <Grid item xs={12}>
      <Card variant="outlined">
        <Stack direction="row" alignItems="center">
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
          <Typography>
            {t('headers.rounds')} ({positions.length})
          </Typography>
        </Stack>
        <MuiCollapse in={open} timeout="auto" unmountOnExit>
          <StandardTable
            headers={() => tableHeaders(t)}
            items={positions.map((position, index) => {
              const {
                id,
                inRoadFrom,
                inRoadTo,
                kmCountEnd,
                kmCountStart,
                driver: { name }
              } = position;

              return (
                <TableRow key={id} sx={tableRowsColor(index, theme)}>
                  <TableCell component="th" scope="row" width="25%">
                    {name}
                  </TableCell>
                  <TableCell component="th" scope="row" width="15%">
                    {kmCountStart}
                  </TableCell>
                  <TableCell component="th" scope="row" width="15%">
                    {kmCountEnd}
                  </TableCell>
                  <TableCell component="th" scope="row" width="40%">
                    {d(inRoadFrom)}-{d(inRoadTo)}
                  </TableCell>
                  <TableCell component="th" scope="row" align="right" width="5%">
                    <Link to={`../../rounds/${id}`} rel="noopener noreferrer">
                      <ManageSearchIcon />
                    </Link>
                  </TableCell>
                </TableRow>
              );
            })}
          />
        </MuiCollapse>
      </Card>
    </Grid>
  );
};
