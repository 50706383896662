import { Translation } from 'hooks/useTranslation';
import { Stack, Button } from '@mui/material';
import { IRound } from 'global';
import { AddRound } from '../Rounds/components/AddRound';
import { Bill } from '../Rounds/components/AddRound/partials';

export type RoundActions = 'editRound' | 'addBill';

export const roundActions = (
  t: Translation,
  isSubmitting: boolean,
  data: IRound,
  handleClose: () => void
) => [
  {
    type: 'editRound' as RoundActions,
    chlidren: (
      <Stack rowGap={2}>
        <AddRound edit />
        <Button disabled={isSubmitting} form="roundEditForm" type="submit" variant="contained">
          {t('buttons.save')}
        </Button>
      </Stack>
    ),
    title: t('roundActions.editRound')
  },
  {
    type: 'addBill' as RoundActions,
    chlidren: (
      <Stack rowGap={2}>
        <Bill round={data} handleClose={handleClose} />
      </Stack>
    ),
    title: t('roundActions.addBill')
  }
];
