import { Divider, Grid } from '@mui/material';
import { MainInfo, Positions, Reciver, Sender, Summary } from './partials';

export const AddInvoiceForm = () => (
  <Grid container spacing={2}>
    <MainInfo />
    <Sender />
    <Reciver />
    <Positions />
    <Summary />
    <Divider />
  </Grid>
);
