import { UserStatus } from 'global';
import { Chip } from '@mui/material';
import { Translation } from 'hooks/useTranslation';

const getChipColor = (status: UserStatus) => {
  switch (true) {
    case status === UserStatus.active:
      return 'success';
    case status === UserStatus.inactive:
      return 'error';
    default:
      return 'primary';
  }
};

export const getStatusChip = (status: UserStatus, t: Translation) => (
  <Chip color={getChipColor(status)} label={t(`userStatus.${status}`)} sx={{ width: '100%' }} />
);
