import { Button, Grid, Stack, Typography } from '@mui/material';
import { nanoid } from '@reduxjs/toolkit';
import { useFormikContext } from 'formik';
import { AppCard, EmptyStateComponent } from 'components';
import { ICarrier, initialCarrierCar } from 'global';
import { CarrierCarElement } from './_CarrierCarElement';

export const CarrierCar = () => {
  const {
    values: { cars },
    setFieldValue
  } = useFormikContext<ICarrier>();

  const addCarrierCar = () =>
    setFieldValue('cars', [...cars, { ...initialCarrierCar, id: nanoid() }]);

  return (
    <AppCard>
      <Stack rowGap={2}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h6" component="span">
            Cars
          </Typography>
          <Button variant="contained" onClick={addCarrierCar}>
            Add Car
          </Button>
        </Stack>
        <Grid container rowGap={2}>
          {cars.length > 0 ? (
            cars.map((el) => <CarrierCarElement key={el.id} car={el} />)
          ) : (
            <EmptyStateComponent small text="No cars added" />
          )}
        </Grid>
      </Stack>
    </AppCard>
  );
};
