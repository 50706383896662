import { CargoData, CargoTypes, PalletsTypes } from 'global';
import {
  Grid,
  Stack,
  Typography,
  Switch,
  TextField,
  FormControl,
  InputLabel,
  Select,
  IconButton,
  MenuItem,
  Box,
  InputAdornment
} from '@mui/material';
import { AppCard } from 'components';
import { useFormikContext } from 'formik';
import ClearIcon from '@mui/icons-material/Clear';
import { useTranslation } from 'hooks/useTranslation';
import { DetailsProps } from './_partials.types';

export const Details = ({ data }: DetailsProps) => {
  const {
    values: { details },
    handleChange,
    setFieldValue
  } = useFormikContext<CargoData>();
  const { t } = useTranslation();

  const index = details.findIndex((el) => el.id === data.id);

  const deleteItem = () =>
    setFieldValue(
      'details',
      details.filter((el) => el.id !== data.id)
    );

  return (
    <Grid item xs={12}>
      <AppCard>
        <Stack direction="row" alignItems="center">
          <Grid container spacing={1}>
            <Grid item xs={12} md={2}>
              <FormControl size="small" fullWidth>
                <InputLabel>{t('inputs.type')}</InputLabel>
                <Select
                  value={details[index].cargoType}
                  name={`details[${index}].cargoType`}
                  label={t('inputs.type')}
                  onChange={handleChange}
                >
                  {Object.values(CargoTypes).map((el) => (
                    <MenuItem key={el} value={el}>
                      {el}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={2}>
              <Stack>
                <FormControl size="small">
                  <InputLabel>{t('inputs.palletsType')}</InputLabel>
                  <Select
                    value={details[index].palletsType}
                    name={`details[${index}].palletsType`}
                    label={t('inputs.palletsType')}
                    onChange={handleChange}
                  >
                    {Object.values(PalletsTypes).map((el) => (
                      <MenuItem key={el} value={el}>
                        {el}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {details[index].palletsType === 'other' && (
                  <TextField
                    fullWidth
                    size="small"
                    label={t('inputs.dimensions')}
                    name={`details[${index}].defaultSize`}
                    value={details[index].defaultSize}
                    onChange={handleChange}
                  />
                )}
              </Stack>
            </Grid>
            <Grid item xs={12} md={1}>
              <TextField
                fullWidth
                size="small"
                label={t('inputs.quantity')}
                name={`details[${index}].quantity`}
                value={details[index].quantity}
                onChange={handleChange}
                required
                // error={!!errors.estimatedKm}
              />
            </Grid>
            <Grid item xs={12} md={1}>
              <TextField
                fullWidth
                size="small"
                label={t('inputs.weight')}
                name={`details[${index}].weight`}
                value={details[index].weight}
                onChange={handleChange}
                required
                InputProps={{
                  endAdornment: <InputAdornment position="end">KG</InputAdornment>
                }}
                // error={!!errors.estimatedKm}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                fullWidth
                size="small"
                label={t('inputs.ref')}
                name={`details[${index}].ref`}
                value={details[index].ref}
                onChange={handleChange}
                // error={!!errors.estimatedKm}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                size="small"
                label={t('inputs.comments')}
                name={`details[${index}].comments`}
                value={details[index].comments}
                onChange={handleChange}
                // error={!!errors.estimatedKm}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Stack rowGap={1}>
                <Stack direction="row" alignItems="center">
                  <Typography>{t('inputs.adr')}</Typography>
                  <Switch
                    size="small"
                    checked={details[index].adr}
                    onChange={handleChange}
                    name={`details[${index}].adr`}
                  />
                </Stack>
                {details[index].adr && (
                  <TextField
                    fullWidth
                    disabled={!details[index].adr}
                    size="small"
                    label={t('inputs.adrCode')}
                    name={`details[${index}].adrCode`}
                    value={details[index].adrCode}
                    onChange={handleChange}
                    // error={!!errors.estimatedKm}
                  />
                )}
              </Stack>
            </Grid>
            <Grid item xs={12} md={2}>
              <Stack rowGap={1}>
                <Stack direction="row" alignItems="center">
                  <Typography>{t('inputs.change')}</Typography>
                  <Switch
                    size="small"
                    checked={details[index].forChange}
                    onChange={handleChange}
                    name={`details[${index}].forChange`}
                  />
                </Stack>
                {details[index].forChange && (
                  <TextField
                    fullWidth
                    size="small"
                    label={t('inputs.quantity')}
                    name={`details[${index}].forChangeQuantity`}
                    value={details[index].forChangeQuantity}
                    onChange={handleChange}
                    // error={!!errors.estimatedKm}
                  />
                )}
              </Stack>
            </Grid>
          </Grid>
          <Box width="40px">
            {index !== 0 && (
              <IconButton onClick={deleteItem}>
                <ClearIcon />
              </IconButton>
            )}
          </Box>
        </Stack>
      </AppCard>
    </Grid>
  );
};
