import axios from 'axios';
import { IComission, IShipment } from 'global';
import { toast } from 'react-hot-toast';
import authHeader from './auth-header';
import { servicesEnv } from './const';

interface GetShipmentsProps {
  number: string;
  user: string;
  status: string;
  company: string;
  sortBy: string;
  projectBy: string;
  limit: number;
  page: number;
}

export type AddNewShipment = Omit<IShipment, 'cargo' | 'eventsOrder' | 'comission'> & {
  cargo: string[];
  eventsOrder: { loading: string[]; unlodaing: string[] };
  comission?: IComission;
};

const API_URL = `${servicesEnv}v1/api/shipment`;

class ShipmentService {
  getShipments = async ({
    number = '',
    user = '',
    status = '',
    company = '',
    sortBy = '',
    projectBy = '',
    limit = 20,
    page = 1
  }: GetShipmentsProps): Promise<IShipment[]> => {
    const res = await axios.get(
      `${API_URL}?${new URLSearchParams({
        number,
        user,
        status,
        company,
        sortBy,
        projectBy,
        limit: String(limit),
        page: String(page)
      })}`,
      { headers: authHeader() }
    );

    if (res.data.error) {
      toast.error(res.data.error);
      return [];
    }

    return res.data.results;
  };
  getShipment = async (id: string): Promise<IShipment | undefined> => {
    const res = await axios.get(`${API_URL}/${id}`, { headers: authHeader() });

    if (res.data.error) {
      toast.error(res.data.error);
      return undefined;
    }

    return res.data;
  };
  addShipment = async (shipment: AddNewShipment): Promise<string | null> => {
    const res = await axios.post(API_URL, shipment, { headers: authHeader() });

    if (res.data.error) {
      toast.error(res.data.error);
      return null;
    }

    return res.data.id;
  };
  editShipment = async (shipment: IShipment): Promise<boolean> => {
    const res = await axios.patch(`${API_URL}/${shipment.id}`, shipment, { headers: authHeader() });

    if (res.data.error) {
      toast.error(res.data.error);
      return false;
    }

    return true;
  };
}
export default new ShipmentService();
