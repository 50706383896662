import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IDecodedToken } from 'global';
import { initialState } from './authReducer.const';

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logIn: (state, action: PayloadAction<IDecodedToken>) => {
      return { ...state, isLogged: true, data: action.payload };
    },
    logOut: (state) => {
      localStorage.removeItem('cc');
      localStorage.removeItem('cc-refresh');
      return { ...state, isLogged: false, data: null };
    }
  }
});

export const { logIn, logOut } = authSlice.actions;

export default authSlice.reducer;
