import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { EmptyStateComponent, Loader } from 'components';
import { ICarrier } from 'global';
import { useTranslation } from 'hooks/useTranslation';
import carrierService from 'services/carrier-service';
import { useAppSelector } from 'store/hooks';
import { filterCarriers } from '../Filters/filters.helpers';
import { CarriersTable } from './CarriersTable/CarriersTable';

export const CarriersList = () => {
  const [carriersList, setCarriersList] = useState<ICarrier[]>([]);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  const getCarriers = async () => {
    const carriers = await carrierService
      .getCarriers()
      .catch(() => toast.error('problem przy pobieraniu przewoźników'));

    if (typeof carriers !== 'string') {
      setCarriersList(carriers);
    }
    setLoading(false);
  };

  useEffect(() => {
    getCarriers();
  }, []);

  const filters = useAppSelector((store) => store.filters);
  const carriersToShow = filterCarriers(carriersList, filters);

  if (loading) {
    return <Loader />;
  }

  return carriersList.length > 0 ? (
    <CarriersTable carriers={carriersToShow} />
  ) : (
    <EmptyStateComponent text={t('info.noCarriers')} />
  );
};
