import { Stack } from '@mui/material';
import { Adress, Contact, MainInfo } from './partials';
import { Workers } from './partials/Workers/_Workers';

interface AddClientFormProps {
  edit?: boolean;
}

export const AddClientForm = ({ edit = false }: AddClientFormProps): JSX.Element => (
  <Stack rowGap={2}>
    <MainInfo edit={edit} />
    <Adress />
    <Contact />
    <Workers />
  </Stack>
);
