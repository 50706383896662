import { useFormikContext } from 'formik';
import { ICompany } from 'global';
import { useTranslation } from 'hooks/useTranslation';
import { Grid, TextField, Typography, Button } from '@mui/material';
import { AppCard } from 'components';

interface MainInfoProps {
  disabled: boolean;
}

export const PaymentDetails = ({ disabled }: MainInfoProps) => {
  const {
    values: {
      details: { bankNumber, bankName }
    },
    handleChange
  } = useFormikContext<ICompany>();
  const { t } = useTranslation();

  return (
    <Grid item xs={12}>
      <AppCard>
        <>
          <Typography mb={3} fontWeight="bold">
            {t('subheaders.paymentDetails')}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                size="small"
                label={t('inputs.bankNumber')}
                name="details.bankNumber"
                value={bankNumber}
                onChange={handleChange}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                size="small"
                label={t('inputs.bankName')}
                name="details.bankName"
                value={bankName}
                onChange={handleChange}
                disabled
              />
            </Grid>

            <Grid item xs={12}>
              <Button variant="outlined">{t('inputs.addNewAccount')}</Button>
            </Grid>
          </Grid>
        </>
      </AppCard>
    </Grid>
  );
};
