import { Grid, Stack, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { AppCard } from 'components';
import { IInvoice } from 'global';

export const Summary = () => {
  const {
    values: { details, currency }
  } = useFormikContext<IInvoice>();

  const sumNetto =
    details.length > 0
      ? details.reduce(function (acc, obj) {
          return acc + obj.priceNetto;
        }, 0)
      : 0;

  const sumVat =
    details.length > 0
      ? details.reduce(function (acc, obj) {
          return acc + (obj.priceNetto * Number(obj.vat)) / 100;
        }, 0)
      : 0;

  const sumBrutto = sumNetto + sumVat;

  return (
    <Grid item xs={12} container justifyContent="flex-end">
      <Grid item xs={4}>
        <AppCard>
          <Stack>
            <Stack direction="row" justifyContent="space-between">
              <Typography>Suma netto</Typography>
              <Typography>
                {sumNetto.toFixed(2)} {currency}
              </Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Typography>Suma vat</Typography>
              <Typography>
                {sumVat.toFixed(2)} {currency}
              </Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Typography>Suma brutto</Typography>
              <Typography>
                {sumBrutto.toFixed(2)} {currency}
              </Typography>
            </Stack>
          </Stack>
        </AppCard>
      </Grid>
    </Grid>
  );
};
