import { Grid, TextField, Typography, Stack, InputAdornment } from '@mui/material';
import { useFormikContext } from 'formik';
import { IRound } from 'global';
import { useTranslation } from 'hooks/useTranslation';
import { useAppSelector } from 'store/hooks';

export const Settlement = () => {
  const { t } = useTranslation();
  const {
    handleChange,
    values: {
      costs: { leasing, fuel, driver, driverAdditional, additional, others }
    }
  } = useFormikContext<IRound>();

  const companyCurrency = useAppSelector((store) => store.company.company?.currency) || '';

  return (
    <Grid item xs={12}>
      <Stack>
        <Typography>Koszty rundy</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              value={leasing}
              name="costs.leasing"
              label={t('inputs.leasing')}
              onChange={handleChange}
              InputProps={{
                endAdornment: <InputAdornment position="end">{companyCurrency}</InputAdornment>
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              value={driver}
              name="costs.driver"
              label={t('inputs.driver')}
              onChange={handleChange}
              InputProps={{
                endAdornment: <InputAdornment position="end">{companyCurrency}</InputAdornment>
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              value={fuel}
              name="costs.fuel"
              label={t('inputs.fuel')}
              onChange={handleChange}
              InputProps={{
                endAdornment: <InputAdornment position="end">{companyCurrency}</InputAdornment>
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              value={others}
              name="costs.others"
              label={t('inputs.others')}
              onChange={handleChange}
              InputProps={{
                endAdornment: <InputAdornment position="end">{companyCurrency}</InputAdornment>
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              value={driverAdditional}
              name="costs.driverAdditional"
              label={t('inputs.driverAdditional')}
              onChange={handleChange}
              InputProps={{
                endAdornment: <InputAdornment position="end">{companyCurrency}</InputAdornment>
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              value={additional}
              name="costs.additional"
              label={t('inputs.additional')}
              onChange={handleChange}
              InputProps={{
                endAdornment: <InputAdornment position="end">{companyCurrency}</InputAdornment>
              }}
            />
          </Grid>
        </Grid>
      </Stack>
    </Grid>
  );
};
