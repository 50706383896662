import { Breadcrumbs, Button, Link as MuiLink, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import { FullDialog, StandardLayout } from 'components';
import { useFormik, FormikProvider, Form } from 'formik';
import {
  IComission,
  ICompanyUser,
  IShipment,
  InitialCompanyUser,
  Role,
  UserDetails,
  UserStatus,
  companyUser
} from 'global';
import { useTranslation } from 'hooks/useTranslation';
import { nanoid } from '@reduxjs/toolkit';
import { useGetUsers } from 'hooks/useGetUsers';
import usersService from 'services/users.service';
import { toast } from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { useGetTokenData } from 'hooks/useGetTokenData';
import { validationSchema } from './AddUser/AddUser.const';
import { AddUser } from './AddUser';
import { UsersList } from './UsersList';
import { DialogProps, DialogTypes } from './UserManagement.types';

export const UserManagement = (): JSX.Element => {
  const [openDialog, setOpenDialog] = useState<DialogProps | null>(null);
  const token = useGetTokenData();

  const handleOpen = (dialogType: DialogTypes, data?: ICompanyUser) => {
    setOpenDialog({ type: dialogType, values: data || companyUser });
  };
  const handleClose = () => setOpenDialog(null);
  const { t } = useTranslation();
  const { refetch } = useGetUsers({});

  const formik = useFormik<ICompanyUser>({
    initialValues: companyUser,
    validationSchema: validationSchema(t),
    onSubmit: async (values) => {
      if (openDialog?.type === 'ADD') {
        const userData: ICompanyUser = {
          ...values,
          userId: nanoid(),
          company: token.company.id
        };
        delete userData.id;
        delete userData.createdAt;
        delete userData.updatedAt;
        console.log('wysylamy', userData);

        const res = await usersService.addUser(userData).catch(() => {
          toast.error(t('errors.addUser'));
        });
        if (!res) return;
      }
      if (openDialog?.type === 'EDIT') {
        const res = await usersService.editUser(values).catch(() => {
          toast.error(t('errors.editDriver'));
        });
        if (!res) return;
      }

      formik.resetForm();
      handleClose();
      refetch();
    }
  });

  useEffect(() => {
    if (openDialog) {
      formik.setValues(openDialog.values);
    }
    // eslint-disable-next-line
  }, [openDialog]);

  return (
    <StandardLayout
      button={
        <Button variant="contained" onClick={() => handleOpen('ADD')}>
          {t('buttons.addUser')}
        </Button>
      }
      breadcrumbs={
        <Breadcrumbs>
          <MuiLink component={Link} underline="hover" color="inherit" to="/admin">
            {t('headers.adminPanel')}
          </MuiLink>
          <Typography color="text.primary">User management</Typography>,
        </Breadcrumbs>
      }
      content={
        <>
          {openDialog?.type && (
            <FullDialog
              handleClose={handleClose}
              open={Boolean(openDialog.type)}
              title={openDialog.type === 'ADD' ? t('buttons.addUser') : 'edit user'}
            >
              <FormikProvider value={formik}>
                <Form onSubmit={formik.handleSubmit} id="userAddForm">
                  <AddUser edit={openDialog.type === 'ADD' || false} />
                </Form>
              </FormikProvider>
            </FullDialog>
          )}
          <UsersList handleOpen={handleOpen} />
        </>
      }
    />
  );
};
