import {
  TextField,
  Grid,
  Typography,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';
import { useFormikContext } from 'formik';
import { ICar, Currencies } from 'global';
import { useTranslation } from 'hooks/useTranslation';

export const Details = () => {
  const {
    values: {
      details: {
        costPerKm: { value, currency },
        averageFuelConsumption,
        comments,
        counterStatus
      }
    },
    handleChange
  } = useFormikContext<ICar>();
  const { t } = useTranslation();

  return (
    <Grid container rowGap={2}>
      <Grid item xs={12}>
        <Typography component="span" variant="h5">
          {t('subheaders.details')}
        </Typography>
      </Grid>
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            label={t('inputs.costPerKm')}
            name="details.costPerKm.value"
            size="small"
            value={value}
            onChange={handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <FormControl sx={{ width: '100px' }} size="small">
                    <InputLabel>Waluta</InputLabel>
                    <Select
                      value={currency}
                      name="details.costPerKm.currency"
                      label="Waluta"
                      onChange={handleChange}
                    >
                      {Object.values(Currencies).map((el) => (
                        <MenuItem key={el} value={el}>
                          {el}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            label={t('inputs.averageFuelConsumption')}
            size="small"
            name="details.averageFuelConsumption"
            InputProps={{
              endAdornment: <InputAdornment position="end">l / 100km</InputAdornment>
            }}
            value={averageFuelConsumption}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            label={t('inputs.counterStatus')}
            name="details.counterStatus"
            size="small"
            value={counterStatus}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label={t('inputs.comments')}
            name="details.comments"
            size="small"
            value={comments}
            multiline
            minRows={2}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
