import { Grid, Stack, TextField, Typography, Divider } from '@mui/material';
import { useFormikContext } from 'formik';
import { ErrorMessage } from 'components';
import { IClient } from 'global';
import { useTranslation } from 'hooks/useTranslation';

interface MainInfoProps {
  edit: boolean;
}

export const MainInfo = ({ edit = false }: MainInfoProps): JSX.Element => {
  const {
    values: { name, shortName, limit, nip, paymentTerm },
    handleChange,
    errors
  } = useFormikContext<IClient>();
  const { t } = useTranslation();

  return (
    <Stack rowGap={2}>
      <Typography variant="h6">{t('subheaders.mainInfo')}</Typography>
      <Divider />
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <TextField
            fullWidth
            label={t('inputs.company')}
            size="small"
            name="name"
            value={name}
            disabled={edit}
            onChange={handleChange}
            error={!!errors.name}
          />
          <ErrorMessage name="name" />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            fullWidth
            label={t('inputs.companyShort')}
            size="small"
            name="shortName"
            value={shortName}
            disabled={edit}
            onChange={handleChange}
            error={!!errors.shortName}
          />
          <ErrorMessage name="shortName" />
        </Grid>
        <Grid item xs={6} md={3}>
          <TextField
            fullWidth
            size="small"
            label={t('inputs.nip')}
            disabled={edit}
            name="nip"
            value={nip}
            onChange={handleChange}
            error={!!errors.nip}
          />
          <ErrorMessage name="details.nip" />
        </Grid>
        <Grid item xs={6} md={3}>
          <TextField
            fullWidth
            label={t('subheaders.limit')}
            size="small"
            name="limit"
            disabled
            value={limit}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <TextField
            fullWidth
            label={t('subheaders.paymentTerm')}
            size="small"
            name="paymentTerm"
            value={paymentTerm}
            onChange={handleChange}
            error={!!errors.paymentTerm}
          />
        </Grid>
      </Grid>
    </Stack>
  );
};
