import { dayjsToDate } from 'global';
import { Stack, Typography, Grid } from '@mui/material';
import { CountryNameWithFlag } from 'components';
import { useTranslation } from 'hooks/useTranslation';
import { ShowPlaceElementProps } from './_partials.types';

export const ShowPlaceElement = ({
  data: {
    position: { country, city, postcode, company, adressLine1, adressLine2 },
    timeMax,
    timeMin,
    details
  }
}: ShowPlaceElementProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Grid item xs={12}>
      <Stack direction="row">
        <Typography>
          <CountryNameWithFlag data={country} />
        </Typography>
        <Typography>
          {company} {adressLine1} {adressLine2} {postcode} {city}
        </Typography>
        <Typography>
          {dayjsToDate(timeMin)} {dayjsToDate(timeMax)}
        </Typography>
      </Stack>
      {details.map(
        ({
          id,
          ref,
          adr,
          adrCode,
          cargoType,
          palletsType,
          defaultSize,
          quantity,
          weight,
          forChange,
          forChangeQuantity,
          comments
        }) => (
          <Stack key={id} direction="row" spacing={2}>
            <Typography>
              {t('inputs.type')}
              {cargoType}
            </Typography>
            <Typography>
              {t('inputs.palletsType')} {palletsType}
            </Typography>
            {defaultSize && <Typography> {defaultSize}</Typography>}
            <Typography>
              {t('inputs.quantity')} {quantity}
            </Typography>
            <Typography>
              {t('inputs.weight')} {weight} kg
            </Typography>
            {forChange && (
              <Typography>
                {t('inputs.forChange')} {forChangeQuantity}
              </Typography>
            )}
            {ref && (
              <Typography>
                {t('inputs.ref')} {ref}
              </Typography>
            )}
            {adr && (
              <Typography>
                {t('inputs.adrCode')}: {adrCode}
              </Typography>
            )}
            {comments && (
              <Typography>
                {t('inputs.comments')} {comments}
              </Typography>
            )}
          </Stack>
        )
      )}
    </Grid>
  );
};
