import axios from 'axios';
import { ICompany } from 'global';
import { toast } from 'react-hot-toast';
import { servicesEnv } from './const';
import authHeader from './auth-header';

const API_URL = `${servicesEnv}v1/api/company`;
class RegisterService {
  async getCompany(id: string): Promise<ICompany | undefined> {
    const res = await axios.get(`${API_URL}/${id}`, {
      headers: authHeader()
    });

    if (res.data.error) {
      toast.error(res.data.error);
      return undefined;
    }
    return res.data;
  }
  async editCompany(company: ICompany): Promise<boolean> {
    const res = await axios.patch(
      API_URL,
      { company },
      {
        headers: authHeader()
      }
    );

    if (res.data.error) {
      toast.error(res.data.error);
      return false;
    }

    return true;
  }
}
export default new RegisterService();
