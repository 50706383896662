import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICompany } from 'global';
import { initialState } from './companyReducer.const';

export const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    setCompany: (state, action: PayloadAction<ICompany>) => {
      return { ...state, company: action.payload };
    }
  }
});

export const { setCompany } = companySlice.actions;

export default companySlice.reducer;
