import { Button } from '@mui/material';
import { useState } from 'react';
import { EditPasswordDialog } from './EditPasswordDialog';

export const EditPassword = () => {
  const [openDialog, setOpenDialog] = useState(false);

  const handleOpen = () => setOpenDialog(true);
  const handleClose = () => setOpenDialog(false);

  return (
    <>
      <Button variant="outlined" onClick={handleOpen}>
        Edytuj hasło
      </Button>
      {openDialog && <EditPasswordDialog open={openDialog} handleClose={handleClose} />}
    </>
  );
};
