import { Grid, InputLabel, FormControl, Select, MenuItem } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useTranslation } from 'hooks/useTranslation';
import { setCargoFilters } from 'store/reducers/filtersReducer/filtersReducer';
import { CargoStatus } from 'global';
import { useGetUsers } from 'hooks/useGetUsers';

export const Filters = () => {
  const filters = useAppSelector((store) => store.filters.cargoFilters);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { data } = useGetUsers({});
  const users = data || [];

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={3}>
        <FormControl fullWidth size="small">
          <InputLabel>{t('inputs.status')}</InputLabel>
          <Select
            value={filters.status}
            name="status"
            label={t('inputs.status')}
            onChange={(e) =>
              dispatch(setCargoFilters({ ...filters, status: e.target.value as CargoStatus }))
            }
          >
            <MenuItem value="">{t('inputs.all')}</MenuItem>
            {Object.values(CargoStatus).map((el) => (
              <MenuItem key={el} value={el}>
                {t(`cargoStatus.${el}`)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={3}>
        <FormControl fullWidth size="small">
          <InputLabel>{t('inputs.guardian')}</InputLabel>
          <Select
            value={filters.guardian}
            name="guardian"
            label={t('inputs.guardian')}
            onChange={(e) => dispatch(setCargoFilters({ ...filters, guardian: e.target.value }))}
          >
            <MenuItem value="">{t('inputs.all')}</MenuItem>
            {users.map((el) => (
              <MenuItem key={el.userId} value={el?.id || ''}>
                {el.details.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};
