import { IComission } from '../types';

export const initialComission: IComission = {
  id: '',
  comissionId: '',
  createdAt: new Date(),
  updatedAt: new Date(),
  company: '',
  user: '',
  value: 0,
  currency: '',
  date: new Date(),
  shipment: null,
  order: null
};
