import { ICarrier } from 'global';
import { FiltersState } from 'store/reducers/filtersReducer/filtersReducer.types';

export const filterCarriers = (carriers: ICarrier[], filters: FiltersState) => {
  let array = [...carriers];
  const { type, value } = filters.carriersSearch;
  const { status } = filters.carriersFilters;
  if (value)
    array = array.filter((el) => {
      if (type === 'name') return el.name === value;
      if (type === 'nip') return el.nip === value;
      return el;
    });
  if (status) array = array.filter((el) => el.status === status);
  return array;
};
