import { Button, Stack } from '@mui/material';
import { useTranslation } from 'hooks/useTranslation';
import { AddCarrierForm } from './AddCarrierFrom';

export const AddCarrier = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Stack rowGap={3}>
      <AddCarrierForm />
      <Button form="carrierAddForm" type="submit" variant="contained">
        {t('buttons.save')}
      </Button>
    </Stack>
  );
};
