import { ICar } from 'global';
import { Stack, Typography, Chip } from '@mui/material';
import { useTranslation } from 'hooks/useTranslation';
import dayjs from 'dayjs';

interface CarDocumentsProps {
  car: ICar;
}

export const CarDocuments = ({
  car: {
    details: { counterStatus },
    documents: { ocp, ocpTo, overviewAt, technicalTill },
    accessories
  }
}: CarDocumentsProps): JSX.Element => {
  const { t, d } = useTranslation();

  const nextOverviewAt = Number(overviewAt) - Number(counterStatus);

  return (
    <Stack>
      <Typography>
        {t('inputs.ocp')} {ocp}
      </Typography>
      <Typography>
        {t('inputs.ocpTo')}: {dayjs(ocpTo).format('DD.MM.YYYY')}
      </Typography>
      <Typography>
        {t('inputs.overviewAt')}: {nextOverviewAt}
      </Typography>
      <Typography>
        {t('inputs.technicalTill')}: {d(String(technicalTill))}
      </Typography>
      <Stack rowGap={1}>
        <Typography>{t('subheaders.accessories')}</Typography>
        <Stack direction="row" flexWrap="wrap" spacing={1}>
          {accessories.map((el) => (
            <Chip size="small" label={t(`accessories.${el}`)} key={el} variant="outlined" />
          ))}
        </Stack>
      </Stack>
    </Stack>
  );
};
