export enum CarStatus {
  inactive = 'inactive',
  empty = 'empty',
  waitForPartload = 'waitForPartload',
  taken = 'taken',
  malfunction = 'malfunction'
}

export enum AdditionalCarStatus {
  onLoading = 'onLoading',
  onUnloading = 'onUnloading',
  onRoad = 'onRoad',
  pause = 'pause',
  none = 'none'
}

export enum CarBody {
  plane = 'plane',
  box = 'box',
  frigo = 'frigo'
}

export enum CarType {
  bus = 'bus',
  solo = 'solo',
  trailer = 'trailer'
}

export enum Accessories {
  adr = 'adr',
  tailift = 'tailift',
  palletsForChange = 'palletsForChange',
  manualUnloading = 'manualUnloading',
  crane = 'crane',
  frigo = 'frigo',
  belts = 'belts'
}
