import { Grid, InputLabel, FormControl, Select, MenuItem } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setDriversFilters } from 'store/reducers/filtersReducer';
import { useTranslation } from 'hooks/useTranslation';
import { DriverStatus } from 'global';

export const Filter = (): JSX.Element => {
  const filters = useAppSelector((store) => store.filters.driversFilters);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  return (
    <Grid container rowGap={1}>
      <Grid item xs={12}>
        <FormControl fullWidth size="small">
          <InputLabel>{t('inputs.status')}</InputLabel>
          <Select
            value={filters.status}
            name="status"
            label={t('inputs.status')}
            onChange={(e) =>
              dispatch(setDriversFilters({ ...filters, status: e.target.value as DriverStatus }))
            }
          >
            <MenuItem value="">{t('inputs.all')}</MenuItem>
            {Object.values(DriverStatus).map((el) => (
              <MenuItem key={el} value={el}>
                {t(`driverStatus.${el}`)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};
