import { Translation } from 'hooks/useTranslation';
import { Stack } from '@mui/material';
import {
  Adress,
  Contact,
  Limit,
  Payment,
  Status
} from '../Clients/components/AddClient/AddClientForm/partials';

export type ClientActions = 'editContact' | 'editStatus' | 'editLimit' | 'payment';

export const clientActions = (t: Translation) => [
  {
    type: 'editContact' as ClientActions,
    chlidren: (
      <Stack rowGap={2}>
        <Adress />
        <Contact />
      </Stack>
    ),
    title: t('clientActions.editContact')
  },
  {
    type: 'editStatus' as ClientActions,
    chlidren: <Status />,
    title: t('clientActions.editStatus')
  },
  {
    type: 'editLimit' as ClientActions,
    chlidren: <Limit />,
    title: t('clientActions.editLimit'),
    adminOnly: true
  },
  {
    type: 'payment' as ClientActions,
    chlidren: <Payment />,
    title: t('clientActions.payment')
  }
];
