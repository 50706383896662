import { ClientStatus, IClient } from 'global';
import { Translation } from 'hooks/useTranslation';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import FiberNewOutlinedIcon from '@mui/icons-material/FiberNewOutlined';
import KeyboardDoubleArrowUpOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowUpOutlined';
import StarPurple500OutlinedIcon from '@mui/icons-material/StarPurple500Outlined';
import { Link } from 'react-router-dom';
import { Theme } from '@mui/material';

export const tableHeaders = (t: Translation) => [
  '',
  t('inputs.company'),
  t('inputs.companyShort'),
  t('inputs.nip'),
  t('inputs.city'),
  t('inputs.status')
];

export const tableCells = (client: IClient, theme: Theme) => [
  {
    value: (
      <Link to={`${client.id}`} rel="noopener noreferrer">
        <ManageSearchIcon />
      </Link>
    ),
    width: '1%'
  },
  {
    value: client.name,
    width: '15%'
  },
  {
    value: client.shortName,
    width: '15%'
  },
  {
    value: client.nip,
    width: '10%'
  },
  {
    value: `${client.details.adress.postcode} ${client.details.adress.city}`,
    width: '58%'
  },
  {
    value: getStatusIcon(client.status, theme),
    width: '1%'
  }
];

export const getStatusIcon = (status: ClientStatus, theme: Theme) => {
  switch (status) {
    case ClientStatus.ok:
      return <CheckBoxOutlinedIcon sx={{ color: theme.palette.success.main }} />;
    case ClientStatus.blocked:
      return <RemoveCircleOutlineOutlinedIcon sx={{ color: theme.palette.error.main }} />;
    case ClientStatus.new:
      return <FiberNewOutlinedIcon />;
    case ClientStatus.best:
      return <StarPurple500OutlinedIcon sx={{ color: theme.palette.warning.main }} />;
    case ClientStatus.overLimit:
      return <KeyboardDoubleArrowUpOutlinedIcon sx={{ color: theme.palette.error.main }} />;
    default:
      return <div />;
  }
};
