import { useFormikContext } from 'formik';
import { ICompany } from 'global';
import { useTranslation } from 'hooks/useTranslation';
import { Grid, TextField, Typography } from '@mui/material';
import { AppCard } from 'components';

export const MainInfo = (): JSX.Element => {
  const {
    values: { nip, currency, name, status }
  } = useFormikContext<ICompany>();
  const { t } = useTranslation();

  return (
    <Grid item xs={12}>
      <AppCard>
        <>
          <Typography mb={2} fontWeight="bold">
            {t('subheaders.mainInfo')}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <TextField fullWidth size="small" label={t('inputs.company')} value={name} disabled />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField fullWidth size="small" label={t('inputs.nip')} value={nip} disabled />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                size="small"
                label={t('inputs.currency')}
                value={currency}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                size="small"
                label={t('inputs.status')}
                value={t(`companyStatus.${status}`)}
                disabled
              />
            </Grid>
          </Grid>
        </>
      </AppCard>
    </Grid>
  );
};
