import { nanoid } from '@reduxjs/toolkit';
import { IDecodedToken, IShipment } from 'global';

// export const getOrderNumber = () => {
//   const date = new Date();
//   const month = date.getMonth() + 1;
//   const year = date.getFullYear();

//   // TODO shipments list
//   const shipmentsList: IShipment[] = [];
//   const number = shipmentsList.filter((el) => el.number.includes(`/${month}/${year}`)).length;

//   return `SH/${number + 1}/${month}/${year}`;
// };

export const setMainValues = (values: IShipment, { company, user }: IDecodedToken): IShipment => ({
  ...values,
  shipmentId: nanoid(),
  company: company.id,
  addedBy: user.id,
  guardian: { id: user.id, name: user.name },
  percentageComission: [
    {
      user: user.id,
      percent: 100
    }
  ]
});
