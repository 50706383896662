import { Navigate } from 'react-router-dom';
import { FC } from 'react';
import { PrivateRouteProps } from './PrivateRoute.model';
import { usePermission } from '../usePermission';

export const PrivateRoute: FC<PrivateRouteProps> = ({ children = <div />, policy }) => {
  // const {
  //   state: { authenticated },
  // } = useContext(AppContext);

  const grantedAcccess = usePermission(policy);

  // return authenticated && grantedAcccess ? children : <Navigate to={{ pathname }} />;
  return grantedAcccess ? children : <Navigate to="/no-permission" />;
};
