import {
  Box,
  Button,
  Checkbox,
  IconButton,
  Stack,
  TextField,
  Typography,
  DialogTitle,
  DialogContent,
  Dialog
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from 'formik';
import { useState } from 'react';
import { AlertMessage } from 'components';
import authService from 'services/auth.service';
import toast from 'react-hot-toast';
import { Message } from 'pages/Login/Login.model';
import { EditPasswordDialogProps } from './EditPassword.model';
import { editPassowrdInitialValues, validationSchema } from './EditPassword.const';

export const EditPasswordDialog = ({ open, handleClose }: EditPasswordDialogProps) => {
  const [loading, setLoading] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [message, setMessage] = useState<Message | null>(null);

  const formik = useFormik({
    initialValues: editPassowrdInitialValues,
    validationSchema,
    onSubmit: async (values) => {
      if (values.newPassword !== values.newPasswordAgain) {
        setMessage({ text: 'Nowe hasła muszą być jednakowe', status: 'error' });
      }
      setLoading(true);

      const res = await authService.editPassword(values.newPassword).catch(() => {
        setLoading(false);
        setMessage({ text: 'Ups. coś poszło nie tak', status: 'error' });
      });

      if (!res) {
        setMessage({ text: 'Niepoprawne hasło użytkownika', status: 'error' });
        setLoading(false);
        return;
      }

      toast.success('Zmieniono hasło');
      handleClose();
      setLoading(false);
    }
  });

  const passwordVisibilitySwitch = () => setIsPasswordVisible(!isPasswordVisible);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{ sx: { width: '600px', minHeight: '50%' } }}
    >
      <DialogTitle>
        <IconButton
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8
          }}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="h6" component="span">
          Edytuj hasło
        </Typography>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={formik.handleSubmit}>
          <Stack rowGap={2} mt={2}>
            {message && <AlertMessage message={message.text} status={message.status} />}
            <TextField
              fullWidth
              type={isPasswordVisible ? 'text' : 'password'}
              name="newPassword"
              id="newPassword"
              label="Nowe hasło"
              value={formik.values.newPassword}
              onChange={formik.handleChange}
              error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
              helperText={formik.touched.newPassword && formik.errors.newPassword}
            />
            <TextField
              fullWidth
              name="newPasswordAgain"
              type={isPasswordVisible ? 'text' : 'password'}
              id="newPasswordAgain"
              label="Powtórz nowe hasło"
              value={formik.values.newPasswordAgain}
              onChange={formik.handleChange}
              error={formik.touched.newPasswordAgain && Boolean(formik.errors.newPasswordAgain)}
              helperText={formik.touched.newPasswordAgain && formik.errors.newPasswordAgain}
            />
            <Box>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Checkbox
                  checked={isPasswordVisible}
                  onChange={passwordVisibilitySwitch}
                  sx={{ pl: 0 }}
                />
                <Typography variant="caption">
                  {isPasswordVisible ? 'Schowaj hasła' : 'Pokaż hasła'}
                </Typography>
              </Stack>
            </Box>
            <Button color="primary" variant="contained" fullWidth type="submit" disabled={loading}>
              Zmień hasło
            </Button>
          </Stack>
        </form>
      </DialogContent>
    </Dialog>
  );
};
