import { AlertMessage, EmptyStateComponent, Loader, StandardTable } from 'components';
import { useTranslation } from 'hooks/useTranslation';
import { useState, ChangeEvent } from 'react';
import { TablePagination, useTheme } from '@mui/material';
import { useGetRounds } from 'hooks/useGetRounds';
import { tableHeaders } from './RoundsTable.const';
import { Row } from './Row';

export const RoundsTable = (): JSX.Element => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const theme = useTheme();
  const { t } = useTranslation();

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const { data, isLoading, error, isFetching } = useGetRounds({ page, rowsPerPage });

  if (isLoading || isFetching) return <Loader />;
  if (error || !data) return <AlertMessage message={t('errors.getData')} status="error" />;
  if (data.length === 0) {
    return <EmptyStateComponent text={t('info.noClients')} />;
  }

  const rows = data.map((round, index) => <Row key={round.id} round={round} index={index} />);

  const rowsToShow = rows.filter(
    (_, index) => index < (page + 1) * rowsPerPage && index >= page * rowsPerPage
  );

  return (
    <>
      <StandardTable headers={tableHeaders} items={rowsToShow} />
      {rows.length > 0 && (
        <TablePagination
          sx={{
            bgcolor:
              theme.palette.mode === 'dark' ? theme.palette.grey[600] : theme.palette.grey[100]
          }}
          rowsPerPageOptions={[20, 50, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </>
  );
};
