import { Alert, Grid } from '@mui/material';

interface AlertMessageProps {
  message: string;
  status: 'error' | 'success' | 'info';
}

export const AlertMessage = ({ message, status }: AlertMessageProps): JSX.Element => (
  <Grid container>
    <Grid item xs={12}>
      <Alert severity={status}>{message}</Alert>
    </Grid>
  </Grid>
);
