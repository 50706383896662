import { nanoid } from '@reduxjs/toolkit';
import { CargoData, IDecodedToken, ICargo } from 'global';

export const getTotalWeight = (events: CargoData[]): number => {
  const loadingEventsDetails = events
    .filter((el) => el.type === 'loading')
    .map((el) => el.details)
    .flat();

  return loadingEventsDetails.reduce((acc, obj) => {
    return acc + Number(obj.weight);
  }, 0);
};

export const setMainValues = (values: ICargo, token: IDecodedToken): ICargo => ({
  ...values,
  cargoId: nanoid(),
  addedBy: token.user.id,
  company: token.company.id,
  price: {
    ...values.price,
    date: new Date()
  },
  weight: String(getTotalWeight(values.events))
});
