import { useState } from 'react';
import { Grid, Stack, Typography, Breadcrumbs, ButtonGroup, Button } from '@mui/material';
import { AppCard } from 'components';
import { useTranslation } from 'hooks/useTranslation';
import { useAppSelector } from 'store/hooks';
import { getTables } from './Board.helpers';
import { WelcomePanel } from './components';

export enum BoardDate {
  today = 'today',
  tomorrow = 'tomorrow'
}

export const Board = (): JSX.Element => {
  const [pickDate, setPickDate] = useState<BoardDate>(BoardDate.today);
  const { t } = useTranslation();
  const user = useAppSelector((store) => store.user.user);

  return (
    <Stack rowGap={1}>
      <Breadcrumbs>
        <Typography fontWeight="bold" color="text.primary">
          {t('headers.board')}
        </Typography>
      </Breadcrumbs>

      <Grid container spacing={2}>
        <WelcomePanel />
        <Grid item xs={12}>
          <AppCard>
            <>
              <ButtonGroup variant="outlined">
                {Object.values(BoardDate).map((el) => (
                  <Button
                    onClick={() => setPickDate(el)}
                    variant={pickDate === el ? 'contained' : 'outlined'}
                  >
                    {t(`buttons.${el}`)}
                  </Button>
                ))}
              </ButtonGroup>
              <Grid container spacing={2}>
                {getTables(t, user?.shipments || [], pickDate)}
              </Grid>
            </>
          </AppCard>
        </Grid>
      </Grid>
    </Stack>
  );
};
