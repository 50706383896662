export enum CargoStatus {
  quotation = 'quotation',
  toSell = 'toSell',
  assigned = 'assigned',
  canceled = 'canceled',
  done = 'done'
}

export enum LoadingUnloadingStatus {
  done = 'done',
  unloading = 'unloading',
  loading = 'loading',
  inProgress = 'inProgress',
  toDo = 'toDo',
  delay = 'delay'
}

export enum PalletsTypes {
  europ = 'europ',
  smallp = 'smallp',
  industrialp = 'industrialp',
  other = 'other'
}

export enum CargoTypes {
  neutral = 'neutral'
}

export enum CargoEvent {
  loading = 'loading',
  unloading = 'unloading'
}
