import {
  Checkbox,
  Stack,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  Divider
} from '@mui/material';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { ErrorMessage } from 'components';
import { IClient, initialAdress } from 'global';
import { useTranslation } from 'hooks/useTranslation';
import { Correspondence } from './_Correspondence';

export const Adress = (): JSX.Element => {
  const [diffrentAdress, setDiffrentAdress] = useState(false);
  const toggleCorrespondence = () => setDiffrentAdress((prev) => !prev);
  const { t } = useTranslation();

  const {
    values: {
      details: {
        adress: { adressLine1, adressLine2, postcode, city, country }
      }
    },
    handleChange,
    setFieldValue,
    errors
  } = useFormikContext<IClient>();

  useEffect(() => {
    setFieldValue('details.correspondenceAdress', diffrentAdress ? initialAdress : null);
  }, [diffrentAdress, setFieldValue]);

  return (
    <Stack rowGap={2}>
      <Typography variant="h6">{t('subheaders.adress')}</Typography>
      <Divider />
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            size="small"
            label={t('inputs.postcode')}
            name="details.adress.postcode"
            value={postcode}
            onChange={handleChange}
            error={!!errors.details?.adress?.postcode}
          />
          <ErrorMessage name="details.adress.postcode" />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            label={t('inputs.city')}
            size="small"
            name="details.adress.city"
            value={city}
            onChange={handleChange}
            error={!!errors.details?.adress?.city}
          />
          <ErrorMessage name="details.adress.city" />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            size="small"
            label={t('inputs.country')}
            name="details.adress.country"
            value={country}
            onChange={handleChange}
            error={!!errors.details?.adress?.country}
          />
          <ErrorMessage name="details.adress.country" />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            size="small"
            fullWidth
            label={t('inputs.adressLine1')}
            name="details.adress.adressLine1"
            value={adressLine1}
            onChange={handleChange}
            error={!!errors.details?.adress?.adressLine1}
          />
          <ErrorMessage name="details.adress.adressLine1" />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            size="small"
            label={t('inputs.adressLine2')}
            name="details.adress.adressLine2"
            value={adressLine2}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox checked={diffrentAdress} onChange={toggleCorrespondence} />}
            label={t('inputs.corespondenceQuestion')}
          />
        </Grid>
        {diffrentAdress && <Correspondence />}
      </Grid>
    </Stack>
  );
};
