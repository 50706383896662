import { toast } from 'react-hot-toast';
import { UseQueryResult, useQuery } from 'react-query';
import { useEffect } from 'react';
import dictonariesService from 'services/dictonaries.service';
import { useTranslation } from './useTranslation';

type Dictonariers = Record<string, Record<string, Record<string, string>>>;

export const useGetDictonaries = (): UseQueryResult<Dictonariers, unknown> => {
  const { t } = useTranslation();

  const query = useQuery('dictonaries', () => dictonariesService.getDictonaries(), {
    refetchOnWindowFocus: false,
    onSuccess(res) {},
    onError() {
      toast.error(t('errors.getData'));
    }
  });

  useEffect(() => {
    query.refetch();
    // eslint-disable-next-line
  }, []);

  return query;
};
