import { useState, SyntheticEvent } from 'react';
import { Form, useFormik, FormikProvider } from 'formik';
import PasswordIcon from '@mui/icons-material/Password';
import {
  Box,
  Button,
  Stack,
  CircularProgress,
  TextField,
  InputAdornment,
  Tab,
  Tabs,
  Typography,
  Grid
} from '@mui/material';
import { ErrorMessage } from 'components';
import { useTranslation } from 'hooks/useTranslation';
import authService from 'services/auth.service';
import AccountCircle from '@mui/icons-material/AccountCircle';
import LockIcon from '@mui/icons-material/Lock';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { ILoginForm, KindOfUser, LoginFormProps } from '../Login.model';
import { initialValues, validationSchema } from '../Login.const';

export const LoginForm = ({ setResetPage, setMessageData }: ILoginForm) => {
  const [tab, setTab] = useState<KindOfUser>('user');
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const handleChange = (_: SyntheticEvent, newValue: KindOfUser) => {
    setTab(newValue);
  };

  const handleLogin = async (formValue: { email: string; password: string }) => {
    const { email, password } = formValue;
    setLoading(true);
    // const isUser = tab === 'user';

    await authService
      .login({
        email,
        password
        // isUser
      })
      .then(
        () => {
          window.location.reload();
        },
        () => {
          setMessageData({
            text: t('errors.login'),
            status: 'error'
          });

          setLoading(false);
        }
      );
  };

  const formik = useFormik<LoginFormProps>({
    initialValues,
    validationSchema: () => validationSchema(t),
    onSubmit: handleLogin
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <Stack rowGap={4}>
          <Tabs sx={{ pb: 5 }} value={tab} onChange={handleChange}>
            <Tab sx={{ width: '50%' }} value="user" label={t('inputs.user')} />
            <Tab sx={{ width: '50%' }} value="driver" label={t('inputs.driver')} />
          </Tabs>
          <Grid container justifyContent="center" rowGap={2}>
            <Grid item xs={12} md={10}>
              <TextField
                fullWidth
                name="email"
                type="text"
                label="Email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircle />
                    </InputAdornment>
                  )
                }}
              />
              <ErrorMessage name="email" />
            </Grid>
            <Grid item xs={12} md={10}>
              <TextField
                fullWidth
                name="password"
                type={showPassword ? 'text' : 'password'}
                label={t('buttons.password')}
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PasswordIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment onClick={() => setShowPassword(!showPassword)} position="end">
                      {showPassword ? <LockIcon /> : <VisibilityIcon />}
                    </InputAdornment>
                  )
                }}
              />
              <ErrorMessage name="password" />
            </Grid>
            <Grid item xs={12} md={10}>
              <Button fullWidth variant="contained" type="submit" disabled={loading}>
                {loading && <CircularProgress size={20} />} {t('buttons.login')}
              </Button>
            </Grid>
          </Grid>

          <Box textAlign="center" height="40px">
            {tab === 'user' && (
              <Button onClick={setResetPage}>
                <Typography variant="caption">{t('inputs.forgotPassword')}</Typography>
              </Button>
            )}
          </Box>
        </Stack>
      </Form>
    </FormikProvider>
  );
};
