import { Checkbox, FormControlLabel, Grid, TextField, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { AppCard, ErrorMessage } from 'components';
import { ICarrier, initialAdress } from 'global';
import { Correspondence } from './_Correspondence';

export const Adress = (): JSX.Element => {
  const [diffrentAdress, setDiffrentAdress] = useState(false);

  const {
    values: {
      details: {
        adress: { adressLine1, adressLine2, postcode, city, country }
      }
    },
    handleChange,
    setFieldValue,
    errors
  } = useFormikContext<ICarrier>();

  useEffect(() => {
    setFieldValue('details.correspondenceAdress', diffrentAdress ? initialAdress : null);
  }, [diffrentAdress]);

  const toggleCorrespondence = () => setDiffrentAdress((prev) => !prev);

  return (
    <AppCard>
      <Grid container>
        <Typography component="span" variant="h6">
          Adress
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="adressLine1"
              size="small"
              name="details.adress.adressLine1"
              value={adressLine1}
              onChange={handleChange}
              error={!!errors.details?.adress?.adressLine1}
            />
            <ErrorMessage name="details.adress.adressLine1" />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              size="small"
              label="adressLine2"
              name="details.adress.adressLine2"
              value={adressLine2}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              label="postcode"
              name="details.adress.postcode"
              value={postcode}
              size="small"
              onChange={handleChange}
              error={!!errors.details?.adress?.postcode}
            />
            <ErrorMessage name="details.adress.postcode" />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              label="city"
              name="details.adress.city"
              value={city}
              size="small"
              onChange={handleChange}
              error={!!errors.details?.adress?.city}
            />
            <ErrorMessage name="details.adress.city" />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              label="country"
              name="details.adress.country"
              value={country}
              size="small"
              onChange={handleChange}
              error={!!errors.details?.adress?.country}
            />
            <ErrorMessage name="details.adress.country" />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={<Checkbox checked={diffrentAdress} onChange={toggleCorrespondence} />}
              label="Adres korespondencyjny jest inny ?"
            />
          </Grid>
          {diffrentAdress && <Correspondence />}
        </Grid>
      </Grid>
    </AppCard>
  );
};
