import { Button, Grid, Breadcrumbs, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import { FullDialog, OrderDoc, StandardLayout } from 'components';
import { useFormik, FormikProvider, Form } from 'formik';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useTranslation } from 'hooks/useTranslation';
import { CargoStatus, initialOrder, IOrder } from 'global';
import { nanoid } from '@reduxjs/toolkit';
import orderService from 'services/order.service';
import cargoService from 'services/cargo.service';
import { useGetTokenData } from 'hooks/useGetTokenData';
import { validationSchema } from './components/AddOrder/AddOrder.const';
import { AddOrder } from './components/AddOrder';
import { OrdersList } from './components/OrdersList';

export const Orders = (): JSX.Element => {
  const [openAdding, setOpenAdding] = useState(false);
  const token = useGetTokenData();
  const { t } = useTranslation();

  const formik = useFormik<IOrder>({
    initialValues: initialOrder,
    validationSchema: validationSchema(t),
    onSubmit: (values) => {
      handleSubmit(values);
    }
  });

  const handleOpen = () => setOpenAdding(true);
  const handleClose = () => {
    formik.resetForm();
    setOpenAdding(false);
  };

  const handleSubmit = async (values: IOrder) => {
    const data = { ...values, id: nanoid(), company: token.company.id, addedBy: token.user.id };

    await orderService
      .addOrder(data)
      .then((res) => console.log(res))
      .catch((err) => console.log(err));

    values.cargo.forEach(async (el) => {
      const editedCargo = {
        ...el,
        status: CargoStatus.assigned,
        // car: values.car,
        // prices: el.prices,
        orderId: values.number
      };

      // await cargoService
      //   .editCargo(editedCargo)
      //   .then((res) => console.log(res))
      //   .catch((err) => console.log(err));
    });
    formik.resetForm();
    handleClose();
  };

  return (
    <>
      {/* <Grid item xs={12}>
        <PDFViewer width="100%" height="800px">
          <OrderDoc order={orders[0]} company={company} />
        </PDFViewer>
      </Grid> */}
      <StandardLayout
        breadcrumbs={
          <Breadcrumbs>
            <Typography color="text.primary">{t('headers.orders')}</Typography>,
          </Breadcrumbs>
        }
        button={
          <Button variant="contained" onClick={handleOpen} sx={{ color: 'white' }}>
            {t('buttons.addOrder')}
          </Button>
        }
        content={
          <FormikProvider value={formik}>
            <Form onSubmit={formik.handleSubmit} id="orderAddForm">
              {openAdding && (
                <FullDialog
                  handleClose={handleClose}
                  open={openAdding}
                  title={t('buttons.addClient')}
                >
                  <AddOrder />
                </FullDialog>
              )}
              <OrdersList />
            </Form>
          </FormikProvider>
        }
      />
    </>
  );
};
