import { Button, Grid, Stack, Typography } from '@mui/material';
import { nanoid } from '@reduxjs/toolkit';
import { useFormikContext } from 'formik';
import { AppCard, EmptyStateComponent, ErrorMessage } from 'components';
import { ICarrier, initialDispo } from 'global';
import { DispoElement } from './_DispoElement';

export const Dispo = () => {
  const {
    values: { dispo },
    setFieldValue
  } = useFormikContext<ICarrier>();

  const addDispo = () => setFieldValue('dispo', [...dispo, { ...initialDispo, id: nanoid() }]);

  return (
    <AppCard>
      <Stack rowGap={2}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography component="span" variant="h6">
            Dispo list
          </Typography>
          <Button variant="contained" onClick={addDispo}>
            Add dispo
          </Button>
        </Stack>

        <Grid container rowGap={2}>
          {dispo.length > 0 ? (
            dispo.map((el) => <DispoElement key={el.id} dispositor={el} />)
          ) : (
            <EmptyStateComponent small text="Brak dyspozytorów" />
          )}
        </Grid>
        {dispo.length === 0 && <ErrorMessage name="dispo" />}
      </Stack>
    </AppCard>
  );
};
