import { Translation } from 'hooks/useTranslation';
import * as yup from 'yup';

export const driverValidationSchema = (t: Translation) =>
  yup.object({
    email: yup.string().required(t('errors.required')),
    password: yup.string().min(6, t('errors.min6')).required(t('errors.required')),
    details: yup.object({
      name: yup.string().required(t('errors.required')),
      phone: yup.string().required(t('errors.required'))
    })
  });
