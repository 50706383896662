import {
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material';
import { Dayjs } from 'dayjs';
import { useFormikContext } from 'formik';
import { AppCard, DatePicker, ErrorMessage } from 'components';
import { Currencies, ICarrier } from 'global';
import { useTranslation } from 'hooks/useTranslation';

export const MainInfo = () => {
  const {
    values: { name, currency, ocpTill, ocp, ocpFrom, nip },
    handleChange,
    setFieldValue,
    errors
  } = useFormikContext<ICarrier>();
  const { t } = useTranslation();

  const handleChangeOcpFrom = (e: Dayjs | null) => setFieldValue('ocpFrom', e);
  const handleChangeOcpTo = (e: Dayjs | null) => setFieldValue('ocpTill', e);

  const onChange = (event: React.ChangeEvent) => {
    const target = event.target as HTMLInputElement;
    const file: File = (target.files as FileList)[0];
    setFieldValue('ocp', file);
  };

  return (
    <AppCard>
      <Grid container rowGap={2}>
        <Grid item xs={12}>
          <Typography variant="h6" component="span">
            {t('details.mainInfo')}
          </Typography>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              label="name"
              size="small"
              name="name"
              value={name}
              onChange={handleChange}
              error={!!errors.name}
            />
            <ErrorMessage name="name" />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              label="nip"
              size="small"
              name="details.nip"
              value={nip}
              onChange={handleChange}
              error={!!errors.nip}
            />
            <ErrorMessage name="details.nip" />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl fullWidth size="small">
              <InputLabel>Waluta</InputLabel>
              <Select value={currency} name="currency" label="Waluta" onChange={handleChange}>
                {Object.values(Currencies).map((el) => (
                  <MenuItem key={el} value={el}>
                    {el}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {/* <Grid item xs={12}>
            <Input type="file" name="ocp" onChange={onChange} />
            {ocp?.name || 'Brak pliku'}
          </Grid> */}
          <Grid item xs={6} md={2}>
            <DatePicker value={ocpFrom} label="Ważnośc ocp od" handleChange={handleChangeOcpFrom} />
          </Grid>
          <Grid item xs={6} md={2}>
            <DatePicker value={ocpTill} label="Ważnośc ocp do" handleChange={handleChangeOcpTo} />
          </Grid>
        </Grid>
        <Divider />
      </Grid>
    </AppCard>
  );
};
