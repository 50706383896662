import { StandardLayout } from 'components';
import { Breadcrumbs, Link as MuiLink, Typography } from '@mui/material';
import { useTranslation } from 'hooks/useTranslation';
import { Link } from 'react-router-dom';

export const InovicesSettings = () => {
  const { t } = useTranslation();

  return (
    <StandardLayout
      breadcrumbs={
        <Breadcrumbs>
          <MuiLink component={Link} underline="hover" color="inherit" to="/admin">
            {t('headers.adminPanel')}
          </MuiLink>
          <Typography color="text.primary">Invoices settings</Typography>,
        </Breadcrumbs>
      }
      content={<div />}
    />
  );
};
