import { Grid, TextField, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { AppCard, ErrorMessage } from 'components';
import { ICarrier } from 'global';
import { useTranslation } from 'hooks/useTranslation';

export const Contact = () => {
  const {
    values: {
      details: { phone, fax, website, email }
    },
    handleChange,
    errors
  } = useFormikContext<ICarrier>();
  const { t } = useTranslation();

  return (
    <AppCard>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography component="span" variant="h6">
            Kontakt
          </Typography>
        </Grid>

        <Grid item xs={6} md={3}>
          <TextField
            fullWidth
            label="phone"
            size="small"
            name="details.phone"
            value={phone}
            onChange={handleChange}
            type="number"
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <TextField
            fullWidth
            label="fax"
            size="small"
            name="details.fax"
            value={fax}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <TextField
            fullWidth
            label="website"
            size="small"
            name="details.website"
            value={website}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <TextField
            fullWidth
            label="email"
            size="small"
            name="details.email"
            value={email}
            onChange={handleChange}
            error={!!errors.details?.email}
          />
          <ErrorMessage name="details.email" />
        </Grid>
      </Grid>
    </AppCard>
  );
};
