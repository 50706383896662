import { Stack, Button } from '@mui/material';
import { useFormikContext } from 'formik';
import { ICar, IShipment } from 'global';
import { useEffect, useState } from 'react';
import { useGetCars } from 'hooks/useGetCars';
import { useTranslation } from 'hooks/useTranslation';
import { MainInfo, Summary } from './partials';

interface AddShipmentFormProps {
  edit?: boolean;
}

export const AddShipmentForm = ({ edit = false }: AddShipmentFormProps): JSX.Element => {
  const [pickedCar, setPickedCar] = useState<ICar | null>(null);
  const [step, setStep] = useState<1 | 2>(1);

  const { t } = useTranslation();

  const {
    setFieldValue,
    values: {
      car,
      carCost,
      guardian,
      distance: { emptyKm, loadedKm }
    },
    isSubmitting
  } = useFormikContext<IShipment>();

  const cars = useGetCars({}).data || [];

  useEffect(() => {
    const chosenCar = cars.find((el) => el.id === car.id);
    if (chosenCar) {
      setPickedCar(chosenCar);
    }
  }, [car.id, cars]);

  const content = () => {
    switch (true) {
      case step === 1:
        return <MainInfo edit={edit} pickedCar={pickedCar} />;
      case step === 2:
        return <Summary edit={edit} pickedCar={pickedCar} />;
      default:
        return <div />;
    }
  };

  return (
    <Stack rowGap={2}>
      {content()}
      {step === 1 && (
        <Button
          variant="contained"
          disabled={!car.id || !guardian.id || !emptyKm || !loadedKm}
          onClick={() => setStep(2)}
        >
          {t('buttons.dalej')}
        </Button>
      )}
      {!edit && step === 2 && (
        <Button variant="contained" disabled={isSubmitting} type="submit" form="shipmentAddForm">
          {t('buttons.save')}
        </Button>
      )}
      {edit && step === 2 && (
        <Button disabled={isSubmitting} form="shipmentEditForm" type="submit" variant="contained">
          {t('buttons.save')}
        </Button>
      )}
      {step === 2 && (
        <Button variant="outlined" onClick={() => setStep(1)}>
          {t('buttons.back')}
        </Button>
      )}
    </Stack>
  );
};
