import { useGetTokenData } from 'hooks/useGetTokenData';
import { Stack, Typography } from '@mui/material';

export const CompanyData = (): JSX.Element => {
  const { company, user } = useGetTokenData();

  return (
    <Stack px={2} pb={2}>
      <Typography>
        {company.name}({company.tier})
      </Typography>

      <Typography>
        {user.name}({user.role})
      </Typography>
      <Typography>
        {company.type}
        {/* TODO jesli kilka - switch - spedycja/tansport info o typie firmy musi gdzies byc bo tak wyswietla sie apka */}
      </Typography>
    </Stack>
  );
};
