import axios from 'axios';
import { IOrder } from 'global';
import { servicesEnv } from './const';

const API_URL = `${servicesEnv}/api/`;
class OrderService {
  async addOrder(order: IOrder): Promise<string> {
    return axios.post(`${API_URL}add-order`, order).then((response) => response.data);
  }
  async editOrder(order: IOrder): Promise<string> {
    return axios.put(`${API_URL}edit-order`, order).then((response) => response.data);
  }
}
export default new OrderService();
