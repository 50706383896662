import { Stack, Chip } from '@mui/material';
import { useTranslation } from 'hooks/useTranslation';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setClientsFilters } from 'store/reducers/filtersReducer/filtersReducer';

export const Picked = (): JSX.Element => {
  const filters = useAppSelector((store) => store.filters.clientsFilters);
  const search = useAppSelector((store) => store.filters.clientsSearch);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { value, type } = search;
  const { status } = filters;

  const clearStatus = () => dispatch(setClientsFilters({ ...filters, status: '' }));

  return (
    <Stack direction="row" spacing={1} justifyContent="flex-end">
      {value ? <Chip size="small" label={`${type}: ${value}`} color="primary" /> : null}
      {status ? (
        <Chip
          size="small"
          label={t(`clientStatus.${status}`)}
          onDelete={clearStatus}
          color="primary"
        />
      ) : null}
    </Stack>
  );
};
