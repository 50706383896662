import { useState } from 'react';
import { Grid, Button, IconButton, Stack, Box, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { CargoData, CargoEvent, ICargo } from 'global';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import { AppCard, EmptyStateComponent, ErrorMessage } from 'components';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useTranslation } from 'hooks/useTranslation';
import { LoadingUnloadingPlaceProps } from './_partials.types';
import { ActionDialog } from './_ActionDialog';
import { ShowPlaceElement } from './_ShowPlaceElement';

export const LoadingUnloadingPlace = ({ isLoading }: LoadingUnloadingPlaceProps): JSX.Element => {
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState<CargoData | undefined>(undefined);

  const handleClickOpen = (data?: CargoData) => {
    if (data) {
      setEditData(data);
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEditData(undefined);
  };

  const {
    values: { events },
    setFieldValue
  } = useFormikContext<ICargo>();

  const deletePlace = (id: string) =>
    setFieldValue(
      'events',
      events.filter((el) => el.id !== id)
    );

  const { t } = useTranslation();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} mb={2}>
        <Stack direction="row" spacing={1} alignItems="center">
          <Button variant="contained" onClick={() => handleClickOpen()}>
            {isLoading ? t('buttons.addLoadingPlace') : t('buttons.addUnloadingPlace')}
          </Button>
          <InfoOutlinedIcon />
          <Typography variant="caption">{t('info.switchOrder')}</Typography>
        </Stack>
      </Grid>
      <Grid item xs={12} container spacing={2}>
        {events.length > 0 ? (
          events
            .filter((el) => el.type === (isLoading ? CargoEvent.loading : CargoEvent.unloading))
            .map((el, index) => (
              <Grid item xs={12}>
                <AppCard>
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <IconButton onClick={() => deletePlace(el.id)}>
                      <DeleteOutlineIcon />
                    </IconButton>
                    {index + 1}.
                    <Box flexGrow={1}>
                      <ShowPlaceElement data={el} />
                    </Box>
                    <IconButton onClick={() => handleClickOpen(el)}>
                      <EditIcon />
                    </IconButton>
                  </Stack>
                </AppCard>
              </Grid>
            ))
        ) : (
          <Grid item xs={12}>
            <EmptyStateComponent text={t('info.noPlaces')} />
            <ErrorMessage name="events" />
          </Grid>
        )}
      </Grid>
      {open && (
        <ActionDialog
          handleClose={handleClose}
          open={open}
          title={isLoading ? t('buttons.addLoadingPlace') : t('buttons.addUnloadingPlace')}
          isLoading={isLoading}
          editData={editData}
        />
      )}
    </Grid>
  );
};
