import { Grid, Stack, TextField, Typography, Divider } from '@mui/material';
import { useFormikContext } from 'formik';
import { ErrorMessage } from 'components';
import { IClient } from 'global';
import { useTranslation } from 'hooks/useTranslation';

interface MainInfoProps {
  edit?: boolean;
}

export const Contact = ({ edit = false }: MainInfoProps) => {
  const {
    values: {
      details: { phone, fax, website, email }
    },
    handleChange,
    errors
  } = useFormikContext<IClient>();
  const { t } = useTranslation();

  return (
    <Stack rowGap={2}>
      <Typography variant="h6">{t('subheaders.contact')}</Typography>
      <Divider />
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <TextField
            fullWidth
            size="small"
            label={t('inputs.phone')}
            name="details.phone"
            value={phone}
            onChange={handleChange}
            error={!!errors.details?.phone}
          />
          <ErrorMessage name="details.phone" />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            fullWidth
            label={t('inputs.fax')}
            size="small"
            name="details.fax"
            value={fax}
            onChange={handleChange}
            error={!!errors.details?.fax}
          />
          <ErrorMessage name="details.fax" />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            fullWidth
            label={t('inputs.website')}
            size="small"
            name="details.website"
            value={website}
            onChange={handleChange}
            error={!!errors.details?.website}
          />
          <ErrorMessage name="details.website" />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            fullWidth
            label={t('inputs.email')}
            size="small"
            name="details.email"
            type="email"
            value={email}
            onChange={handleChange}
            error={!!errors.details?.email}
          />
          <ErrorMessage name="details.email" />
        </Grid>
      </Grid>
    </Stack>
  );
};
