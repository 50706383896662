import { Button, Stack } from '@mui/material';
import { useTranslation } from 'hooks/useTranslation';
import { AddInvoiceForm } from './AddInvoiceForm';

export const AddInvoice = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Stack rowGap={3}>
      <AddInvoiceForm />
      <Button form="invoiceAddForm" variant="contained" type="submit">
        {t('buttons.save')}
      </Button>
    </Stack>
  );
};
