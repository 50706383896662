import { Stack } from '@mui/material';
import { Translation } from 'hooks/useTranslation';
import { AddShipmentForm } from '../Shipments/components/AddShipmentForm';

export type ShipmentActions = 'editShipment';

export const shipmentActions = (t: Translation) => [
  {
    type: 'editShipment' as ShipmentActions,
    chlidren: (
      <Stack rowGap={2}>
        <AddShipmentForm edit />
      </Stack>
    ),
    title: t('shipmentActions.editShipment')
  }
];
