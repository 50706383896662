import { useEffect, useState } from 'react';
import { EmptyStateComponent } from 'components';
import { IOrder } from 'global';
import { useTranslation } from 'hooks/useTranslation';
import { useAppSelector } from 'store/hooks';
import { OrdersTable } from './OrdersTable/OrdersTable';

export const OrdersList = () => {
  const [ordersList, setOrdersList] = useState<IOrder[]>([]);
  const { t } = useTranslation();

  // const filters = useAppSelector((store) => store.carriers.filters);
  // const carriersToShow = filterCarriers(carrierssList, filters);

  return ordersList.length > 0 ? (
    <OrdersTable orders={ordersList} />
  ) : (
    <EmptyStateComponent text={t('info.noOrders')} />
  );
};
