import { Grid, TextField, FormControl, InputLabel, Select, Button, MenuItem } from '@mui/material';
import { nanoid } from '@reduxjs/toolkit';
import { Dayjs } from 'dayjs';
import { Form, FormikProvider, useFormik } from 'formik';
import { toast } from 'react-hot-toast';
import { DatePicker } from 'components';
import { Bills, Currencies, IRound, RoundCostTypes, initialBill } from 'global';
import { useTranslation } from 'hooks/useTranslation';
import roundService, { EditRoundProps } from 'services/round.service';

interface BillProps {
  round: IRound;
  handleClose: () => void;
}

export const Bill = ({ round, handleClose }: BillProps): JSX.Element => {
  const { t } = useTranslation();

  const formik = useFormik<Bills>({
    initialValues: { ...initialBill, id: nanoid(), date: new Date() },
    // validationSchema: roundValidationSchema(t),
    onSubmit: async (values) => {
      const updatedBills = [...round.bills];
      updatedBills.push(values);

      const editedRound: EditRoundProps = {
        ...round,
        shipments: round.shipments.map((el) => el.id),
        bills: updatedBills
      };

      const res = await roundService.editRound(editedRound).catch(() => {
        toast.error(t('errors.addBill'));
      });
      if (!res) return;
      toast.success(t('success.addBill'));
      formik.resetForm();
      handleClose();
    }
  });

  const {
    values: { type, date, amount, currency, quantity },
    handleChange,
    setFieldValue
  } = formik;

  const handleDate = (e: Dayjs | null) => setFieldValue(`date`, e);

  return (
    <Grid item xs={12}>
      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit} id="addBill">
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel>{t('inputs.type')}</InputLabel>
                <Select required value={type} label={t('inputs.type')} onChange={handleChange}>
                  {Object.values(RoundCostTypes).map((el) => (
                    <MenuItem key={el} value={el}>
                      {el}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} md={3}>
              <DatePicker value={date} label={t('inputs.date')} handleChange={handleDate} />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                value={amount}
                name="amount"
                label={t('inputs.amount')}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl fullWidth>
                <InputLabel>{t('inputs.currency')}</InputLabel>
                <Select
                  required
                  name="currency"
                  value={currency}
                  label={t('inputs.currency')}
                  onChange={handleChange}
                >
                  {Object.values(Currencies).map((el) => (
                    <MenuItem key={el} value={el as string}>
                      {el}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                value={quantity}
                name="quantity"
                label={t('inputs.quantity')}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          <Button disabled={formik.isSubmitting} form="addBill" type="submit" variant="contained">
            {t('buttons.save')}
          </Button>
        </Form>
      </FormikProvider>
    </Grid>
  );
};
