import { Translation } from 'hooks/useTranslation';

interface Buttons {
  path: string;
  label: string;
}

export const buttons = (t: Translation): Buttons[] => [
  {
    path: '/admin/users-management',
    label: t('subheaders.userManagement')
  },
  {
    path: '/admin/company-settings',
    label: t('subheaders.companySettings')
  }
  //   {
  //     path: '/admin/orders-settings',
  //     label: t('subheaders.ordersSettings'),
  //   },
  //   {
  //     path: '/admin/invoices-settings',
  //     label: t('subheaders.invoicesSettings'),
  //   }
];
