import OpenWithIcon from '@mui/icons-material/OpenWith';
import { Dimensions } from 'global';
import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'hooks/useTranslation';

interface DimensionsDataProps {
  dimensions: Dimensions;
}

export const DimensionsData = ({
  dimensions: { height, length, width, palletsCapacity, ldm }
}: DimensionsDataProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Stack direction="row" spacing={2}>
      <OpenWithIcon />
      <Stack>
        <Typography>
          L{length}/W{width}/H{height}
        </Typography>
        <Typography>
          {t('inputs.palletsCapacity')}: {palletsCapacity}
        </Typography>
        <Typography>{ldm} LDM</Typography>
      </Stack>
    </Stack>
  );
};
