import { FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import { useFormikContext } from 'formik';
import { AppCard } from 'components';
import { ICarrier } from 'global';
import { CarrierStatus } from 'global/enum/_carrier';
import { useTranslation } from 'hooks/useTranslation';

export const Status = () => {
  const {
    values: { status },
    handleChange
  } = useFormikContext<ICarrier>();
  const { t } = useTranslation();

  return (
    <AppCard>
      <Grid container rowGap={2}>
        <Grid item xs={6} md={3}>
          <FormControl fullWidth>
            <InputLabel>Status</InputLabel>
            <Select value={status} name="status" label="status" onChange={handleChange}>
              {Object.values(CarrierStatus).map((el) => (
                <MenuItem key={el} value={el}>
                  {el}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </AppCard>
  );
};
