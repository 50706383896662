import { Translation } from 'hooks/useTranslation';
import { Position, Status } from '../../AddCar/AddCarFrom/partials';

export type FleetCarInfoActions = 'editPosition' | 'editStatus';

export const fleetCarInfoActions = (t: Translation) => [
  {
    title: t('buttons.editPosition'),
    type: 'editPosition' as FleetCarInfoActions,
    children: <Position />
  },
  {
    title: t('buttons.editStatus'),
    type: 'editStatus' as FleetCarInfoActions,
    children: <Status />
  }
];
