import { Stack, Typography } from '@mui/material';
import { AppCard } from 'components';
import { useGetDictonaries } from 'hooks/useGetDictonaries';
import { useTranslation } from 'hooks/useTranslation';

export const Support = (): JSX.Element => {
  const { t } = useTranslation();

  const { data } = useGetDictonaries();

  return (
    <AppCard>
      <Stack>
        <Typography>Faq</Typography>
        <Typography>
          Kontakt z selectem jako tematy - bugi, problemy, features, platnosci, inne
        </Typography>
        <Typography>historia wyslanych i odpowiedzi</Typography>
        <Typography>Opisy flow</Typography>
      </Stack>
    </AppCard>
  );
};
