import { useState } from 'react';
import { Box, Grid, Card, useTheme } from '@mui/material';
import { AlertMessage } from 'components';
import logo from 'images/logobig.jpg';
import { ResetPassword, LoginForm } from './components';
import { Message } from './Login.model';

export const Login = (): JSX.Element => {
  const [isLogin, setIsLogin] = useState(true);
  const [message, setMessage] = useState<Message | null>(null);
  const theme = useTheme();

  return (
    <Grid container height={{ xs: '200px', md: '100vh' }}>
      <Grid
        item
        xs={12}
        md={3}
        container
        textAlign="center"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12}>
          <Box width="auto" height={{ xs: '100px', md: '200px' }} component="img" src={logo} />
        </Grid>
      </Grid>
      <Grid item xs={12} md={9} container bgcolor={theme.palette.grey[200]}>
        <Grid item xs={10} md={6} mx="auto" my={{ xs: '100px', md: '200px' }}>
          <Card sx={{ px: 2 }}>
            {isLogin ? (
              <LoginForm
                setResetPage={() => setIsLogin(false)}
                setMessageData={(data: Message) => setMessage(data)}
              />
            ) : (
              <ResetPassword
                setLoginPage={() => setIsLogin(true)}
                setMessageData={(data: Message) => setMessage(data)}
              />
            )}
            {message && (
              <Box mb={3}>
                <AlertMessage message={message.text} status={message.status} />
              </Box>
            )}
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
};
