import { Form, FormikProvider, useFormik } from 'formik';
import { AlertMessage, Loader } from 'components';
import { ICompany, initialCompany } from 'global';
import { useTranslation } from 'hooks/useTranslation';
import { Typography, Breadcrumbs, Link as MuiLink, Stack, Button } from '@mui/material';

import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import companyService from 'services/company.service';
import { toast } from 'react-hot-toast';
import { CompanySettingsForm } from './CompanySettingsForm';

export const CompanySettings = () => {
  const [editMode, setEditMode] = useState(false);
  const { t } = useTranslation();

  const { data, isLoading, error, isFetching, refetch } = useQuery(
    'company',
    () => {},
    //  companyService.getCompany(),
    {
      // onSuccess(res) {
      //   if (res) {
      //     formik.setValues(res);
      //   }
      // },
      // onError() {
      //   toast.error(t('errors.getData'));
      // }
    }
  );

  const formik = useFormik<ICompany>({
    initialValues: data || initialCompany,
    // validationSchema: validationSchema(t),
    onSubmit: async (values) => {
      const res = await companyService.editCompany(values).catch(() => {
        toast.error(t('errors.editCompany'));
      });

      if (!res) return;

      toast.success(t('success.editCompany'));
      formik.resetForm();
      await refetch();
      setEditMode(false);
    }
  });

  if (isLoading || isFetching) return <Loader />;
  if (error || !data) return <AlertMessage message={t('errors.getData')} status="error" />;

  const toggleEditMode = () => setEditMode(!editMode);

  return (
    <Stack rowGap={2}>
      <Breadcrumbs>
        <MuiLink fontWeight="bold" component={Link} underline="hover" color="inherit" to="/admin">
          {t('headers.adminPanel')}
        </MuiLink>
        <Typography color="text.primary">{t('subheaders.companySettings')}</Typography>,
      </Breadcrumbs>

      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit} id="userAddForm">
          <Stack rowGap={2}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography variant="h5" component="span">
                {t('subheaders.companySettings')}
              </Typography>
              <Stack direction="row" spacing={2}>
                {editMode && (
                  <Button
                    disabled={formik.isSubmitting}
                    variant="contained"
                    form="userAddForm"
                    type="submit"
                  >
                    {t('buttons.save')}
                  </Button>
                )}
                <Button variant="outlined" onClick={toggleEditMode}>
                  {editMode ? 'Close' : 'Edit'}
                </Button>
              </Stack>
            </Stack>
            <CompanySettingsForm disabled={!editMode} />
          </Stack>
        </Form>
      </FormikProvider>
    </Stack>
  );
};
