import { TableCell, TableRow, useTheme } from '@mui/material';
import { nanoid } from '@reduxjs/toolkit';
import { IClient, tableRowsColor } from 'global';
import { tableCells } from './ClientsTable.const';

interface RowProps {
  client: IClient;
  index: number;
}

export const Row = ({ client, index }: RowProps): JSX.Element => {
  const theme = useTheme();
  const cells = tableCells(client, theme);

  return (
    <TableRow sx={tableRowsColor(index, theme)}>
      {cells.map(({ value, width }, cellIndex) => (
        <TableCell
          key={nanoid()}
          align={cellIndex === cells.length - 1 ? 'right' : 'inherit'}
          component="th"
          scope="row"
          sx={{ width }}
        >
          {value}
        </TableCell>
      ))}
    </TableRow>
  );
};
