import { Grid, TextField, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { ErrorMessage } from 'components';
import { ICarrier } from 'global';

export const Correspondence = (): JSX.Element => {
  const {
    values: {
      details: { correspondenceAdress }
    },
    handleChange,
    errors
  } = useFormikContext<ICarrier>();

  if (!correspondenceAdress) {
    return <div />;
  }

  const { adressLine1, adressLine2, postcode, city, country } = correspondenceAdress;

  return (
    <>
      <Grid item xs={12}>
        <Typography component="span" variant="h6">
          Adres korespondencyjny
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          label="adressLine1"
          name="details.correspondenceAdress.adressLine1"
          value={adressLine1}
          onChange={handleChange}
          // error={!!errors.details?.correspondenceAdress?.adress}
        />
        {/* <ErrorMessage name="details.correspondenceAdress.adress" /> */}
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          label="adressLine2"
          name="details.correspondenceAdress.adressLine2"
          value={adressLine2}
          onChange={handleChange}
          // error={!!errors.details?.correspondenceAdress?.adressNumb}
        />
        {/* <ErrorMessage name="details.correspondenceAdress.adressNumb" /> */}
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          fullWidth
          label="postcode"
          name="details.correspondenceAdress.postcode"
          value={postcode}
          onChange={handleChange}
          // error={!!errors.details?.correspondenceAdress?.postcode}
        />
        <ErrorMessage name="details.correspondenceAdress.postcode" />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          fullWidth
          label="city"
          name="details.correspondenceAdress.city"
          value={city}
          onChange={handleChange}
          // error={!!errors.details?.correspondenceAdress?.city}
        />
        <ErrorMessage name="details.correspondenceAdress.city" />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          fullWidth
          label="country"
          name="details.correspondenceAdress.country"
          value={country}
          onChange={handleChange}
          // error={!!errors.details?.correspondenceAdress?.country}
        />
        <ErrorMessage name="details.correspondenceAdress.country" />
      </Grid>
    </>
  );
};
