import { AlertMessage, Loader, StandardTable } from 'components';
import { IDriver } from 'global';
import { DialogTypes } from 'pages/Drivers/Drivers.types';
import { useState, ChangeEvent } from 'react';
import { useTheme, TablePagination } from '@mui/material';
import { useTranslation } from 'hooks/useTranslation';
import { useGetDrivers } from 'hooks/useGetDrivers';
import { tableHeaders } from './DriversTable.const';
import { Row } from './Row';
import { Filter, Picked } from '../Filters';

interface DriversTableProps {
  handleOpen: (dialogType: DialogTypes, data?: IDriver) => void;
}

export const DriversTable = ({ handleOpen }: DriversTableProps): JSX.Element => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const theme = useTheme();
  const { t } = useTranslation();

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const { data, isLoading, error } = useGetDrivers({ page, rowsPerPage });

  if (isLoading) return <Loader />;
  if (error || !data) return <AlertMessage message={t('errors.getData')} status="error" />;

  const rows = data.map((driver, index) => (
    <Row key={driver.id} driver={driver} index={index} handleOpen={handleOpen} />
  ));

  const rowsToShow = rows.filter(
    (_, index) => index < (page + 1) * rowsPerPage && index >= page * rowsPerPage
  );

  return (
    <>
      <StandardTable
        filters={<Filter />}
        picked={<Picked />}
        headers={tableHeaders}
        items={rowsToShow}
        emptyState={t('info.noDrivers')}
      />
      {rows.length > 0 && (
        <TablePagination
          sx={{
            bgcolor:
              theme.palette.mode === 'dark' ? theme.palette.grey[600] : theme.palette.grey[100]
          }}
          rowsPerPageOptions={[20, 50, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </>
  );
};
