import axios from 'axios';
import { CargoData, Position } from 'global';
import { toast } from 'react-hot-toast';
import authHeader from './auth-header';
import { servicesEnv } from './const';

const API_URL = `${servicesEnv}v1/api/cargoData`;

class CargoDataService {
  async addCargoData(data: CargoData, cargoId: string): Promise<boolean> {
    const res = await axios.post(`${API_URL}/${cargoId}`, data, {
      headers: authHeader()
    });

    if (res.data.error) {
      toast.error(res.data.error);
      return false;
    }

    return true;
  }

  async getCargoDataList(companyId: string, name: string): Promise<Position[]> {
    const res = await axios.get(
      `${API_URL}/list?${new URLSearchParams({
        company: companyId,
        name
      })}`,
      {
        headers: authHeader()
      }
    );

    if (res.data.error) {
      toast.error(res.data.error);
      return [];
    }

    console.log(res.data);

    return res.data;
  }
}

export default new CargoDataService();
