import { TableCell, TableRow, useTheme } from '@mui/material';
import { nanoid } from '@reduxjs/toolkit';
import { IShipment } from 'global';
import { tableRowsColor } from 'global/utils/_colors';
import { tableCells } from '../Board.const';
import { Options } from '../Board.helpers';

interface RowProps {
  item: IShipment;
  index: number;
  type: Options;
}

export const Row = ({ item, index, type }: RowProps): JSX.Element => {
  const theme = useTheme();
  const cells = tableCells(item, type);

  return (
    <TableRow sx={tableRowsColor(index, theme)}>
      {cells.map(({ value, width }, cellIndex) => (
        <TableCell
          key={nanoid()}
          align={cellIndex === cells.length - 1 ? 'right' : 'inherit'}
          component="th"
          scope="row"
          sx={{ width }}
        >
          {value}
        </TableCell>
      ))}
    </TableRow>
  );
};
