import axios from 'axios';
import { IDriver } from 'global';
import { toast } from 'react-hot-toast';
import authHeader from './auth-header';
import { servicesEnv } from './const';

interface GetDriversProps {
  name: string;
  status: string;
  company: string;
  sortBy: string;
  projectBy: string;
  limit: number;
  page: number;
}

const API_URL = `${servicesEnv}v1/api/drivers`;
class DriverService {
  async getDrivers({
    name = '',
    status = '',
    company = '',
    sortBy = '',
    projectBy = '',
    limit = 20,
    page = 1
  }: GetDriversProps): Promise<IDriver[]> {
    const res = await axios.get(
      `${API_URL}?${new URLSearchParams({
        name,
        status,
        company,
        sortBy,
        projectBy,
        limit: String(limit),
        page: String(page)
      })}`,
      {
        headers: authHeader()
      }
    );

    if (res.data.error) {
      toast.error(res.data.error);
      return [];
    }

    return res.data.results;
  }
  async addDriver(driver: IDriver): Promise<boolean> {
    const res = await axios.post(API_URL, driver, {
      headers: authHeader()
    });

    if (res.data.error) {
      toast.error(res.data.error);
      return false;
    }

    return true;
  }
  async editDriver(driver: IDriver): Promise<boolean> {
    const res = await axios.patch(`${API_URL}/${driver.id}`, driver, {
      headers: authHeader()
    });

    if (res.data.error) {
      toast.error(res.data.error);
      return false;
    }

    return true;
  }
}
export default new DriverService();
