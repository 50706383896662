import { Translation } from 'hooks/useTranslation';
import * as yup from 'yup';

export const validationSchema = (t: Translation) =>
  yup.object({
    name: yup.string().required(t('errors.required')),
    // ocpTill:
    // ocpFrom: null,
    details: yup.object({
      adress: yup.object({
        adressLine1: yup.string().required(t('errors.required')),
        postcode: yup.string().required(t('errors.required')),
        city: yup.string().required(t('errors.required')),
        country: yup.string().required(t('errors.required'))
      }),
      email: yup.string().required(t('errors.required')),
      nip: yup.string().required(t('errors.required'))
    }),
    dispo: yup
      .array(
        yup.object().shape({
          name: yup.string().required(t('errors.required')),
          phone: yup.string().required(t('errors.required')),
          email: yup.string().required(t('errors.required'))
        })
      )
      .min(1, 'Min 1 element'),
    cars: yup.array(
      yup.object().shape({
        name: yup.string().required(t('errors.required')),
        phone: yup.string().required(t('errors.required')),
        plates: yup.string().required(t('errors.required'))
      })
    )
  });
