import { Stack } from '@mui/material';
import { Adress, Contact, Dispo } from '../Carriers/components/AddCarrier/AddCarrierFrom/partials';
import { CarrierCar } from '../Carriers/components/AddCarrier/AddCarrierFrom/partials/_CarrierCar';
import { Status } from '../Carriers/components/AddCarrier/AddCarrierFrom/partials/_Status';

export type CarrierActions = 'editContact' | 'editStatus' | 'editWorkers';

export const carrierActions = [
  {
    type: 'editContact' as CarrierActions,
    chlidren: (
      <Stack rowGap={2}>
        <Adress />
        <Contact />
      </Stack>
    ),
    title: 'Edit contact informations'
  },
  {
    type: 'editStatus' as CarrierActions,
    chlidren: <Status />,
    title: 'Edit client status'
  },
  {
    type: 'editWorkers' as CarrierActions,
    chlidren: (
      <Stack rowGap={2}>
        <Dispo />
        <CarrierCar />
      </Stack>
    ),
    title: 'Edit workers'
  }
];
