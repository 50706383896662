import { Stack, Breadcrumbs, Typography } from '@mui/material';
import { AppCard } from 'components';
import { useTranslation } from 'hooks/useTranslation';
import { useEffect, useState } from 'react';
import resultService from 'services/result.service';
import { useAppSelector } from 'store/hooks';

type ComissionMonth = Record<number, { value: number }>;
export type ComissionYear = Record<number, ComissionMonth>;

export const Stats = (): JSX.Element => {
  const [comissions, setComissions] = useState<ComissionYear>({});
  const { t, n } = useTranslation();
  const companyCurrency = useAppSelector((store) => store.company.company?.currency);
  const companyId = useAppSelector((store) => store.company.company?.id) || '';

  useEffect(() => {
    resultService.getCompanyComissions(companyId).then((res) => setComissions(res));
  }, []);

  return (
    <Stack rowGap={2}>
      <Breadcrumbs>
        <Typography fontWeight="bold" color="text.primary">
          {t('headers.stats')}
        </Typography>
      </Breadcrumbs>

      <AppCard>
        <Stack>
          <Typography variant="h5" component="span">
            {t('inputs.comission')}
          </Typography>
          {Object.entries(comissions)
            .reverse()
            .map(([key, value]) => (
              <Stack>
                <Typography>{key}</Typography>
                {Object.entries(value)
                  .reverse()
                  .map(([month, comissionValue]) => (
                    <Typography>
                      {t(`months.${month}`)}: {n(Number(comissionValue.value), companyCurrency)}
                    </Typography>
                  ))}
              </Stack>
            ))}
        </Stack>
      </AppCard>
    </Stack>
  );
};
