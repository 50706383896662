import { Breadcrumbs, Typography, Button } from '@mui/material';
import { useState } from 'react';
import { FullDialog, StandardLayout } from 'components';
import { useTranslation } from 'hooks/useTranslation';
import { Form, FormikProvider, useFormik } from 'formik';
import { ICargo, initialCargo } from 'global';
import cargoService from 'services/cargo.service';
import { useGetCargo } from 'hooks/useGetCargo';
import { toast } from 'react-hot-toast';
import { useGetTokenData } from 'hooks/useGetTokenData';
import cargoDataService from 'services/cargoData.service';
import { validationSchema } from './Cargo.const';
import { AddCargo, CargoTable } from './components';
import { setMainValues } from './Cargo.helpers';

export const Cargo = (): JSX.Element => {
  const [openAdding, setOpenAdding] = useState(false);
  const { t } = useTranslation();
  const { refetch } = useGetCargo({});
  const token = useGetTokenData();

  const handleOpen = () => setOpenAdding(true);
  const handleClose = () => {
    setOpenAdding(false);
    formik.resetForm();
  };

  const formik = useFormik<ICargo>({
    initialValues: { ...initialCargo, guardian: { id: token.user.id, name: token.user.name } },
    validationSchema: validationSchema(t),
    onSubmit: async (values) => {
      const allValues: ICargo = setMainValues(values, token);

      const events = [...allValues.events];
      const createCargoData = { ...allValues, events: [] };

      const cargoID = await cargoService.addCargo(createCargoData).catch(() => {
        toast.error(t('errors.addCargo'));
      });

      if (!cargoID) return;

      const promisses = events.map((el) => cargoDataService.addCargoData(el, cargoID));
      await Promise.all(promisses);

      toast.success(t('success.cargoAdded'));
      formik.resetForm();
      handleClose();
      refetch();
    }
  });

  return (
    <StandardLayout
      breadcrumbs={
        <Breadcrumbs>
          <Typography fontWeight="bold" color="text.primary">
            {t('headers.cargo')}
          </Typography>
        </Breadcrumbs>
      }
      button={
        <Button variant="contained" onClick={handleOpen}>
          {t('buttons.addCargo')}
        </Button>
      }
      content={
        <>
          {openAdding && (
            <FullDialog handleClose={handleClose} open={openAdding} title={t('buttons.addCargo')}>
              <FormikProvider value={formik}>
                <Form onSubmit={formik.handleSubmit} id="addCargoForm">
                  <AddCargo />
                </Form>
              </FormikProvider>
            </FullDialog>
          )}
          <CargoTable />
        </>
      }
    />
  );
};
