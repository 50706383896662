import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { useFormikContext } from 'formik';
import { AppCard } from 'components';
import { Currencies, IClient } from 'global';
import { useTranslation } from 'hooks/useTranslation';

export const Payment = () => {
  const {
    values: { currency, paymentTerm },
    handleChange
  } = useFormikContext<IClient>();
  const { t } = useTranslation();

  return (
    <AppCard>
      <Stack rowGap={2}>
        <Typography variant="h6">{t('inputs.payment')}</Typography>
        <Grid container spacing={2}>
          <Grid item xs={6} md={3}>
            <TextField
              fullWidth
              size="small"
              label={t('inputs.paymentTerm')}
              name="paymentTerm"
              value={paymentTerm}
              onChange={handleChange}
              required
              //   error={!!errors.details?.phone}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <FormControl fullWidth size="small">
              <InputLabel>{t('inputs.currency')}</InputLabel>
              <Select
                value={currency}
                name="currency"
                label={t('inputs.currency')}
                onChange={handleChange}
              >
                {Object.values(Currencies).map((el) => (
                  <MenuItem key={el} value={el}>
                    {el}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Stack>
    </AppCard>
  );
};
