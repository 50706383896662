import { IDriver } from 'global';
import { DateTranslation, Translation } from 'hooks/useTranslation';
import EditIcon from '@mui/icons-material/Edit';
import { DialogTypes } from 'pages/Drivers/Drivers.types';
import { Theme } from '@mui/material';
import { getStatusChip } from './DriversTable.helpers';

export const tableHeaders = (t: Translation): string[] => [
  t('inputs.fullName'),
  t('inputs.phone'),
  t('inputs.email'),
  t('w drodze do i blachy'),
  '',
  ''
];

export const tableCells = (
  handleOpen: (dialogType: DialogTypes, data?: IDriver) => void,
  driver: IDriver,
  t: Translation,
  d: DateTranslation,
  theme: Theme
) => [
  {
    value: driver.details.name,
    width: '20%'
  },
  {
    value: driver.details.phone,
    width: '20%'
  },
  {
    value: driver.email,
    width: '45%'
  },
  {
    value:
      driver.rounds.length > 0
        ? `${d(driver.rounds[0].inRoadTo)}(${driver.rounds[0].car.plates})`
        : '-',
    width: '10%'
  },
  {
    value: getStatusChip(driver.status, t),
    width: '8%'
  },
  {
    value: (
      <EditIcon
        onClick={() => handleOpen('EDIT', driver)}
        sx={{ cursor: 'pointer', color: theme.palette.grey[400] }}
      />
    ),
    width: '2%'
  }
];
