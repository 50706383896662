import { Translation } from 'hooks/useTranslation';
import { EditCarData } from './components/EditCarData';

export type FleetCarActions = 'editCar';

export const fleetCarActions = (t: Translation) => [
  {
    type: 'editCar' as FleetCarActions,
    chlidren: <EditCarData />,
    title: t('buttons.editCar')
  }
];
