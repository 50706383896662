import { Chip, Stack } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'hooks/useTranslation';
import { useAppSelector } from 'store/hooks';
import { setCarsFilters, setCarsSearch } from 'store/reducers/filtersReducer/filtersReducer';

export const PickedPanel = () => {
  const filters = useAppSelector((store) => store.filters.carsFilters);
  const search = useAppSelector((store) => store.filters.carsSearch);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { plates } = search;
  const { status, country } = filters;

  const clearPlates = () => dispatch(setCarsSearch({ ...search, plates: '' }));

  const clearStatus = () => dispatch(setCarsFilters({ ...filters, status: [] }));
  const clearCountry = () => dispatch(setCarsFilters({ ...filters, country: '' }));

  return (
    <Stack direction="row" flexWrap="wrap" spacing={1} mt={1} justifyContent="flex-end">
      {plates && <Chip label={plates} variant="outlined" onDelete={clearPlates} color="primary" />}
      {country && (
        <Chip label={country} variant="outlined" onDelete={clearCountry} color="primary" />
      )}
      {status.length > 0 && (
        <Chip
          label={status.map((el) => t(`carStatus.${el}`)).join(', ')}
          variant="outlined"
          onDelete={clearStatus}
        />
      )}
    </Stack>
  );
};
