import { useEffect, useState } from 'react';
import { EmptyStateComponent } from 'components';
import { IInvoice } from 'global';
import { useTranslation } from 'hooks/useTranslation';
import { useAppSelector } from 'store/hooks';
import { InvoicesTable } from './InvoicesTable/InvoicesTable';

export const InvoicesList = () => {
  const [invoicesList, setInvoicesList] = useState<IInvoice[]>([]);
  const { t } = useTranslation();

  // const carsToShow = filterInvoices(invoicesList, filters);

  return <EmptyStateComponent text={t('info.noInvoices')} />;
};
