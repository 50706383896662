import { useLocation } from 'react-router';
import {
  AlertMessage,
  AppCard,
  CarData,
  CarDocuments,
  DimensionsData,
  FullDialog,
  Loader,
  PositionInfo,
  StandardView
} from 'components';
import { Grid, Typography, Button, Breadcrumbs, Link as MuiLink, ButtonGroup } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'hooks/useTranslation';
import { ICar, initialCar } from 'global';
import { toast } from 'react-hot-toast';
import carService from 'services/car.service';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { Form, FormikProvider, useFormik } from 'formik';
import { fleetCarActions, FleetCarActions } from './FleetCar.helpers';
import { Collapse } from './Collapse/Collapse';
import { validationSchema } from '../Fleet/components';

export const FleetCar = () => {
  const [edit, setEdit] = useState<FleetCarActions | null>(null);
  const location = useLocation();
  const { t } = useTranslation();
  const carId = location.pathname.replace('/fleet/', '');

  const { data, isLoading, error, isFetching, refetch } = useQuery(
    ['car', carId],
    () => {
      return carService.getCar(carId);
    },
    {
      refetchOnWindowFocus: false,
      onSuccess(res) {
        if (res) {
          formik.setValues(res);
        }
      },
      onError() {
        toast.error(t('errors.getData'));
      }
    }
  );

  const hadleOpen = (action: FleetCarActions) => setEdit(action);
  const handleClose = () => setEdit(null);
  const refetchData = () => {
    refetch();
  };

  const formik = useFormik<ICar>({
    initialValues: data || initialCar,
    validationSchema: validationSchema(t),
    onSubmit: async (values) => {
      const res = await carService.editCar(values).catch(() => {
        toast.error(t('errors.editCar'));
      });

      if (!res) return;

      toast.success(t('success.carEdited'));
      refetchData();
      handleClose();
    }
  });

  if (isLoading || isFetching) return <Loader />;
  if (error || !data) return <AlertMessage message={t('errors.getData')} status="error" />;

  const dialogActions = fleetCarActions(t);
  const dialogProps = dialogActions.find((el) => el.type === edit);

  const { createdAt, updatedAt } = data;

  return (
    <StandardView
      breadcrumbs={
        <Breadcrumbs>
          <MuiLink component={Link} fontWeight="bold" underline="hover" color="inherit" to="/fleet">
            {t('headers.fleet')}
          </MuiLink>
          <Typography color="text.primary">{data.plates}</Typography>,
        </Breadcrumbs>
      }
      createdAt={createdAt}
      updatedAt={updatedAt}
      buttons={
        <ButtonGroup variant="contained">
          {dialogActions.map(({ type, title }) => (
            <Button key={title} onClick={() => hadleOpen(type)} variant="contained">
              {title}
            </Button>
          ))}
        </ButtonGroup>
      }
      content={
        <>
          <Grid item xs={12}>
            <Typography variant="h5">{data.plates}</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <AppCard fullHeight>
              <PositionInfo data={data.position} />
            </AppCard>
          </Grid>
          <Grid item xs={12} md={6}>
            <AppCard fullHeight>
              <DimensionsData dimensions={data.details.dimensions} />
            </AppCard>
          </Grid>
          <Grid item xs={12} md={6}>
            <AppCard fullHeight>
              <CarData car={data} />
            </AppCard>
          </Grid>
          <Grid item xs={12} md={6}>
            <AppCard fullHeight>
              <CarDocuments car={data} />
            </AppCard>
          </Grid>
          <Grid item xs={12}>
            <Collapse positions={data.rounds} />
          </Grid>
          {dialogProps && (
            <FullDialog handleClose={handleClose} open={Boolean(edit)} title={dialogProps.title}>
              <FormikProvider value={formik}>
                <Form onSubmit={formik.handleSubmit} id="carEditForm">
                  {dialogProps.chlidren}
                </Form>
              </FormikProvider>
            </FullDialog>
          )}
        </>
      }
    />
  );
};
