import { TextField, Autocomplete } from '@mui/material';
import { useState, useEffect } from 'react';
import { Position } from 'global';
import { useFormikContext } from 'formik';
import cargoDataService from 'services/cargoData.service';
import { useAppSelector } from 'store/hooks';

export const AutocompleteEvent = (): JSX.Element => {
  const [name, setName] = useState('');
  const [options, setOptions] = useState<Position[]>([]);

  const { setFieldValue, resetForm } = useFormikContext<Position>();

  const companyId = useAppSelector((store) => store.company.company?.id) || '';

  useEffect(() => {
    const getData = setTimeout(() => {
      cargoDataService
        .getCargoDataList(companyId, name)
        .then((res) => setOptions(res))
        .catch((err) => console.log(err));
    }, 500);

    return () => clearTimeout(getData);
  }, [name]);

  const optionsArray: string[] = options.map((el) => el.company);

  return (
    <Autocomplete
      options={optionsArray}
      fullWidth
      onChange={(event, value) => {
        const pickedOption = options.find((el) => el.company === value);
        if (pickedOption) {
          setFieldValue('position', pickedOption);
        } else {
          resetForm();
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          onChange={(e) => setName(e.target.value)}
          label="Wyszkaj po nazwie firmy"
        />
      )}
    />
  );
};
