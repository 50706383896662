import axios from 'axios';
import { ICar } from 'global';
import { toast } from 'react-hot-toast';
import { CarsFilters, CarsSearch } from 'store/reducers/filtersReducer';
import authHeader from './auth-header';
import { servicesEnv } from './const';

interface GetCarsProps {
  plates: string;
  status: string;
  company: string;
  sortBy: string;
  projectBy: string;
  limit: number;
  page: number;
}

const API_URL = `${servicesEnv}v1/api/cars`;
class CarService {
  async getCars({
    plates = '',
    status = '',
    company = '',
    sortBy = '',
    projectBy = '',
    limit = 20,
    page = 1
  }: GetCarsProps): Promise<ICar[]> {
    const res = await axios.get(
      `${API_URL}?${new URLSearchParams({
        plates,
        status,
        company,
        sortBy,
        projectBy,
        limit: String(limit),
        page: String(page)
      })}`,
      {
        headers: authHeader()
      }
    );

    if (res.data.error) {
      toast.error(res.data.error);
      return [];
    }

    return res.data.results;
  }
  async getCar(id: string): Promise<ICar | undefined> {
    const res = await axios.get(`${API_URL}/${id}`, {
      headers: authHeader()
    });

    if (res.data.error) {
      toast.error(res.data.error);
      return undefined;
    }

    return res.data;
  }
  async addCar(car: ICar): Promise<boolean> {
    const res = await axios.post(API_URL, car, {
      headers: authHeader()
    });

    if (res.data.error) {
      toast.error(res.data.error);
      return false;
    }

    return true;
  }
  async editCar(car: ICar): Promise<boolean> {
    const res = await axios.patch(`${API_URL}/${car.id}`, car, {
      headers: authHeader()
    });

    if (res.data.error) {
      toast.error(res.data.error);
      return false;
    }

    return res.data;
  }
}
export default new CarService();
