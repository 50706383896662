import { Button, Stack } from '@mui/material';
import { useFormikContext } from 'formik';
import { ICar } from 'global';
import { useTranslation } from 'hooks/useTranslation';
import { AddCarForm } from 'pages/Fleet/components/AddCar/AddCarFrom';

export const EditCarData = () => {
  const { t } = useTranslation();
  const { isSubmitting } = useFormikContext<ICar>();

  return (
    <Stack rowGap={3}>
      <AddCarForm edit />
      <Button disabled={isSubmitting} form="carEditForm" type="submit" variant="contained">
        {t('buttons.save')}
      </Button>
    </Stack>
  );
};
