import { Grid, TextField } from '@mui/material';
import { useFormikContext } from 'formik';
import { ErrorMessage } from 'components';
import { ICarrier } from 'global';
import { useTranslation } from 'hooks/useTranslation';

interface DimensionsAndCapacityProps {
  index: number;
}

export const DimensionsAndCapacity = ({ index }: DimensionsAndCapacityProps) => {
  const {
    values: { cars },
    handleChange
  } = useFormikContext<ICarrier>();
  const { t } = useTranslation();

  return (
    <>
      <Grid item xs={6} md={2}>
        <TextField
          fullWidth
          label="Wysokość"
          size="small"
          name={`cars[${index}].dimensions.height`}
          type="number"
          value={cars[index].dimensions.height}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={6} md={2}>
        <TextField
          fullWidth
          label="Szerokość"
          size="small"
          type="number"
          name={`cars[${index}].dimensions.width`}
          value={cars[index].dimensions.width}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={6} md={2}>
        <TextField
          fullWidth
          label="Długość"
          size="small"
          name={`cars[${index}].dimensions.length`}
          type="number"
          value={cars[index].dimensions.length}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={6} md={2}>
        <TextField
          fullWidth
          label="Waga auta"
          size="small"
          name={`cars[${index}].capacity.carWeight`}
          value={cars[index].capacity.carWeight}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={6} md={2}>
        <TextField
          fullWidth
          label="Ładowność"
          size="small"
          name={`cars[${index}].capacity.cargoCapacity`}
          value={cars[index].capacity.cargoCapacity}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={6} md={1}>
        <TextField
          fullWidth
          size="small"
          label="palletscapacity"
          name={`cars[${index}].dimensions.palletsCapacity`}
          type="number"
          value={cars[index].dimensions.palletsCapacity}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={6} md={1}>
        <TextField
          fullWidth
          label="ldm"
          size="small"
          name={`cars[${index}].dimensions.ldm`}
          type="number"
          value={cars[index].dimensions.ldm}
          onChange={handleChange}
        />
      </Grid>
    </>
  );
};
