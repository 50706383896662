import { CarStatus } from 'global';
import { Translation } from 'hooks/useTranslation';
import { Theme } from '@mui/material';

export const getColorsAndLabels = (status: CarStatus, t: Translation, theme: Theme) => {
  switch (status) {
    case 'empty':
      return { color: theme.palette.success.light, label: t('carStatus.empty') };
    case 'inactive':
      return { color: theme.palette.grey[300], label: t('carStatus.inactive') };
    case 'taken':
      return { color: theme.palette.error.light, label: t('carStatus.taken') };
    case 'waitForPartload':
      return { color: theme.palette.warning.light, label: t('carStatus.waitForPartload') };
    default:
      return { color: theme.palette.common.white, label: '' };
  }
};
