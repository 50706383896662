import {
  AdminPanel,
  Cargo,
  Carriers,
  Client,
  Clients,
  CompanySettings,
  Drivers,
  Fleet,
  FleetCar,
  InovicesSettings,
  Invoice,
  Invoices,
  MapComponent,
  NoAccess,
  Orders,
  OrdersSettings,
  Settings,
  Shipments,
  Stats,
  UserManagement,
  CargoPage,
  Carrier,
  Order,
  DriverPage,
  Board,
  Rounds,
  Round,
  Shipment,
  Support
} from 'pages';
import { policies } from '../permissions';

export const appRoutes = [
  {
    path: 'board',
    component: <Board />,
    policy: policies.canView
  },
  {
    path: 'drivers',
    component: <Drivers />,
    policy: policies.canView
  },
  {
    path: 'fleet',
    component: <Fleet />,
    policy: policies.canView
  },
  {
    path: 'fleet/:id',
    component: <FleetCar />,
    policy: policies.canView
  },
  {
    path: 'cargo',
    component: <Cargo />,
    policy: policies.canView
  },
  {
    path: 'cargo/:id',
    component: <CargoPage />,
    policy: policies.canView
  },
  {
    path: 'map',
    component: <MapComponent />,
    policy: policies.canView
  },
  {
    path: 'clients',
    component: <Clients />,
    policy: policies.canView
  },
  {
    path: 'clients/:id',
    component: <Client />,
    policy: policies.canView
  },
  {
    path: 'rounds',
    component: <Rounds />,
    policy: policies.canView
  },
  {
    path: 'rounds/:id',
    component: <Round />,
    policy: policies.canView
  },
  {
    path: 'carriers',
    component: <Carriers />,
    policy: policies.canView
  },
  {
    path: 'shipments',
    component: <Shipments />,
    policy: policies.canView
  },
  {
    path: 'shipments/:id',
    component: <Shipment />,
    policy: policies.canView
  },
  {
    path: 'carriers/:id',
    component: <Carrier />,
    policy: policies.canView
  },
  {
    path: 'invoices',
    component: <Invoices />,
    policy: policies.canView
  },
  {
    path: 'invoices/:id',
    component: <Invoice />,
    policy: policies.canView
  },
  {
    path: 'orders',
    component: <Orders />,
    policy: policies.canView
  },
  {
    path: 'orders/:id',
    component: <Order />,
    policy: policies.canView
  },
  {
    path: 'stats',
    component: <Stats />,
    policy: policies.canView
  },
  {
    path: 'admin',
    component: <AdminPanel />,
    policy: policies.canView
  },
  {
    path: 'admin/users-management',
    component: <UserManagement />,
    policy: policies.canView
  },
  {
    path: 'admin/orders-settings',
    component: <OrdersSettings />,
    policy: policies.canView
  },
  {
    path: 'admin/invoices-settings',
    component: <InovicesSettings />,
    policy: policies.canView
  },
  {
    path: 'admin/company-settings',
    component: <CompanySettings />,
    policy: policies.canView
  },
  {
    path: 'settings',
    component: <Settings />,
    policy: policies.canView
  },

  {
    path: 'no-permission',
    component: <NoAccess />,
    policy: policies.canView
  },
  {
    path: 'driver-page',
    component: <DriverPage />,
    policy: policies.canView
  },
  {
    path: 'support',
    component: <Support />,
    policy: policies.canView
  }
];
