import { Chip, Theme } from '@mui/material';
import { ICar } from 'global';
import { useTranslation } from 'hooks/useTranslation';
import { getColorsAndLabels } from './StatusChip.const';

interface StatusChipProps {
  car: ICar;
  theme: Theme;
}

export const StatusChip = ({
  car: { status, additionalStatus },
  theme
}: StatusChipProps): JSX.Element => {
  const { t } = useTranslation();

  const data = getColorsAndLabels(status, t, theme);

  return (
    <Chip
      sx={{ bgcolor: data.color, width: '100%' }}
      label={`${data.label} ${
        additionalStatus !== 'none' ? `(${t(`additionalCarStatus.${additionalStatus}`)})` : ''
      }`}
      variant="outlined"
    />
  );
};
