import { useState, useEffect } from 'react';
import { IDecodedToken } from 'global';
import jwtDecode from 'jwt-decode';
import { useAppSelector } from 'store/hooks';

export const initTokenData = {
  exp: 1,
  company: {
    id: '',
    name: '',
    currency: '',
    tier: '',
    type: '',
    paidTill: '',
    status: ''
  },
  user: {
    id: '',
    name: '',
    role: '',
    status: ''
  }
};

export const useGetTokenData = () => {
  const [token, setToken] = useState<IDecodedToken>(initTokenData);

  const stateToken = useAppSelector((store) => store.auth.data);

  useEffect(() => {
    const loggedUser = localStorage.getItem('cc');

    if (loggedUser) {
      const parsedToken = JSON.parse(loggedUser);
      const decodedToken = jwtDecode<IDecodedToken>(parsedToken);
      setToken(decodedToken);
    } else {
      setToken(initTokenData);
    }
  }, [stateToken]);

  return token;
};
