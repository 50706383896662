import { AppCard } from 'components';
import { Grid } from '@mui/material';
import { EditPassword } from './EditPassword';

export const Settings = (): JSX.Element => (
  <AppCard>
    <Grid item xs={12}>
      <EditPassword />
    </Grid>
  </AppCard>
);
