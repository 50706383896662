import { Currencies, ICar, IShipment } from 'global';
import {
  Grid,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  InputAdornment,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Stack
} from '@mui/material';
import { useFormikContext } from 'formik';
import { useTranslation } from 'hooks/useTranslation';

interface CarCostProps {
  pickedCar: ICar | null;
}

export const CarCost = ({ pickedCar }: CarCostProps) => {
  const {
    values: {
      carCost: { custom, value, currency, customValue },
      distance: { emptyKm, loadedKm }
    },
    handleChange,
    setFieldValue
  } = useFormikContext<IShipment>();
  const { t, n } = useTranslation();

  const handleSetDefaultCost = () => {
    setFieldValue('carCost', {
      custom: false,
      value: Number(pickedCar?.details.costPerKm.value) * (Number(emptyKm) + Number(loadedKm)),
      currency: pickedCar?.details.costPerKm.currency,
      customValue: ''
    });
  };

  const handleSetCustomCost = () =>
    setFieldValue('carCost', {
      custom: true,
      value: '',
      currency: pickedCar?.details.costPerKm.currency,
      customValue: ''
    });

  return (
    <Grid item xs={12}>
      <Stack>
        <Typography>Kosz auta wg:</Typography>
        <FormControlLabel
          control={<Checkbox checked={!custom} onClick={handleSetDefaultCost} />}
          label={` ${n(
            Number(pickedCar?.details.costPerKm.value),
            pickedCar?.details.costPerKm.currency
          )}  /km`}
        />
        <Stack direction="row">
          <FormControlLabel
            control={<Checkbox checked={custom} onClick={handleSetCustomCost} />}
            label="Custom"
          />
          {custom && (
            <TextField
              size="small"
              label={t('inputs.custom')}
              name="carCost.customValue"
              value={customValue}
              onChange={handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <FormControl sx={{ width: '100px' }} size="small">
                      <InputLabel>{t('inputs.currency')}</InputLabel>
                      <Select
                        value={currency}
                        name="carCost.currency"
                        label={t('inputs.currency')}
                        onChange={handleChange}
                      >
                        {Object.values(Currencies).map((el) => (
                          <MenuItem key={el} value={el}>
                            {el}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </InputAdornment>
                )
              }}
            />
          )}
        </Stack>
      </Stack>
    </Grid>
  );
};
