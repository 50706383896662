import { AlertMessage, EmptyStateComponent, Loader, StandardTable } from 'components';
import { useState, ChangeEvent } from 'react';
import { useTheme, TablePagination } from '@mui/material';
import { useTranslation } from 'hooks/useTranslation';
import { useGetShipments } from 'hooks/useGetShipments';
import { Row } from './Row';
import { tableHeaders } from './ShipmentsTable.const';

export const ShipmentsTable = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const theme = useTheme();
  const { t } = useTranslation();

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const { data, isLoading, error, isFetching } = useGetShipments({ page, rowsPerPage });

  if (isLoading || isFetching) return <Loader />;
  if (error || !data)
    return <AlertMessage message="Problem przy pobieraniu danych" status="error" />;
  if (data.length === 0) {
    return <EmptyStateComponent text={t('info.noShipments')} />;
  }

  const rows = data.map((shipment, index) => (
    <Row key={shipment.id} shipment={shipment} index={index} />
  ));

  const rowsToShow = rows.filter(
    (_, index) => index < (page + 1) * rowsPerPage && index >= page * rowsPerPage
  );

  return (
    <>
      <StandardTable headers={tableHeaders} items={rowsToShow} />
      {rows.length > 0 && (
        <TablePagination
          sx={{
            bgcolor:
              theme.palette.mode === 'dark' ? theme.palette.grey[600] : theme.palette.grey[100]
          }}
          rowsPerPageOptions={[20, 50, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </>
  );
};
