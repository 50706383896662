import { useMemo, useState } from 'react';
import { GoogleMap, InfoWindow, useLoadScript } from '@react-google-maps/api';
import './styles.css';
import { Coordinates } from 'global';
import { useAppSelector } from 'store/hooks';
import { Typography } from '@mui/material';
import { CarMarker } from './CarMarker';
import { CargoMarker } from './CargoMarker';

const googleMapsApiKey = 'AIzaSyBzQ1DKtNZv04P4Tkml8RonB_sCgWBGWtc';

// 52.41803813643274, 16.920199345363518

export const MapComponent = () => {
  const center = useMemo(() => ({ lat: 52.418038, lng: 16.920199 }), []);
  const [selectedMarker, setSelectedMarker] = useState<Coordinates | null>(null);

  const handleChangeSelectedMarker = (data: Coordinates | null) => {
    if (data) {
      setSelectedMarker(data);
    } else {
      setSelectedMarker(null);
    }
  };

  // const cargoLoadingMarkers = cargo.map((el) =>
  //   el.loading
  //     .filter((el) => el.position.coordinates.lat && el.position.coordinates.lng)
  //     .map((el) => (
  //       <CargoMarker
  //         key={el.id}
  //         data={el}
  //         handleChangeSelectedMarker={handleChangeSelectedMarker}
  //         selectedMarker={selectedMarker}
  //         isLoading
  //       />
  //     ))
  // );

  // const cargoUnloadingMarkers = cargo.map((el) =>
  //   el.unloading
  //     .filter((el) => el.position.coordinates.lat && el.position.coordinates.lng)
  //     .map((el) => (
  //       <CargoMarker
  //         key={el.id}
  //         data={el}
  //         handleChangeSelectedMarker={handleChangeSelectedMarker}
  //         selectedMarker={selectedMarker}
  //       />
  //     ))
  // );

  // const carsMarkers = cars
  //   .filter((el) => el.position.coordinates.lat && el.position.coordinates.lng)
  //   .map((el) => (
  //     <CarMarker
  //       key={el.plates}
  //       data={el}
  //       handleChangeSelectedMarker={handleChangeSelectedMarker}
  //       selectedMarker={selectedMarker}
  //     />
  //   ));

  const { isLoaded } = useLoadScript({
    googleMapsApiKey
  });

  if (googleMapsApiKey === undefined) {
    return <div>Error</div>;
  }

  if (!isLoaded) return <div>Loading...</div>;

  return (
    <GoogleMap zoom={6} center={center} mapContainerClassName="map-container">
      {/* {[
        ...carsMarkers
        // ...cargoLoadingMarkers, ...cargoUnloadingMarkers
      ].map((el) => el)} */}
      {selectedMarker && (
        <InfoWindow
          onCloseClick={() => {
            handleChangeSelectedMarker(null);
          }}
          position={selectedMarker}
        >
          <Typography>siema</Typography>
        </InfoWindow>
      )}
    </GoogleMap>
  );
};
