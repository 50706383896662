import { Grid, TextField, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { CountrySelect, ErrorMessage } from 'components';
import { ICar } from 'global';
import { useTranslation } from 'hooks/useTranslation';

export const Position = () => {
  const {
    values: {
      position: { postcode, country, city, adressLine1, adressLine2 }
    },
    handleChange,
    errors,
    setFieldValue
  } = useFormikContext<ICar>();
  const { t } = useTranslation();

  const setCountry = (pickedCountry: string, code: string) =>
    setFieldValue('position.country', { name: pickedCountry, code });

  return (
    <Grid container rowGap={2}>
      <Grid item xs={12}>
        <Typography component="span" variant="h5">
          {t('inputs.position')}
        </Typography>
      </Grid>
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={6}>
          <CountrySelect value={country.name} label={t('inputs.country')} setCountry={setCountry} />
          <ErrorMessage name="position.country" />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label={t('inputs.postcode')}
            name="position.postcode"
            value={postcode}
            onChange={handleChange}
            required
            error={!!errors.position?.postcode}
          />
          <ErrorMessage name="position.postcode" />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label={t('inputs.city')}
            name="position.city"
            value={city}
            onChange={handleChange}
            required
            error={!!errors.position?.city}
          />
          <ErrorMessage name="position.city" />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label={t('inputs.adressLine1')}
            name="position.adressLine1"
            value={adressLine1}
            onChange={handleChange}
            required
            error={!!errors.position?.adressLine1}
          />
          <ErrorMessage name="position.adressLine1" />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label={t('inputs.adressLine2')}
            name="position.adressLine2"
            value={adressLine2}
            onChange={handleChange}
            error={!!errors.position?.adressLine2}
          />
          <ErrorMessage name="position.adressLine2" />
        </Grid>
      </Grid>
    </Grid>
  );
};
