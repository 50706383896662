import { ICar, IShipment } from 'global';
import {
  Grid,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider
} from '@mui/material';
import { useFormikContext } from 'formik';
import { useTranslation } from 'hooks/useTranslation';
import { useGetUsers } from 'hooks/useGetUsers';
import { useGetCars } from 'hooks/useGetCars';
import { MultipleAutocomplete } from './_MultipleAutocomplete';
import { EventsOrder } from './_EventsOrder';
import { CarCost } from './_CarCost';

interface MainInfoProps {
  edit?: boolean;
  pickedCar: ICar | null;
}

export const MainInfo = ({ edit = false, pickedCar }: MainInfoProps) => {
  const {
    values: {
      guardian,
      car,
      comments,
      distance: { emptyKm, loadedKm }
    },
    handleChange,
    setFieldValue
  } = useFormikContext<IShipment>();
  const { t } = useTranslation();

  const users = useGetUsers({}).data || [];
  const cars = useGetCars({}).data || [];

  const carsWithActiveRound = cars.filter((el) => el.rounds.length > 0);

  const disablePickCargo = !car.id || !emptyKm || !loadedKm || !guardian.id;

  return (
    <Grid item xs={12} container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5" component="span">
          {t('subheaders.mainInfo')}
        </Typography>
        <Divider />
      </Grid>
      <Grid item xs={12} md={3}>
        <FormControl size="small" fullWidth>
          <InputLabel>{t('inputs.guardian')}</InputLabel>
          <Select
            value={guardian.id}
            label={t('inputs.guardian')}
            onChange={(e) => {
              const pickedUser = users.find((el) => el.id === e.target.value);
              if (pickedUser) {
                setFieldValue('guardian', { name: pickedUser.details.name, id: pickedUser.id });
              }
            }}
          >
            {users.map((el) => (
              <MenuItem key={el.id} value={el.id}>
                {el.details.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={3}>
        <FormControl size="small" fullWidth>
          <InputLabel>{t('inputs.car')}</InputLabel>
          <Select
            value={car.id}
            label={t('inputs.car')}
            onChange={(e) => {
              const chosenCar = carsWithActiveRound.find((el) => el.id === e.target.value);
              if (chosenCar) {
                setFieldValue('car', { plates: chosenCar.plates, id: chosenCar.id });
                setFieldValue('round', chosenCar.rounds[0].id);
              }
            }}
          >
            {carsWithActiveRound
              // .filter((el) => el.activeTill)
              // TODO active round
              .map((el) => (
                <MenuItem key={el.id} value={el.id}>
                  {el.plates}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={3}>
        <TextField
          fullWidth
          size="small"
          label={t('inputs.emptyKm')}
          name="distance.emptyKm"
          value={emptyKm}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <TextField
          fullWidth
          size="small"
          label={t('inputs.loadedKm')}
          name="distance.loadedKm"
          value={loadedKm}
          onChange={handleChange}
        />
      </Grid>
      {pickedCar && <CarCost pickedCar={pickedCar} />}
      <Grid item xs={12}>
        <TextField
          fullWidth
          size="small"
          label={t('inputs.comments')}
          name="comments"
          value={comments}
          onChange={handleChange}
          multiline
          minRows={2}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography component="span" variant="h5">
          {t('subheaders.cargo')}
        </Typography>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <MultipleAutocomplete
          disabled={disablePickCargo}
          label={t('subheaders.cargo')}
          placeholder={t('inputs.pickCargo')}
        />
      </Grid>
      <EventsOrder />
    </Grid>
  );
};
