import { Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router';
import { AppCard } from 'components';

export const NoAccess = () => {
  const navigate = useNavigate();

  return (
    <AppCard>
      <>
        <Typography>UPS</Typography>
        <Typography>Nie masz dostępu do danej ścieżki</Typography>
        <Button variant="contained" onClick={() => navigate(-1)}>
          Cofnij
        </Button>
      </>
    </AppCard>
  );
};
