export enum ClientStatus {
  ok = 'ok',
  blocked = 'blocked',
  overLimit = 'overLimit',
  best = 'best',
  new = 'new'
}

export enum WorkerRole {
  accountant = 'accountant',
  dispo = 'dispo'
}
