import axios from 'axios';
import { IRound } from 'global';
import { toast } from 'react-hot-toast';
import { servicesEnv } from './const';
import authHeader from './auth-header';

const API_URL = `${servicesEnv}v1/api/rounds`;

export type EditRoundProps = Omit<IRound, 'shipments'> & { shipments: string[] };

interface GetRoundsProps {
  status: string;
  company: string;
  sortBy: string;
  projectBy: string;
  limit: number;
  page: number;
}

class RoundService {
  async getRounds({
    status = '',
    company = '',
    sortBy = '',
    projectBy = '',
    limit = 20,
    page = 1
  }: GetRoundsProps): Promise<IRound[]> {
    const res = await axios.get(
      `${API_URL}?${new URLSearchParams({
        status,
        company,
        sortBy,
        projectBy,
        limit: String(limit),
        page: String(page)
      })}`,

      {
        headers: authHeader()
      }
    );

    if (res.data.error) {
      toast.error(res.data.error);
      return [];
    }

    return res.data.results;
  }
  async getRound(id: string): Promise<IRound | undefined> {
    const res = await axios.get(`${API_URL}/${id}`, {
      headers: authHeader()
    });

    if (res.data.error) {
      toast.error(res.data.error);
      return undefined;
    }

    return res.data;
  }
  async addRound(round: IRound): Promise<boolean> {
    const res = await axios.post(API_URL, round, {
      headers: authHeader()
    });

    if (res.data.error) {
      toast.error(res.data.error);
      return false;
    }

    return true;
  }
  async editRound(round: EditRoundProps): Promise<boolean> {
    const res = await axios.patch(`${API_URL}/${round.id}`, round, {
      headers: authHeader()
    });

    if (res.data.error) {
      toast.error(res.data.error);
      return false;
    }

    return true;
  }
}
export default new RoundService();
