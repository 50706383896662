import axios from 'axios';
import { ICargo } from 'global';
import { toast } from 'react-hot-toast';
import authHeader from './auth-header';
import { servicesEnv } from './const';

interface GetCargoProps {
  status: string;
  company: string;
  sortBy: string;
  projectBy: string;
  limit: number;
  page: number;
}

const API_URL = `${servicesEnv}v1/api/cargo`;
class CargoService {
  async getCargos({
    status = '',
    company = '',
    sortBy = '',
    projectBy = '',
    limit = 20,
    page = 1
  }: GetCargoProps): Promise<ICargo[]> {
    const res = await axios.get(
      `${API_URL}?${new URLSearchParams({
        status,
        company,
        sortBy,
        projectBy,
        limit: String(limit),
        page: String(page)
      })}`,
      { headers: authHeader() }
    );

    if (res.data.error) {
      toast.error(res.data.error);
      return [];
    }

    return res.data.results;
  }
  async getCargo(id: string): Promise<ICargo | undefined> {
    const res = await axios.get(`${API_URL}/${id}`, { headers: authHeader() });

    if (res.data.error) {
      toast.error(res.data.error);
      return undefined;
    }

    return res.data;
  }
  async addCargo(cargo: ICargo): Promise<string> {
    const res = await axios.post(API_URL, cargo, { headers: authHeader() });

    if (res.data.error) {
      toast.error(res.data.error);
      return '';
    }

    return res.data.id;
  }
  async editCargo(cargo: Omit<ICargo, 'events'> & { events: string[] }): Promise<boolean> {
    const res = await axios.patch(`${API_URL}/${cargo.id}`, cargo, { headers: authHeader() });

    if (res.data.error) {
      toast.error(res.data.error);
      return false;
    }

    return true;
  }
}

export default new CargoService();
