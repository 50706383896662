import { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Box, TextField } from '@mui/material';
import { DesktopDatePicker, MobileDatePicker } from '@mui/x-date-pickers';

interface DatePickerProps {
  value: any;
  handleChange: (newValue: Dayjs | null) => void;
  label: string;
  small?: boolean;
}

export const DatePicker = ({ value, handleChange, label, small = false }: DatePickerProps) => (
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <>
      <Box display={{ md: 'block', xs: 'none' }}>
        <DesktopDatePicker
          label={label}
          inputFormat="YYYY/MM/DD"
          value={value}
          onChange={handleChange}
          renderInput={(params) => (
            <TextField required fullWidth size={small ? 'small' : 'medium'} {...params} />
          )}
        />
      </Box>
      <Box display={{ md: 'none', xs: 'block' }}>
        <MobileDatePicker
          label={label}
          inputFormat="YYYY/MM/DD"
          value={value}
          onChange={handleChange}
          renderInput={(params) => <TextField required {...params} />}
        />
      </Box>
    </>
  </LocalizationProvider>
);
