import { Stack, Typography, Box } from '@mui/material';
import { CargoData } from 'global';
import { useTranslation } from 'hooks/useTranslation';

interface LoadingUnloadingInfoProps {
  data: CargoData;
}

export const LoadingUnloadingInfo = ({
  data: {
    position: {
      company,
      postcode,
      city,
      country: { name },
      adressLine1,
      adressLine2
    },
    timeMax,
    timeMin,
    details
  }
}: LoadingUnloadingInfoProps): JSX.Element => {
  const { t, d } = useTranslation();

  // TODO   defaultSize: '',forChange: false, forChangeQuantity: ''

  return (
    <Stack>
      <Box>
        <Typography>
          {company} {adressLine1} {adressLine2}, {postcode} {city}, {name}{' '}
          <Typography variant="caption">
            ( {d(String(timeMin), true)} - {d(String(timeMax), true)})
          </Typography>
        </Typography>
      </Box>
      {details.map(({ ref, id, quantity, cargoType, adrCode, adr, palletsType, comments }) => (
        <>
          <Typography key={id}>
            {t('inputs.cargoType')}: {t(`cargoType.${cargoType}`)} {'  '}
            {t('inputs.quantity')}: {quantity} {'   '}
            {t('inputs.palletsType')}: {t(`cargoType.${palletsType}`)} {'  '}
            {ref && (
              <Typography component="span">
                {t('inputs.ref')}: {ref} {'   '}
              </Typography>
            )}
            {adr && (
              <Typography component="span">
                {t('inputs.adr')}
                {'   '}
              </Typography>
            )}
            {adrCode && (
              <Typography component="span">
                {t('inputs.adrCode')}: {adrCode} {'   '}
              </Typography>
            )}
          </Typography>
          {comments && <Typography>{comments}</Typography>}
        </>
      ))}
    </Stack>
  );
};
