import { Translation } from 'hooks/useTranslation';
import * as yup from 'yup';

export const validationSchema = (t: Translation) =>
  yup.object({
    plates: yup.string().required(t('errors.required')),
    details: yup.object().shape({
      dimensions: yup.object().shape({
        height: yup.number().min(0).max(1000).required(t('errors.required')),
        length: yup.number().min(0).max(1000).required(t('errors.required')),
        width: yup.number().min(0).max(1000).required(t('errors.required')),
        palletsCapacity: yup.number().min(0).max(1000).required(t('errors.required')),
        ldm: yup.number().min(0).max(1000).required(t('errors.required'))
      }),
      capacity: yup.object().shape({
        carWeight: yup.number().min(0).max(100000).required(t('errors.required')),
        cargoCapacity: yup.number().min(0).max(100000).required(t('errors.required'))
      })
    })
    // position: yup.object().shape({
    //   postcode: yup.string().required(t('errors.required')),
    //   city: yup.string().required(t('errors.required')),
    //   adressLine1: yup.string().required(t('errors.required'))
    // })
  });
