import { Stack } from '@mui/material';
import { Settlement, MainInfo } from './partials';

interface AddRoundProps {
  edit?: boolean;
}

export const AddRound = ({ edit = false }: AddRoundProps): JSX.Element => {
  return (
    <Stack rowGap={3} mb={4}>
      <Stack rowGap={2}>
        <MainInfo edit={edit} />
        {edit && <Settlement />}
      </Stack>
    </Stack>
  );
};
