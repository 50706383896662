import { Stack, Typography, Chip, Theme } from '@mui/material';
import { Link } from 'react-router-dom';
import { CargoEvent, getFromToTimes, ICargo } from 'global';
import { DateTranslation, NumberTranslation, Translation } from 'hooks/useTranslation';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { getColorsAndLabels, statusIcons } from './CargoTable.helper';

export const tableHeaders = (t: Translation) => [
  '',
  t('inputs.loading'),
  t('inputs.unloading'),
  '',
  t('inputs.weight'),
  t('inputs.additional'),
  t('inputs.price')
];

export const tableCells = (
  cargo: ICargo,
  d: DateTranslation,
  t: Translation,
  n: NumberTranslation,
  theme: Theme
) => {
  const {
    status,
    description,
    events,
    weight,
    price: { value, currency }
  } = cargo;
  const chipProps = getColorsAndLabels(status, t);
  return [
    {
      value: (
        <Link to={`${cargo.id}`} rel="noopener noreferrer">
          <ManageSearchIcon />
        </Link>
      ),
      width: '1%'
    },
    {
      value: events
        .filter((el) => el.type === CargoEvent.loading)
        .map(
          ({
            id, // eslint-disable-next-line
            status,
            timeMin,
            timeMax,
            position: {
              postcode,
              city,
              country: { code }
            }
          }) => (
            <Stack direction="row" key={id} justifyContent="space-between">
              <Stack>
                <Typography>
                  {postcode} {city} {code}
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="center">
                <Typography component="p" variant="caption">
                  {getFromToTimes(timeMin, timeMax)}
                </Typography>
                {statusIcons(theme)[status]}
              </Stack>
            </Stack>
          )
        ),
      width: '20%'
    },
    {
      value: events
        .filter((el) => el.type === CargoEvent.unloading)
        .map(
          ({
            id, // eslint-disable-next-line
            status,
            timeMax,
            timeMin,
            position: {
              postcode,
              city,
              country: { code }
            }
          }) => (
            <Stack direction="row" key={id} justifyContent="space-between">
              <Stack>
                <Typography>
                  {postcode} {city} {code}
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="center">
                <Typography component="p" variant="caption">
                  {getFromToTimes(timeMin, timeMax)}
                </Typography>
                {statusIcons(theme)[status]}
              </Stack>
            </Stack>
          )
        ),
      width: '20%'
    },
    {
      value: <Chip color={chipProps.color} label={chipProps.label} sx={{ width: '100%' }} />,
      width: '5%'
    },
    {
      value: `${weight} kg`,
      width: '5%'
    },
    {
      value: description,
      width: '15%'
    },
    {
      value: n(Number(value), currency),
      width: '10%'
    }
  ];
};
