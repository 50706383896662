import { IShipment } from 'global';
import { useTranslation } from 'hooks/useTranslation';
import { Stack, Typography } from '@mui/material';

interface ShipmentMainDataProps {
  shipment: IShipment;
}

export const ShipmentMainData = ({
  shipment: {
    distance: { emptyKm, loadedKm },
    guardian: { name },
    status,
    car: { plates }
  }
}: ShipmentMainDataProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Stack>
      <Typography>
        {t('inputs.guardian')}: {name}
      </Typography>
      <Typography>
        {t('inputs.status')}: {t(`cargoStatus.${status}`)}
      </Typography>
      <Typography>
        {t('inputs.distance')}: {Number(emptyKm)} km + {Number(loadedKm)} km
      </Typography>
      <Typography>
        {t('inputs.car')}: {plates}
      </Typography>
    </Stack>
  );
};
