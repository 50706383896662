import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { ErrorMessage } from 'components';
import { IInvoice, InvoiceElementDetails, units, vats } from 'global';
import { useTranslation } from 'hooks/useTranslation';

interface PositionProps {
  position: InvoiceElementDetails;
}

export const Position = ({ position }: PositionProps) => {
  const {
    values: { details },
    handleChange,
    setFieldValue
  } = useFormikContext<IInvoice>();
  const { t } = useTranslation();

  const { id, title, quantity, discount, unit, priceNetto, vat, valueBrutto } = position;

  const index = details.findIndex((el) => el.id === id);

  useEffect(() => {
    setFieldValue(
      `details[${index}].valueBrutto`,
      (priceNetto + (priceNetto * Number(vat)) / 100).toFixed(2)
    );
  }, [vat, priceNetto]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={5}>
        <TextField
          fullWidth
          label="Nazwa"
          name={`details[${index}].title`}
          value={title}
          onChange={handleChange}
          required
        />
        <ErrorMessage name="details.title" />
      </Grid>
      <Grid item xs={12} md={1}>
        <TextField
          fullWidth
          label="Ilość"
          type="number"
          name={`details[${index}].quantity`}
          value={quantity}
          onChange={handleChange}
          required
        />
      </Grid>
      <Grid item xs={12} md={1}>
        <FormControl fullWidth>
          <InputLabel>Jednostka</InputLabel>
          <Select
            value={unit}
            name={`details[${index}].unit`}
            label="Jednostka"
            onChange={handleChange}
          >
            {Object.values(units).map((el) => (
              <MenuItem key={el} value={el}>
                {el}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={1}>
        <FormControl fullWidth>
          <InputLabel>vat</InputLabel>
          <Select value={vat} name={`details[${index}].vat`} label="vat" onChange={handleChange}>
            {Object.values(vats).map((el) => (
              <MenuItem key={el} value={el}>
                {el}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={2}>
        <TextField
          fullWidth
          label="priceNetto"
          type="number"
          name={`details[${index}].priceNetto`}
          value={priceNetto}
          onChange={(e) => {
            handleChange(e);
            setFieldValue(`details[${index}].valueNetto`, e.target.value);
          }}
          required
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <TextField
          fullWidth
          disabled
          label="valueBrutto"
          type="number"
          //   name={`details[${index}].valueBrutto`}
          value={valueBrutto}
          //   onChange={handleChange}
          required
        />
      </Grid>
    </Grid>
  );
};
