import axios from 'axios';
import { ICarrier } from 'global';
import { servicesEnv } from './const';

const API_URL = `${servicesEnv}/api/`;
class CarrierService {
  async getCarrier(id: string): Promise<ICarrier> {
    return axios.get(`${API_URL}${id}`).then((response) => response.data);
  }
  async getCarriers(): Promise<ICarrier[]> {
    return axios.get(`${API_URL}carriers`).then((response) => response.data);
  }
  async addCarrier(carrier: ICarrier): Promise<string> {
    return axios.post(`${API_URL}add-carrier`, carrier).then((response) => response.data);
  }
  async editCarrier(carrier: ICarrier): Promise<string> {
    return axios.put(`${API_URL}edit-carrier`, carrier).then((response) => response.data);
  }
}
export default new CarrierService();
