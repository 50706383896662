import { MenuItem, Stack, Typography, useTheme } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'hooks/useTranslation';
import { useAppSelector } from 'store/hooks';
import { changeViewMode } from 'store/reducers/viewReducer/viewReducer';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';

export const ModeSwitch = (): JSX.Element => {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isDark = useAppSelector((store) => store.view.mode);

  return (
    <MenuItem onClick={() => dispatch(changeViewMode(isDark === 'light' ? 'dark' : 'light'))}>
      <Stack direction="row" spacing={1}>
        {theme.palette.mode === 'light' ? <Brightness7Icon /> : <Brightness4Icon />}
        <Typography>{t('buttons.switchMode')}</Typography>
      </Stack>
    </MenuItem>
  );
};
