import { Button, Breadcrumbs, Typography } from '@mui/material';
import { useState } from 'react';
import { FullDialog, StandardLayout } from 'components';
import { useFormik, FormikProvider, Form } from 'formik';
import { ICarrier, initialCarrierValues } from 'global';
import { useTranslation } from 'hooks/useTranslation';
import { nanoid } from '@reduxjs/toolkit';
import carrierService from 'services/carrier-service';
import { toast } from 'react-hot-toast';
import { useGetTokenData } from 'hooks/useGetTokenData';
import { AddCarrier } from './components/AddCarrier';
import { CarriersList } from './components/CarriersList';
import { validationSchema } from './components/AddCarrier/AddCarrier.const';
import { Filter, Picked, Search } from './components/Filters';

export const Carriers = () => {
  const [openAdding, setOpenAdding] = useState(false);
  const token = useGetTokenData();

  const handleOpen = () => setOpenAdding(true);
  const handleClose = () => setOpenAdding(false);
  const { t } = useTranslation();

  const formik = useFormik<ICarrier>({
    initialValues: initialCarrierValues,
    validationSchema: validationSchema(t),
    onSubmit: async (values) => {
      const carrierData = {
        ...values,
        id: nanoid(),
        company: token.company.id,
        addedBy: token.user.id
      };
      const res = await carrierService.addCarrier(carrierData).catch(() => {
        toast.error('Somethnig went wrong with adding the carrier');
      });

      if (!res) {
        return;
      }

      toast.success('Carrier added');
      formik.resetForm();
      handleClose();
    }
  });

  return (
    <StandardLayout
      breadcrumbs={
        <Breadcrumbs>
          <Typography color="text.primary">{t('headers.carriers')}</Typography>,
        </Breadcrumbs>
      }
      search={<Search />}
      button={
        <Button variant="contained" onClick={handleOpen}>
          {t('buttons.addCarrier')}
        </Button>
      }
      content={
        <>
          {openAdding && (
            <FullDialog handleClose={handleClose} open={openAdding} title={t('buttons.addCarrier')}>
              <FormikProvider value={formik}>
                <Form onSubmit={formik.handleSubmit} id="carrierAddForm">
                  <AddCarrier />
                </Form>
              </FormikProvider>
            </FullDialog>
          )}
          <CarriersList />
        </>
      }
    />
  );
};
