import { useLocation } from 'react-router';
import {
  AppCard,
  Loader,
  AlertMessage,
  LoadingUnloadingInfo,
  FullDialog,
  ShipmentMainData,
  StandardTable,
  StandardView
} from 'components';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import {
  Grid,
  Stack,
  Typography,
  Breadcrumbs,
  Link as MuiLink,
  TableCell,
  TableRow,
  Button,
  useTheme,
  ButtonGroup
} from '@mui/material';
import { useTranslation } from 'hooks/useTranslation';
import { useQuery } from 'react-query';
import { IShipment, initialShipment, tableRowsColor } from 'global';
import shipmentService from 'services/shipment.service';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
import { toast } from 'react-hot-toast';
import { ShipmentActions, shipmentActions } from './Shipment.helpers';

export const Shipment = () => {
  const [edit, setEdit] = useState<ShipmentActions | null>(null);
  const location = useLocation();
  const { t, n } = useTranslation();
  const shipmentId = location.pathname.replace('/shipments/', '');
  const theme = useTheme();

  const { data, isLoading, error, isFetching, refetch } = useQuery(
    ['shipment', shipmentId],
    () => shipmentService.getShipment(shipmentId),
    {
      onSuccess(res) {
        if (res) {
          console.log('data', res);
          formik.setValues(res);
        }
      },
      onError() {
        toast.error(t('errors.getData'));
      }
    }
  );

  const hadleOpen = (action: ShipmentActions) => setEdit(action);
  const handleClose = () => setEdit(null);

  const formik = useFormik<IShipment>({
    initialValues: data || initialShipment,
    // validationSchema: validationSchema(t),
    onSubmit: async (values) => {
      // !!!!!!!! JSON.stringify(one) === JSON.stringify(two);
      // if (data?.guardian !== values.guardian) {
      //   //updateUser -remove from old - add to new one
      //   // also remove comission from old one and add new object to new user
      // }
      // if (data?.car !== values.car) {
      //   // update car - remove from old - add to new one
      // }
      // if (data?.comission !== values.comission) {
      //   // update comission - replace with old one
      // }
      // if(data?.cargo !== values.cargo){
      //   // change cargos objects
      // }
      // if(data?.status !== values.status){
      //   // update only status
      // }
      // then update shipment object

      const res = await shipmentService.editShipment(values).catch(() => {
        toast.error(t('errors.editShipment'));
      });

      if (!res) return;

      toast.success(t('success.schipmentEdited'));
      formik.resetForm();
      await refetch();
      handleClose();
    }
  });

  if (isLoading || isFetching) return <Loader />;

  if (error || !data)
    return <AlertMessage message="Problem przy pobieraniu danych" status="error" />;

  const clientActionsItems = shipmentActions(t);
  const dialogProps = clientActionsItems.find((el) => el.type === edit);

  const { createdAt, updatedAt, comments, eventsOrder, cargo, carCost, comission, price } = data;

  return (
    <StandardView
      breadcrumbs={
        <Breadcrumbs>
          <MuiLink
            fontWeight="bold"
            component={Link}
            underline="hover"
            color="inherit"
            to="/shipments"
          >
            {t('headers.shipments')}
          </MuiLink>
          <Typography color="text.primary">{data.number}</Typography>,
        </Breadcrumbs>
      }
      createdAt={createdAt}
      updatedAt={updatedAt}
      buttons={
        <ButtonGroup variant="contained">
          {clientActionsItems.map(({ type, title }) => (
            <Button key={title} onClick={() => hadleOpen(type)} variant="contained">
              {title}
            </Button>
          ))}
        </ButtonGroup>
      }
      content={
        <>
          <Grid item xs={12}>
            <Typography mb={1} variant="h6" component="span">
              Ładunki
            </Typography>
            <StandardTable
              headers={() => ['client', 'number', 'price', '']}
              items={cargo.map(
                ({ client: { orderId, name }, id, price: { value, currency } }, index) => (
                  <TableRow sx={tableRowsColor(index, theme)}>
                    <TableCell component="th" scope="row" sx={{ width: '20%' }}>
                      {name}
                    </TableCell>
                    <TableCell component="th" scope="row" sx={{ width: '20%' }}>
                      {orderId}
                    </TableCell>
                    <TableCell component="th" scope="row" sx={{ width: '20%' }}>
                      {n(Number(value), currency)}
                    </TableCell>
                    <TableCell component="th" scope="row" align="right">
                      <Link to={`/cargo/${id}`}>
                        <ManageSearchIcon />
                      </Link>
                    </TableCell>
                  </TableRow>
                )
              )}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <AppCard>
              <ShipmentMainData shipment={data} />
            </AppCard>
          </Grid>
          <Grid item xs={12} md={4}>
            <AppCard fullHeight>
              <Stack>
                <Typography>total price: {n(Number(price.value), price.currency)}</Typography>
                <Typography>car cost: {n(Number(carCost.value), carCost.currency)}</Typography>
                {comission && (
                  <Typography>
                    comission: {n(Number(comission.value), comission.currency)}
                  </Typography>
                )}
              </Stack>
            </AppCard>
          </Grid>

          <Grid item xs={12} md={4}>
            <AppCard fullHeight>
              <Typography>comments: {comments}</Typography>
            </AppCard>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6" component="span">
              {t('inputs.loadings')}
            </Typography>
            <Grid container>
              {eventsOrder.loading.map((el, index) => (
                <Grid item xs={12} md={4}>
                  <Typography>{index + 1}</Typography>
                  <LoadingUnloadingInfo key={el.id} data={el} />
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" component="span">
              {t('inputs.unloadings')}
            </Typography>{' '}
            <Grid container>
              {eventsOrder.unloading.map((el, index) => (
                <Grid item xs={12} md={4}>
                  <Typography>{index + 1}</Typography>
                  <LoadingUnloadingInfo key={el.id} data={el} />
                </Grid>
              ))}
            </Grid>
          </Grid>

          {dialogProps && (
            <FullDialog handleClose={handleClose} open={Boolean(edit)} title={dialogProps.title}>
              <FormikProvider value={formik}>
                <Form onSubmit={formik.handleSubmit} id="shipmentEditForm">
                  <Stack rowGap={3}>{dialogProps.chlidren}</Stack>
                </Form>
              </FormikProvider>
            </FullDialog>
          )}
        </>
      }
    />
  );
};
