import { AlertMessage, EmptyStateComponent, Loader } from 'components';
import { ICompanyUser } from 'global';
import { useTranslation } from 'hooks/useTranslation';
import { useGetUsers } from 'hooks/useGetUsers';
import { UsersTable } from './UsersTable/UsersTable';
import { DialogTypes } from '../UserManagement.types';

interface UsersListProps {
  handleOpen: (dialogType: DialogTypes, data?: ICompanyUser) => void;
}

export const UsersList = ({ handleOpen }: UsersListProps): JSX.Element => {
  // const filters = useAppSelector((store) => store.filters);
  const { t } = useTranslation();

  const { data, isLoading, error, isFetching } = useGetUsers({});

  if (isLoading || isFetching) return <Loader />;
  if (error || !data) return <AlertMessage message={t('errors.getData')} status="error" />;
  if (data.length === 0) return <EmptyStateComponent text={t('info.noUsers')} />;

  // const driversToShow = filterDrivers(data, filters);

  return <UsersTable users={data} handleOpen={handleOpen} />;
};
