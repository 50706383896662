import { Grid, Stack, TextField, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { AppCard } from 'components';
import { IClient } from 'global';
import { useTranslation } from 'hooks/useTranslation';

export const Limit = () => {
  const {
    values: { limit },
    handleChange
  } = useFormikContext<IClient>();
  const { t } = useTranslation();

  return (
    <AppCard>
      <Stack rowGap={2}>
        <Typography variant="h6">{t('subheaders.limit')}</Typography>
        <Grid container spacing={2}>
          <Grid item xs={6} md={3}>
            <TextField
              fullWidth
              label={t('subheaders.limit')}
              size="small"
              name="limit"
              disabled
              value={limit}
              onChange={handleChange}
              required
            />
          </Grid>
        </Grid>
      </Stack>
    </AppCard>
  );
};
