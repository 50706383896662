import { IRound } from 'global';
import { DateTranslation, Translation } from 'hooks/useTranslation';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { Link } from 'react-router-dom';

export const tableHeaders = (t: Translation) => [
  '',
  t('inputs.driver'),
  t('inputs.kmCountStart'),
  t('inputs.kmCountEnd'),
  t('inputs.term')
];

export const tableCells = (round: IRound, d: DateTranslation) => [
  {
    value: (
      <Link to={`${round.id}`} rel="noopener noreferrer">
        <ManageSearchIcon />
      </Link>
    ),
    width: '1%'
  },
  {
    value: round.driver.name,
    width: '15%'
  },
  {
    value: round.kmCountStart,
    width: '15%'
  },
  {
    value: round.kmCountEnd,
    width: '10%'
  },
  {
    value: `${d(round.inRoadFrom)}-${d(round.inRoadTo)}`,
    width: '58%'
  }
];
