import axios from 'axios';
import { ICompanyUser, InitialCompanyUser } from 'global';
import { toast } from 'react-hot-toast';
import authHeader from './auth-header';
import { servicesEnv } from './const';

interface GetUsersProps {
  name: string;
  status: string;
  company: string;
  sortBy: string;
  projectBy: string;
  limit: number;
  page: number;
}

const API_URL = `${servicesEnv}v1/api/users`;
class UsersService {
  getUser = async (id: string): Promise<ICompanyUser | undefined> => {
    const res = await axios.get(`${API_URL}/${id}`, {
      headers: authHeader()
    });

    if (res.data.error) {
      toast.error(res.data.error);
      return undefined;
    }

    return res.data;
  };
  getUsers = async ({
    name = '',
    status = '',
    company = '',
    sortBy = '',
    projectBy = '',
    limit = 20,
    page = 1
  }: GetUsersProps): Promise<ICompanyUser[]> => {
    const res = await axios.get(
      `${API_URL}?${new URLSearchParams({
        name,
        status,
        company,
        sortBy,
        projectBy,
        limit: String(limit),
        page: String(page)
      })}`,
      { headers: authHeader() }
    );

    if (res.data.error) {
      toast.error(res.data.error);
      return [];
    }

    return res.data.results;
  };
  addUser = async (user: InitialCompanyUser): Promise<boolean> => {
    const res = await axios.post(API_URL, user, { headers: authHeader() });

    if (res.data.error) {
      toast.error(res.data.error);
      return false;
    }

    return true;
  };
  editUser = async (user: ICompanyUser): Promise<boolean> => {
    const res = await axios.patch(`${API_URL}/${user.id}`, user, { headers: authHeader() });

    if (res.data.error) {
      toast.error(res.data.error);
      return false;
    }

    return true;
  };
}
export default new UsersService();
