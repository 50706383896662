import { Button, Breadcrumbs, Typography } from '@mui/material';
import { useState } from 'react';
import { FullDialog, StandardLayout } from 'components';
import { useFormik, FormikProvider, Form } from 'formik';
import { IRound, initialRound } from 'global';
import { useTranslation } from 'hooks/useTranslation';
import { toast } from 'react-hot-toast';
import { useGetTokenData } from 'hooks/useGetTokenData';
import { useGetRounds } from 'hooks/useGetRounds';
import roundService from 'services/round.service';
import { nanoid } from '@reduxjs/toolkit';
import { roundValidationSchema } from './components/AddRound/AddRound.conts';
import { AddRound } from './components/AddRound';
import { RoundsTable } from './components';

export const Rounds = (): JSX.Element => {
  const [openAdding, setOpenAdding] = useState(false);

  const handleOpen = () => setOpenAdding(true);
  const handleClose = () => {
    setOpenAdding(false);
    formik.resetForm();
  };
  const { t } = useTranslation();
  const { refetch } = useGetRounds({});
  const token = useGetTokenData();

  const formik = useFormik<IRound>({
    initialValues: initialRound,
    validationSchema: roundValidationSchema(t),
    onSubmit: async (values) => {
      const newRound = {
        ...values,
        roundId: nanoid(),
        addedBy: token.user.id,
        company: token.company.id
      };

      const res = await roundService.addRound(newRound).catch(() => {
        toast.error(t('errors.addRound'));
      });
      if (!res) return;

      toast.success(t('success.roundAdded'));
      formik.resetForm();
      refetch();
      handleClose();
    }
  });

  return (
    <StandardLayout
      breadcrumbs={
        <Breadcrumbs>
          <Typography fontWeight="bold" color="text.primary">
            {t('headers.rounds')}
          </Typography>
        </Breadcrumbs>
      }
      // search={<Search />}
      // filters={<Filter />}
      // picked={<Picked />}
      button={
        <Button variant="contained" onClick={handleOpen}>
          {t('buttons.addRound')}
        </Button>
      }
      content={
        <>
          {openAdding && (
            <FullDialog handleClose={handleClose} open={openAdding} title={t('buttons.addRound')}>
              <FormikProvider value={formik}>
                <Form onSubmit={formik.handleSubmit} id="roundAddForm">
                  <AddRound />
                  <Button
                    disabled={formik.isSubmitting}
                    form="roundAddForm"
                    type="submit"
                    variant="contained"
                  >
                    {t('buttons.save')}
                  </Button>
                </Form>
              </FormikProvider>
            </FullDialog>
          )}
          <RoundsTable />
        </>
      }
    />
  );
};
