import { AlertMessage, EmptyStateComponent, FullDialog, Loader, StandardTable } from 'components';
import { useState, ChangeEvent } from 'react';
import { ICar } from 'global';
import { useGetCars } from 'hooks/useGetCars';
import { useTheme, TablePagination } from '@mui/material';
import { useTranslation } from 'hooks/useTranslation';
import { tableHeaders } from './FleetTable.const';
import { Row } from './Row';
import { CarInfoForm } from './CarInfo/CarInfoForm';
import { FleetCarInfoActions } from './CarInfo/CarInfo.helpers';
import { Filters, PickedPanel } from '../Filters';

export const FleetTable = (): JSX.Element => {
  const [car, setCar] = useState<ICar | null>(null);
  const [type, setType] = useState<FleetCarInfoActions | null>(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const theme = useTheme();
  const { t } = useTranslation();

  const handleClose = () => {
    setCar(null);
    setType(null);
  };
  const handleOpen = (pickedCar: ICar, actionType: FleetCarInfoActions | null) => {
    setCar(pickedCar);
    setType(actionType);
  };

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const { data, isLoading, error, isFetching } = useGetCars({ page, rowsPerPage });

  if (isLoading) return <Loader />;
  if (error || !data) return <AlertMessage message={t('errors.getData')} status="error" />;

  const rows = data.map((rowCar, index) => (
    <Row key={rowCar.id} car={rowCar} index={index} handleOpen={handleOpen} />
  ));

  const rowsToShow = rows.filter(
    (_, index) => index < (page + 1) * rowsPerPage && index >= page * rowsPerPage
  );

  return (
    <>
      <StandardTable
        headers={tableHeaders}
        items={rowsToShow}
        filters={<Filters />}
        picked={<PickedPanel />}
        emptyState={t('info.noCars')}
      />
      {rows.length > 0 && (
        <TablePagination
          sx={{
            bgcolor:
              theme.palette.mode === 'dark' ? theme.palette.grey[600] : theme.palette.grey[100]
          }}
          rowsPerPageOptions={[20, 50, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
      {car && type && (
        <FullDialog open={Boolean(car)} handleClose={handleClose} title={car?.plates || ''}>
          <CarInfoForm car={car} handleClose={handleClose} type={type} />
        </FullDialog>
      )}
    </>
  );
};
