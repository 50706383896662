import { ShipmentStatus } from '../enum';
import { IShipment } from '../types';
import { initialValueAndCurrency } from './_cargo';
import { initialComission } from './_comission';

export const initialShipment: IShipment = {
  id: '',
  shipmentId: '',
  number: '',
  company: '',
  createdAt: new Date(),
  round: '',
  updatedAt: new Date(),
  addedBy: '',
  guardian: {
    id: '',
    name: ''
  },
  cargo: [],
  distance: {
    emptyKm: '',
    loadedKm: ''
  },
  car: {
    id: '',
    plates: ''
  },
  comments: '',
  price: initialValueAndCurrency,
  carCost: {
    custom: false,
    value: '',
    currency: 'PLN',
    customValue: ''
  },
  status: ShipmentStatus.inProgress,
  comission: initialComission,
  percentageComission: [{ user: '', percent: 100 }],
  eventsOrder: {
    loading: [],
    unloading: []
  }
};
