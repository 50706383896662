import { ClientStatus, WorkerRole } from '../enum';
import { IClient, IInitialClient, IWorker } from '../types';
import { initialAdress } from './_company';

export const initialWorker: IWorker = {
  name: '',
  phone: '',
  email: '',
  role: WorkerRole.dispo,
  id: '',
  addedBy: ''
};

export const initialClientFormValues: IInitialClient = {
  clientId: '',
  name: '',
  nip: '',
  shortName: '',
  company: '',
  limit: '1000',
  paymentTerm: '',
  addedBy: '',
  currency: 'PLN',
  orders: [],
  invoices: [],
  workers: [],
  status: ClientStatus.new,
  details: {
    adress: {
      adressLine1: '',
      adressLine2: '',
      postcode: '',
      city: '',
      country: ''
    },
    phone: '',
    fax: '',
    website: '',
    email: '',
    bankNumber: '',
    bankName: '',
    isCorespondenceAdressDiffrent: false,
    correspondenceAdress: initialAdress
  }
};

export const initialClient: IClient = {
  ...initialClientFormValues,
  id: '',
  createdAt: new Date(),
  updatedAt: new Date()
};
