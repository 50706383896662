import { toast } from 'react-hot-toast';
import { UseQueryResult, useQuery } from 'react-query';
import carService from 'services/car.service';
import { useAppSelector } from 'store/hooks';
import { useEffect } from 'react';
import { HookProps, ICar } from 'global';
import { useTranslation } from './useTranslation';

export const useGetCars = ({
  page = 1,
  rowsPerPage = 20
}: HookProps): UseQueryResult<ICar[], unknown> => {
  const { t } = useTranslation();
  const filters = useAppSelector((store) => store.filters.carsFilters);
  const search = useAppSelector((store) => store.filters.carsSearch);

  const companyId = useAppSelector((store) => store.company.company?.id) || '';

  const query = useQuery(
    'cars',
    () =>
      carService.getCars({
        plates: search.plates,
        status: filters.status.join(','),
        company: companyId,
        sortBy: '',
        projectBy: '',
        limit: rowsPerPage,
        page
      }),
    {
      onSuccess() {},
      onError() {
        toast.error(t('errors.getData'));
      }
    }
  );

  useEffect(() => {
    query.refetch();
    // eslint-disable-next-line
  }, [filters, search, page, rowsPerPage]);

  return query;
};
