import { Translation } from 'hooks/useTranslation';
import { NavigateFunction } from 'react-router';
import { ICarrier } from 'global';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { CarrierStatus } from 'global/enum/_carrier';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import FiberNewOutlinedIcon from '@mui/icons-material/FiberNewOutlined';
import { Link } from 'react-router-dom';
import { Theme } from '@mui/material';

export const tableHeaders = (t: Translation) => ['', t('details.company'), 'nip', 'city', 'status'];

export const tableCells = (carrier: ICarrier, navigate: NavigateFunction, theme: Theme) => [
  {
    value: (
      <Link to={`${carrier.id}`} rel="noopener noreferrer">
        <ManageSearchIcon />
      </Link>
    ),
    width: '5%'
  },
  {
    value: carrier.name,
    width: '15%'
  },
  {
    value: carrier.nip,
    width: '15%'
  },
  {
    value: `${carrier.details.adress.postcode} ${carrier.details.adress.city}`,
    width: '68%'
  },
  {
    value: getStatusIcon(carrier.status, theme),
    width: '1%'
  }
];

export const getStatusIcon = (status: CarrierStatus, theme: Theme) => {
  switch (status) {
    case CarrierStatus.ok:
      return <CheckBoxOutlinedIcon sx={{ color: theme.palette.success.main }} />;
    case CarrierStatus.blocked:
      return <RemoveCircleOutlineOutlinedIcon sx={{ color: theme.palette.error.main }} />;
    case CarrierStatus.new:
      return <FiberNewOutlinedIcon />;
    default:
      return <div />;
  }
};
