import { Chip, Grid, Typography, Stack } from '@mui/material';
import { useFormikContext } from 'formik';
import { useTranslation } from 'hooks/useTranslation';
import { Accessories as AccessoriesElements, ICar } from 'global';

export const Accessories = () => {
  const {
    values: { accessories },
    setFieldValue
  } = useFormikContext<ICar>();
  const { t } = useTranslation();

  const toggleAccessories = (name: string) => {
    const isPicked = accessories.includes(name);
    if (isPicked) {
      setFieldValue(
        'accessories',
        accessories.filter((el) => el !== name)
      );
      return;
    }
    setFieldValue('accessories', [...accessories, name]);
  };

  return (
    <Grid container rowGap={2}>
      <Grid item xs={12}>
        <Typography component="span" variant="h5">
          {t('subheaders.accessories')}
        </Typography>
      </Grid>
      {/* TODO tlumacznie wyposazenia */}
      <Grid item xs={12}>
        <Stack direction="row" flexWrap="wrap" spacing={1}>
          {Object.keys(AccessoriesElements).map((el) => {
            const isPicked = accessories.includes(el);
            return (
              <Chip
                key={el}
                label={el}
                variant={isPicked ? 'filled' : 'outlined'}
                onClick={() => toggleAccessories(el)}
              />
            );
          })}
        </Stack>
      </Grid>
    </Grid>
  );
};
