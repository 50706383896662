import { OrderStatus } from 'global/enum';
import { IOrder } from '../types';
import { initialOrderSettings } from './_company';

export const initialOrder: IOrder = {
  id: '',
  user: '',
  company: '',
  createdAt: new Date(),
  updatedAt: new Date(),
  addedBy: '',
  number: '',
  cargo: [],
  comments: '',
  price: {
    value: '',
    currency: 'PLN',
    date: new Date()
  },
  // car: {
  //   type: typeOfShipment.own,
  //   plates: '',
  //   ownCar: undefined,
  //   carrier: undefined
  // },
  comission: {
    value: '',
    currency: 'PLN',
    date: new Date()
  },
  status: OrderStatus.created,
  margin: [],
  orderSettings: initialOrderSettings
};
