import { TableCell, TableRow, useTheme } from '@mui/material';
import { IShipment, tableRowsColor } from 'global';
import { useTranslation } from 'hooks/useTranslation';
import { nanoid } from '@reduxjs/toolkit';
import { tableCells } from './ShipmentsTable.const';

interface RowProps {
  shipment: IShipment;
  index: number;
}

export const Row = ({ shipment, index }: RowProps) => {
  const { t, d } = useTranslation();
  const theme = useTheme();
  const cells = tableCells(shipment, d, t);

  return (
    <TableRow sx={tableRowsColor(index, theme)}>
      {cells.map(({ value, width }, cellIndex) => (
        <TableCell
          key={nanoid()}
          align={cellIndex === cells.length - 1 ? 'right' : 'inherit'}
          component="th"
          scope="row"
          sx={{ width }}
        >
          {value}
        </TableCell>
      ))}
    </TableRow>
  );
};
