import { Translation } from 'hooks/useTranslation';
import * as yup from 'yup';

export const validationSchema = (t: Translation) =>
  yup.object({
    // id: yup.string().required(t('errors.required'))
    // dimensions: yup.object().shape({
    //   height: yup.number().min(0).max(1000).required(t('errors.required')),
    //   length: yup.number().min(0).max(1000).required(t('errors.required')),
    //   width: yup.number().min(0).max(1000).required(t('errors.required')),
    //   palletsCapacity: yup.number().min(0).max(1000).required(t('errors.required'))
    // }),
    // capacity: yup.object().shape({
    //   carCapacity: yup.number().min(0).max(100000).required(t('errors.required')),
    //   cargoCapacity: yup.number().min(0).max(100000).required(t('errors.required'))
    // }),
    // driver: yup.object().shape({
    //   driverName: yup.string().required(t('errors.required')),
    //   driverNumber: yup.number().required(t('errors.required'))
    // }),
    // position: yup.object().shape({
    //   company: yup.string().required(t('errors.required')),
    //   postcode: yup.string().required(t('errors.required')),
    //   city: yup.string().required(t('errors.required')),
    //   street: yup.string().required(t('errors.required')),
    //   number: yup.number().required(t('errors.required'))
    // })
  });
