import { useLocation } from 'react-router';
import {
  AppCard,
  Loader,
  AlertMessage,
  LoadingUnloadingInfo,
  FullDialog,
  CargoClientData,
  CargoMainInfoData,
  StandardView
} from 'components';
import {
  Grid,
  Stack,
  Typography,
  Breadcrumbs,
  Link as MuiLink,
  Button,
  ButtonGroup
} from '@mui/material';
import { useTranslation } from 'hooks/useTranslation';
import cargoService from 'services/cargo.service';
import { useQuery } from 'react-query';
import { CargoEvent, ICargo, initialCargo } from 'global';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { toast } from 'react-hot-toast';
import { Form, FormikProvider, useFormik } from 'formik';
import { validationSchema } from '../Cargo/Cargo.const';
import { CargoActions, cargoActions } from './CargoPage.helpers';

export const CargoPage = (): JSX.Element => {
  const [edit, setEdit] = useState<CargoActions | null>(null);
  const location = useLocation();
  const { t } = useTranslation();
  const cargoId = location.pathname.replace('/cargo/', '');

  const hadleOpen = (action: CargoActions) => setEdit(action);
  const handleClose = () => setEdit(null);

  const { data, isLoading, error, isFetching, refetch } = useQuery(
    ['cargo', cargoId],
    () => cargoService.getCargo(cargoId),
    {
      refetchOnWindowFocus: false,
      onSuccess(res) {
        if (res) {
          formik.setValues(res);
        }
      },
      onError() {
        toast.error(t('errors.getData'));
      }
    }
  );

  const formik = useFormik<ICargo>({
    initialValues: data || initialCargo,
    validationSchema: validationSchema(t),
    onSubmit: async (values) => {
      const res = cargoService
        .editCargo({ ...values, events: values.events.map((el) => el.id) })
        .catch(() => {
          toast.error(t('errors.editCargo'));
        });

      if (!res) return;

      toast.success(t('success.cargoEdited'));
      formik.resetForm();
      await refetch();
      handleClose();
    }
  });

  if (isLoading || isFetching) return <Loader />;

  if (error || !data)
    return <AlertMessage message="Problem przy pobieraniu danych" status="error" />;

  const clientActionsItems = cargoActions(t);
  const dialogProps = clientActionsItems.find((el) => el.type === edit);

  const { description, createdAt, updatedAt, accessories, events } = data;

  return (
    <StandardView
      breadcrumbs={
        <Breadcrumbs>
          <MuiLink fontWeight="bold" component={Link} underline="hover" color="inherit" to="/cargo">
            {t('headers.cargo')}
          </MuiLink>
          <Typography color="text.primary">{data.client.orderId}</Typography>,
        </Breadcrumbs>
      }
      createdAt={createdAt}
      updatedAt={updatedAt}
      buttons={
        <ButtonGroup variant="contained">
          {clientActionsItems.map(({ type, title }) => (
            <Button key={title} onClick={() => hadleOpen(type)}>
              {title}
            </Button>
          ))}
        </ButtonGroup>
      }
      content={
        <>
          <Grid item xs={12} md={4}>
            <AppCard fullHeight>
              <CargoMainInfoData cargo={data} />
            </AppCard>
          </Grid>
          <Grid item xs={12} md={4}>
            <AppCard fullHeight>
              <CargoClientData cargo={data} />
            </AppCard>
          </Grid>
          <Grid item xs={12} md={4}>
            <AppCard fullHeight>
              <Typography>Dokumenty</Typography>
            </AppCard>
          </Grid>
          <Grid item xs={12}>
            <AppCard>
              <Stack>
                <Typography>
                  {t('inputs.description')}: {description}
                </Typography>
                <Typography>
                  {t('subheaders.accessories')}:{' '}
                  {accessories.map((el) => t(`accessories.${el}`)).join()}
                </Typography>
              </Stack>
            </AppCard>
          </Grid>
          <Grid item xs={12} container>
            <Grid item xs={12}>
              <Typography fontWeight="bold">{t('inputs.loadings')}</Typography>
            </Grid>
            {events
              .filter((el) => el.type === CargoEvent.loading)
              .map((el) => (
                <Grid item xs={12} md={4}>
                  <AppCard key={el.id}>
                    <LoadingUnloadingInfo data={el} />
                  </AppCard>
                </Grid>
              ))}
          </Grid>
          <Grid item xs={12} container>
            <Grid item xs={12}>
              <Typography fontWeight="bold">{t('inputs.unloadings')}</Typography>
            </Grid>
            {events
              .filter((el) => el.type === CargoEvent.unloading)
              .map((el) => {
                return (
                  <Grid item xs={12} md={4}>
                    <AppCard key={el.id}>
                      <LoadingUnloadingInfo data={el} />
                    </AppCard>
                  </Grid>
                );
              })}
          </Grid>
          {dialogProps && (
            <FullDialog handleClose={handleClose} open={Boolean(edit)} title={dialogProps.title}>
              <FormikProvider value={formik}>
                <Stack rowGap={3}>
                  <Form onSubmit={formik.handleSubmit} id="cargoEditForm">
                    {dialogProps.chlidren}
                  </Form>
                  <Button form="cargoEditForm" type="submit" variant="contained">
                    {t('buttons.save')}
                  </Button>
                </Stack>
              </FormikProvider>
            </FullDialog>
          )}
        </>
      }
    />
  );
};
