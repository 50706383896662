import { Coordinates } from 'global/types';

const apikey = 'AIzaSyBzQ1DKtNZv04P4Tkml8RonB_sCgWBGWtc';

export const findPosition = async (
  aressLine1: string,
  city: string
): Promise<Coordinates | null> => {
  let coordinates: Coordinates | null = null;

  await fetch(
    `https://maps.googleapis.com/maps/api/geocode/json?address=${aressLine1},+${city}&key=${apikey}`
  )
    .then((response) => response.json())
    .then((res) => {
      if (res) {
        if (res.results.length > 0) {
          coordinates = res.results[0].geometry.location;
        }
      }
    })
    .catch((err) => console.log(err));

  return coordinates;
};
