import { IDecodedToken } from '../types';

export const isTokenValid = (token: IDecodedToken | null): boolean => {
  if (token === null) return false;
  const { exp } = token;
  if (Date.now() >= exp * 1000) {
    return false;
  }
  return true;
};
