import { TableCell, TableRow, useTheme } from '@mui/material';
import { IRound, tableRowsColor } from 'global';
import { nanoid } from '@reduxjs/toolkit';
import { useTranslation } from 'hooks/useTranslation';
import { tableCells } from './RoundsTable.const';

interface RowProps {
  round: IRound;
  index: number;
}

export const Row = ({ round, index }: RowProps): JSX.Element => {
  const theme = useTheme();
  const { d } = useTranslation();
  const cells = tableCells(round, d);

  return (
    <TableRow sx={tableRowsColor(index, theme)}>
      {cells.map(({ value, width }, cellIndex) => (
        <TableCell
          key={nanoid()}
          align={cellIndex === cells.length - 1 ? 'right' : 'inherit'}
          component="th"
          scope="row"
          sx={{ width }}
        >
          {value}
        </TableCell>
      ))}
    </TableRow>
  );
};
