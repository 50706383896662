import { ICargo } from 'global';
import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'hooks/useTranslation';
import { FormattedNumber } from 'react-intl';

interface CargoClientDataProps {
  cargo: ICargo;
}

export const CargoClientData = ({
  cargo: {
    createdAt,
    updatedAt,
    price: { value, currency },
    client: { name, orderId, dispo }
  }
}: CargoClientDataProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Stack>
      <Typography>
        {t('inputs.client')}: {name}
      </Typography>
      <Typography>
        {t('inputs.orderId')}: {orderId}
      </Typography>
      <Typography>
        {t('inputs.price')}:{'  '}
        <FormattedNumber
          value={Number(value)} // eslint-disable-next-line
          style="currency"
          currency={currency}
        />
      </Typography>
      <Typography>
        {t('roles.dispo')} : {dispo}
      </Typography>
    </Stack>
  );
};
