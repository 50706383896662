import { Stack } from '@mui/material';
import { Accessories, Details, DimensionsAndCapacity, Documents, MainInfo } from './partials';

interface AddCarFormProps {
  edit?: boolean;
}

export const AddCarForm = ({ edit = false }: AddCarFormProps): JSX.Element => (
  <Stack rowGap={2}>
    <MainInfo edit={edit} />
    <DimensionsAndCapacity />
    <Details />
    <Accessories />
    <Documents />
  </Stack>
);
