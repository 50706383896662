import { ICar, ICargo, convertCurrency } from 'global';

export const costOfCar = async (
  pickedCar: ICar | null,
  carCost: {
    custom: boolean;
    value: string;
    currency: string;
    customValue: string;
  },
  distance: { emptyKm: string; loadedKm: string },
  companyCurrency: string
) => {
  const sumDistance = Number(distance.emptyKm) + Number(distance.loadedKm) || 0;
  const pickedCarPricePerKm = pickedCar ? pickedCar.details.costPerKm.value : '';
  const pickedCarCurrencyPerKm = pickedCar ? pickedCar.details.costPerKm.currency : '';

  const carPrice = carCost.custom
    ? await convertCurrency(carCost.customValue, carCost.currency, companyCurrency)
    : await convertCurrency(
        String(sumDistance * Number(pickedCarPricePerKm)),
        pickedCarCurrencyPerKm,
        companyCurrency
      );

  return String(carPrice) || '0';
};

export const getSummedPrice = async (cargo: ICargo[], companyCurrency: string) => {
  const promises = cargo.map((el) =>
    convertCurrency(el.price.value, el.price.currency, companyCurrency)
  );

  let sum = 0;

  await Promise.all(promises).then((values) => {
    const total = values.reduce((partialSum, a) => Number(partialSum) + Number(a), 0) || 0;

    sum = total;
  });

  return String(sum);
};
