import { Button, Stack } from '@mui/material';
import { useTranslation } from 'hooks/useTranslation';
import { AddOrderForm } from './AddOrderForm';

export const AddOrder = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Stack>
      <Stack rowGap={3}>
        <AddOrderForm />
        <Button variant="contained" form="orderAddForm" type="submit">
          {t('buttons.save')}
        </Button>
      </Stack>
    </Stack>
  );
};
