import { Grid, Stack, Typography } from '@mui/material';
import { AppCard } from 'components';
import { useTranslation } from 'hooks/useTranslation';
import { useAppSelector } from 'store/hooks';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import resultService, { BoardStats } from 'services/result.service';

export const WelcomePanel = (): JSX.Element => {
  const [stats, setStats] = useState<BoardStats | null>(null);
  const { t, n } = useTranslation();
  const month = new Date().getMonth();
  const companyCurrency = useAppSelector((store) => store.company.company?.currency) || '';
  const companyId = useAppSelector((store) => store.company.company?.id) || '';
  const user = useAppSelector((store) => store.user.user);

  const getData = async () => {
    try {
      const res = await resultService.getBoardResults(companyId, user?.id || '');

      if (!res) return;

      setStats(res);
    } catch (error) {
      toast.error('error');
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Grid item xs={12}>
      <AppCard fullHeight>
        <Stack rowGap={2}>
          <Stack direction="row" spacing={2} alignItems="center">
            <EmojiPeopleIcon sx={{ fontSize: '40px' }} />
            <Typography component="span" variant="h6">
              {t('messages.hello')} {user?.details.name}
            </Typography>
          </Stack>
          {stats && (
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <AppCard>
                  <Stack rowGap={1}>
                    <Typography fontWeight="bold">{t('messages.userComission')}</Typography>
                    <Typography variant="caption">
                      {t(`months.${month}`)}{' '}
                      <Typography component="span" fontWeight="bold">
                        {n(stats.user.actual, companyCurrency)}
                      </Typography>
                    </Typography>

                    <Typography variant="caption">
                      {t(`months.${month - 1}`)}{' '}
                      <Typography component="span" fontWeight="bold">
                        {n(stats.user.last, companyCurrency)}
                      </Typography>
                    </Typography>
                  </Stack>
                </AppCard>
              </Grid>
              <Grid item xs={12} md={6}>
                <AppCard>
                  <Stack rowGap={1}>
                    <Typography fontWeight="bold">{t('messages.companyComission')}</Typography>
                    <Typography variant="caption">
                      {t(`months.${month}`)}{' '}
                      <Typography component="span" fontWeight="bold">
                        {n(stats.company.actual, companyCurrency)}
                      </Typography>
                    </Typography>

                    <Typography variant="caption">
                      {t(`months.${month - 1}`)}{' '}
                      <Typography component="span" fontWeight="bold">
                        {n(stats.company.last, companyCurrency)}
                      </Typography>
                    </Typography>
                  </Stack>
                </AppCard>
              </Grid>
            </Grid>
          )}
        </Stack>
      </AppCard>
    </Grid>
  );
};
