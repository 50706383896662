import { TextField, Grid, Typography } from '@mui/material';
import { Dayjs } from 'dayjs';
import { useFormikContext } from 'formik';
import { DatePicker } from 'components';
import { ICar } from 'global';
import { useTranslation } from 'hooks/useTranslation';

export const Documents = () => {
  const {
    values: {
      documents: { ocp, ocpTo, overviewAt, technicalTill }
    },
    handleChange,
    setFieldValue
  } = useFormikContext<ICar>();
  const { t } = useTranslation();

  return (
    <Grid container rowGap={2}>
      <Grid item xs={12}>
        <Typography component="span" variant="h5">
          {t('subheaders.documents')}
        </Typography>
      </Grid>
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            label={t('inputs.ocp')}
            name="documents.ocp"
            size="small"
            value={ocp}
            disabled
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <DatePicker
            value={ocpTo}
            label={t('inputs.ocpTo')}
            small
            handleChange={(newValue: Dayjs | null) => setFieldValue('documents.ocpTo', newValue)}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            label={t('inputs.overviewAt')}
            name="documents.overviewAt"
            size="small"
            type="number"
            value={overviewAt}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <DatePicker
            value={technicalTill}
            label={t('inputs.technicalTill')}
            small
            handleChange={(newValue: Dayjs | null) =>
              setFieldValue('documents.technicalTill', newValue)
            }
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
