import { AgreementTypes, DriverStatus, PaymentPeroid } from '../enum';
import { IDriver } from '../types';

export const initialDriverValues: IDriver = {
  id: '',
  driverId: '',
  company: '',
  createdAt: new Date(),
  updatedAt: new Date(),
  email: '',
  password: '',
  role: 'driver',
  addedBy: '',
  status: DriverStatus.active,
  adr: false,
  rounds: [],
  details: {
    name: '',
    phone: '',
    nationality: 'Poland',
    comments: ''
  },
  agreement: {
    agreementTill: null,
    paymentPeroid: 'daily' as PaymentPeroid,
    amount: '',
    currency: 'PLN',
    agreementType: 'UOP' as AgreementTypes
  }
};
