import axios from 'axios';
import { toast } from 'react-hot-toast';
import authHeader from './auth-header';
import { servicesEnv } from './const';

const API_URL = `${servicesEnv}v1/api/dictonaries`;

class DictonariesService {
  async getDictonaries(): Promise<any> {
    const res = await axios.get(API_URL, {
      headers: authHeader()
    });

    if (res.data.error) {
      toast.error(res.data.error);
      return {};
    }

    return res.data;
  }
}
export default new DictonariesService();
