import {
  Dialog,
  IconButton,
  Typography,
  DialogContent,
  DialogTitle,
  Stack,
  useTheme
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { maxContentWidth } from 'global';
import { FullDialogProps, Transition } from './FullDialog.types';

export const FullDialog = ({
  children,
  handleClose,
  open,
  title
}: FullDialogProps): JSX.Element => {
  const theme = useTheme();
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      scroll="paper"
    >
      <DialogTitle bgcolor={theme.palette.common.black}>
        <Stack direction="row" maxWidth={maxContentWidth} mx="auto" width="100%">
          <Typography flexGrow={1} color={theme.palette.common.white} variant="h6" component="span">
            {title}
          </Typography>
          <IconButton edge="end" sx={{ color: theme.palette.grey[200] }} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent dividers>
        <Stack maxWidth={maxContentWidth} mx="auto" width="100%">
          {children}
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
