import { useFormikContext } from 'formik';
import { ErrorMessage } from 'components';
import { IWorker, WorkerRole } from 'global';
import { useTranslation } from 'hooks/useTranslation';
import { Grid, TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { useEffect } from 'react';

interface AddEditElementProps {
  editData?: IWorker;
}

export const WorkerForm = ({ editData = undefined }: AddEditElementProps): JSX.Element => {
  const { t } = useTranslation();

  const {
    handleChange,
    setValues,
    values: { name, phone, email, role }
  } = useFormikContext<IWorker>();

  useEffect(() => {
    if (editData) {
      setValues(editData);
    }
  }, [editData]);

  return (
    <Grid container spacing={2} mb={2}>
      <Grid item xs={12} md={3}>
        <TextField
          fullWidth
          size="small"
          label={t('inputs.fullName')}
          name="name"
          value={name}
          required
          onChange={handleChange}
        />
        <ErrorMessage name="name" />
      </Grid>
      <Grid item xs={12} md={3}>
        <TextField
          fullWidth
          label={t('inputs.phone')}
          size="small"
          name="phone"
          type="number"
          required
          value={phone}
          onChange={handleChange}
        />
        <ErrorMessage name="phone" />
      </Grid>
      <Grid item xs={12} md={3}>
        <TextField
          fullWidth
          label={t('inputs.email')}
          size="small"
          type="email"
          required
          name="email"
          value={email}
          onChange={handleChange}
        />
        <ErrorMessage name="email" />
      </Grid>
      <Grid item xs={12} md={3}>
        <FormControl fullWidth size="small">
          <InputLabel>{t('inputs.role')}</InputLabel>
          <Select value={role} name="role" label={t('inputs.role')} onChange={handleChange}>
            {Object.keys(WorkerRole).map((el) => (
              <MenuItem key={el} value={el}>
                {t(`roles.${el}`)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};
