import { FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { CountrySelect } from 'components';
import { CarStatus } from 'global';
import { useTranslation } from 'hooks/useTranslation';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setCarsFilters } from 'store/reducers/filtersReducer/filtersReducer';

export const Filters = () => {
  const filters = useAppSelector((store) => store.filters.carsFilters);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handleChange = (event: SelectChangeEvent<CarStatus[]>) => {
    const {
      target: { value }
    } = event;

    if (typeof value !== 'string') {
      dispatch(setCarsFilters({ ...filters, status: value }));
    }
  };

  const setCountry = (country: string, code: string) =>
    dispatch(setCarsFilters({ ...filters, country }));

  const pickedCountry = filters.country;

  return (
    <Grid container rowGap={1}>
      <Grid item xs={12}>
        <FormControl fullWidth size="small">
          <InputLabel>{t('carStatus.title')}</InputLabel>
          <Select
            value={filters.status}
            multiple
            label={t('carStatus.title')}
            onChange={handleChange}
          >
            {Object.keys(CarStatus).map((el) => (
              <MenuItem key={el} value={el}>
                {t(`carStatus.${el}`)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <CountrySelect
          small
          label={t('inputs.country')}
          setCountry={setCountry}
          value={pickedCountry}
          filter
        />
      </Grid>
    </Grid>
  );
};
