import { ICar } from 'global';
import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'hooks/useTranslation';

interface CarDataProps {
  car: ICar;
}

export const CarData = ({
  car: {
    details: {
      type,
      body,
      capacity: { carWeight, cargoCapacity },
      costPerKm,
      averageFuelConsumption,
      comments,
      counterStatus
    }
  }
}: CarDataProps): JSX.Element => {
  const { t, n } = useTranslation();
  return (
    <Stack>
      <Typography>
        {t(`carType.${type}`)} ({t(`carBody.${body}`)})
      </Typography>
      <Typography>
        {t('inputs.carWeight')}: {carWeight} kg
      </Typography>
      <Typography>
        {t('inputs.cargoCapacity')}: {cargoCapacity} kg
      </Typography>
      <Typography>
        {t('inputs.costPerKm')}: {n(Number(costPerKm.value), costPerKm.currency)}
      </Typography>
      <Typography>
        {t('inputs.averageFuelConsumption')}: {averageFuelConsumption}
      </Typography>
      <Typography>
        {t('inputs.counterStatus')}: {counterStatus}
      </Typography>
      <Typography>
        {t('inputs.comments')}: {comments}
      </Typography>
    </Stack>
  );
};
