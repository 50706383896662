import { CargoStatus, LoadingUnloadingStatus } from 'global';
import { Translation } from 'hooks/useTranslation';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import CheckIcon from '@mui/icons-material/Check';
import MovingIcon from '@mui/icons-material/Moving';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import HourglassFullIcon from '@mui/icons-material/HourglassFull';
import { Theme, Stack, Card } from '@mui/material';

const iconStyles = { fontSize: '18px' };

export const statusIcons = (theme: Theme) => ({
  [LoadingUnloadingStatus.toDo]: (
    <HourglassFullIcon sx={{ ...iconStyles, color: theme.palette.grey[500] }} />
  ),
  [LoadingUnloadingStatus.loading]: (
    <ArrowCircleDownIcon sx={{ ...iconStyles, color: theme.palette.grey[500] }} />
  ),
  [LoadingUnloadingStatus.inProgress]: (
    <MovingIcon sx={{ ...iconStyles, color: theme.palette.grey[500] }} />
  ),
  [LoadingUnloadingStatus.delay]: (
    <TrendingDownIcon sx={{ ...iconStyles, color: theme.palette.error.main }} />
  ),
  [LoadingUnloadingStatus.unloading]: (
    <ArrowCircleUpIcon sx={{ ...iconStyles, color: theme.palette.grey[500] }} />
  ),
  [LoadingUnloadingStatus.done]: (
    <CheckIcon sx={{ ...iconStyles, color: theme.palette.success.main }} />
  )
});

export const getLegend = (t: Translation, theme: Theme) => (
  <Card variant="outlined">
    <Stack direction="row" spacing={2} my={1}>
      {Object.entries(statusIcons(theme)).map(([key, value]) => (
        <Stack direction="row" alignItems="center">
          {value}
          {t(`cargoDataStatus.${key}`)}
        </Stack>
      ))}
    </Stack>
  </Card>
);

export const getColorsAndLabels = (
  status: CargoStatus,
  t: Translation
): {
  color: 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
  label: string;
} => {
  switch (status) {
    case CargoStatus.assigned:
      return { color: 'info', label: t('cargoStatus.assigned') };
    case CargoStatus.canceled:
      return { color: 'error', label: t('cargoStatus.canceled') };
    case CargoStatus.done:
      return { color: 'success', label: t('cargoStatus.done') };
    case CargoStatus.quotation:
      return { color: 'secondary', label: t('cargoStatus.quotation') };
    case CargoStatus.toSell:
      return { color: 'warning', label: t('cargoStatus.toSell') };
    default:
      return { color: 'default', label: '' };
  }
};
