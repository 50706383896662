import { Grid, Stack, TextField, IconButton, Box, Typography, useTheme } from '@mui/material';
import { useFormikContext } from 'formik';
import { Dispo, ICarrier } from 'global';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { ErrorMessage } from 'components';

interface DispoElementProps {
  dispositor: Dispo;
}

export const DispoElement = ({ dispositor }: DispoElementProps) => {
  const {
    values: { dispo, cars },
    handleChange,
    setFieldValue
  } = useFormikContext<ICarrier>();
  const theme = useTheme();

  const index = dispo.findIndex((el) => el.id === dispositor.id);

  const deleteDispo = () =>
    setFieldValue(
      'dispo',
      dispo.filter((el) => el.id !== dispositor.id)
    );

  return (
    <Grid item xs={12}>
      <Stack width="100%" direction="row" alignItems="center">
        <Grid item xs={12} container spacing={2}>
          <Grid item xs={4}>
            <TextField
              fullWidth
              size="small"
              label="name"
              name={`dispo[${index}].name`}
              value={dispo[index].name}
              onChange={handleChange}
            />
            <ErrorMessage name={`dispo[${index}].name`} />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              size="small"
              label="phone"
              type="number"
              name={`dispo[${index}].phone`}
              value={dispo[index].phone}
              onChange={handleChange}
            />
            <ErrorMessage name={`dispo[${index}].phone`} />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="email"
              size="small"
              name={`dispo[${index}].email`}
              type="email"
              value={dispo[index].email}
              onChange={handleChange}
            />
            <ErrorMessage name={`dispo[${index}].email`} />
          </Grid>
        </Grid>
        <Box width="40px" height="40px">
          <IconButton onClick={deleteDispo}>
            <DeleteOutlineIcon />
          </IconButton>
        </Box>
      </Stack>
      <Grid item xs={12}>
        <Typography variant="caption">
          Przypisane auta :{' '}
          {cars
            .filter((el) => el.dispoId === dispo[index].name)
            .map((el) => el.plates)
            .join(', ')}
        </Typography>
      </Grid>
    </Grid>
  );
};
