import { CountryNameWithFlag } from 'components';
import { ICar } from 'global';
import { Stack, Typography, Theme } from '@mui/material';
import { DateTranslation, Translation } from 'hooks/useTranslation';
import { Link } from 'react-router-dom';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { EditMenu } from './CarInfo/EditMenu';
import { FleetCarInfoActions } from './CarInfo/CarInfo.helpers';
import { StatusChip } from './StatusChip/StatusChip';

export const tableHeaders = (t: Translation) => [
  '',
  t('inputs.plates'),
  t('inputs.type'),
  t('inputs.cargoCapacity'),
  '',
  t('inputs.position'),
  t('inputs.driver'),
  t('inputs.response'),
  ''
];

export const tableCells = (
  car: ICar,
  t: Translation,
  handleOpen: (car: ICar, type: FleetCarInfoActions | null) => void,
  d: DateTranslation,
  theme: Theme
) => {
  const cargoWeight =
    car.loads.length > 0
      ? car.loads.reduce((acc, obj) => {
          return acc + Number(obj.weight);
        }, 0)
      : 0;

  return [
    {
      value: (
        <Link to={`${car.id}`} rel="noopener noreferrer">
          <ManageSearchIcon />
        </Link>
      ),
      width: '1%'
    },
    {
      value: car.plates,
      width: '6%'
    },
    {
      value: (
        <Stack>
          <Typography variant="caption">
            {t(`carTypes.${car.details.type}`) + t(`carBody.${car.details.body}`)}
          </Typography>
          <Typography variant="caption">
            L{car.details.dimensions.length}/W{car.details.dimensions.width}/H
            {car.details.dimensions.height}
          </Typography>
        </Stack>
      ),
      width: '10%'
    },

    {
      value: (
        <Stack>
          <Typography>{car.details.capacity.cargoCapacity} kg</Typography>
          <Typography variant="caption">
            {t('inputs.left')} {Number(car.details.capacity.cargoCapacity) - Number(cargoWeight)} kg
          </Typography>
        </Stack>
      ),
      width: '10%'
    },
    {
      value: <StatusChip car={car} theme={theme} />,
      width: '10%'
    },

    {
      value: (
        <Stack>
          <CountryNameWithFlag data={car.position.country} />
          {car.position.postcode} {car.position.city}
        </Stack>
      ),
      width: '10%'
    },
    {
      value:
        car.rounds.length > 0 ? `${d(car.rounds[0].inRoadTo)} (${car.rounds[0].driver.name})` : '-',
      width: '5%'
    },
    {
      value: car.guardian.name,
      width: '10%'
    },
    {
      value: <EditMenu car={car} handleOpen={handleOpen} />,
      width: '10%'
    }
  ];
};
