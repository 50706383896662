import { Grid, TextField, Typography, InputAdornment } from '@mui/material';
import { useFormikContext } from 'formik';
import { ErrorMessage } from 'components';
import { ICar } from 'global';
import { useTranslation } from 'hooks/useTranslation';

export const DimensionsAndCapacity = (): JSX.Element => {
  const {
    values: {
      details: {
        capacity: { carWeight, cargoCapacity },
        dimensions: { height, width, length, palletsCapacity, ldm }
      }
    },
    errors,
    handleChange
  } = useFormikContext<ICar>();
  const { t } = useTranslation();

  return (
    <Grid container rowGap={2}>
      <Grid item xs={12}>
        <Typography component="span" variant="h5">
          {t('subheaders.dimensions')}
        </Typography>
      </Grid>
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={6} md={1}>
          <TextField
            fullWidth
            label={t('inputs.length')}
            name="details.dimensions.length"
            size="small"
            value={length}
            onChange={handleChange}
            error={!!errors.details?.dimensions?.length}
          />
          <ErrorMessage name="details.dimensions.length" />
        </Grid>
        <Grid item xs={6} md={1}>
          <TextField
            fullWidth
            label={t('inputs.width')}
            name="details.dimensions.width"
            value={width}
            size="small"
            onChange={handleChange}
            error={!!errors.details?.dimensions?.width}
          />
          <ErrorMessage name="details.dimensions.width" />
        </Grid>
        <Grid item xs={6} md={1}>
          <TextField
            fullWidth
            label={t('inputs.height')}
            name="details.dimensions.height"
            value={height}
            size="small"
            onChange={handleChange}
            error={!!errors.details?.dimensions?.height}
          />
          <ErrorMessage name="details.dimensions.height" />
        </Grid>
        <Grid item xs={6} md={2}>
          <TextField
            fullWidth
            label={t('inputs.palletsCapacity')}
            name="details.dimensions.palletsCapacity"
            size="small"
            value={palletsCapacity}
            onChange={handleChange}
            error={!!errors.details?.dimensions?.palletsCapacity}
          />
          <ErrorMessage name="details.dimensions.palletsCapacity" />
        </Grid>
        <Grid item xs={6} md={2.5}>
          <TextField
            fullWidth
            label={t('inputs.carWeight')}
            name="details.capacity.carWeight"
            size="small"
            error={!!errors.details?.capacity?.carWeight}
            value={carWeight}
            InputProps={{
              endAdornment: <InputAdornment position="end">kg</InputAdornment>
            }}
            onChange={handleChange}
          />
          <ErrorMessage name="details.capacity.carWeight" />
        </Grid>
        <Grid item xs={6} md={2.5}>
          <TextField
            fullWidth
            label={t('inputs.cargoCapacity')}
            size="small"
            error={!!errors.details?.capacity?.cargoCapacity}
            name="details.capacity.cargoCapacity"
            value={cargoCapacity}
            InputProps={{
              endAdornment: <InputAdornment position="end">kg</InputAdornment>
            }}
            onChange={handleChange}
          />
          <ErrorMessage name="details.capacity.cargoCapacity" />
        </Grid>
        <Grid item xs={6} md={2}>
          <TextField
            fullWidth
            label={t('inputs.ldm')}
            size="small"
            error={!!errors.details?.dimensions?.ldm}
            name="details.dimensions.ldm"
            value={ldm}
            onChange={handleChange}
          />
          <ErrorMessage name="details.dimensions.ldm" />
        </Grid>
      </Grid>
    </Grid>
  );
};
