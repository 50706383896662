import { Box, CssBaseline, useTheme } from '@mui/material';
import { useLocation, useNavigate, Outlet } from 'react-router';
import { useEffect, useState } from 'react';
import { isTokenValid } from 'global';
import { Login } from 'pages';
import { useAppSelector } from 'store/hooks';
import { Header } from './Header';
import { Drawer } from './Drawer';
import { DrawerHeader } from './Drawer/Drawer.styled';

export const Layout = (): JSX.Element => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const token = useAppSelector((store) => store.auth.data);
  const theme = useTheme();
  const tokenValid = isTokenValid(token);

  useEffect(() => {
    if (token) {
      const isDriver = token.user.role === 'driver';

      if (tokenValid) {
        if (isDriver) {
          navigate('/driver-page');
          return;
        }

        if (location.pathname === '/') {
          navigate('/board');
        }
      }
    }
  }, [tokenValid, location.pathname, navigate, token]);

  if (!tokenValid) {
    return <Login />;
  }

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box
      display="flex"
      minHeight="100vh"
      bgcolor={theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[500]}
      width="100%"
    >
      <CssBaseline />
      <Header open={open} handleDrawerOpen={handleDrawerOpen} />
      <Drawer open={open} handleDrawerClose={handleDrawerClose} />
      <Box component="main" sx={{ flexGrow: 1, p: 3, width: 'calc(100% - 64px)' }}>
        <DrawerHeader />
        <Outlet />
      </Box>
    </Box>
  );
};
