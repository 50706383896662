import { Button, Stack } from '@mui/material';
import { useTranslation } from 'hooks/useTranslation';
import { ICompanyUser } from 'global';
import { useFormikContext } from 'formik';
import { AddUserForm } from './AddUserForm';

interface AddUserProps {
  edit: boolean;
}

export const AddUser = ({ edit = false }: AddUserProps): JSX.Element => {
  const { t } = useTranslation();
  const { isSubmitting } = useFormikContext<ICompanyUser>();

  return (
    <Stack>
      <Stack rowGap={3}>
        <AddUserForm edit={edit} />
        <Button disabled={isSubmitting} variant="contained" form="userAddForm" type="submit">
          {t('buttons.save')}
        </Button>
      </Stack>
    </Stack>
  );
};
