import {
  IconButton,
  Tooltip,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  List,
  useTheme,
  Theme,
  Box
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { navItem } from 'global';
import { useLocation, useNavigate } from 'react-router';
import { useTranslation } from 'hooks/useTranslation';
import { useAppSelector } from 'store/hooks';
import { Loader } from 'components';
import * as S from './Drawer.styled';

interface DrawerProps {
  open: boolean;
  handleDrawerClose: () => void;
}

const getListElementsColors = (
  isPicked: boolean,
  theme: Theme
): {
  bgcolor: string;
} => {
  const activeStyles =
    theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.primary.main;

  const inactiveStyles = theme.palette.mode === 'dark' ? theme.palette.grey[500] : 'white';

  return {
    bgcolor: isPicked ? activeStyles : inactiveStyles
  };
};

export const Drawer = ({ open, handleDrawerClose }: DrawerProps): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();
  const handleNavigate = (path: string) => navigate(path);
  const theme = useTheme();
  const token = useAppSelector((store) => store.auth.data);
  const { t } = useTranslation();

  if (!token) return <Loader />;

  // TODO assign user role instead admin

  return (
    <S.Drawer variant="permanent" open={open}>
      <S.DrawerHeader>
        <IconButton onClick={handleDrawerClose}>
          <ChevronLeftIcon />
        </IconButton>
      </S.DrawerHeader>
      <Divider />

      <List sx={{ p: 0 }}>
        {navItem(t)
          .filter((el) => el.policies.includes(token.company.type))
          .map(({ label, path, icon, admin, driver, space }) => {
            const isAdmin = token.user.role === 'admin';
            const isDriver = token.user.role === 'driver';

            const hideListItem: boolean =
              (admin && !isAdmin) || (driver && !isDriver) || (!driver && isDriver);

            if (hideListItem) {
              return null;
            }

            return (
              <Box key={path}>
                <ListItem
                  sx={getListElementsColors(location.pathname.includes(path), theme)}
                  key={path}
                  disablePadding
                  onClick={() => handleNavigate(path)}
                >
                  <Tooltip title={label} placement="right">
                    <ListItemButton>
                      <ListItemIcon>{icon}</ListItemIcon>
                      <ListItemText primary={label} />
                    </ListItemButton>
                  </Tooltip>
                </ListItem>
                {space && <Divider sx={{ width: '100%' }} />}
              </Box>
            );
          })}
      </List>
    </S.Drawer>
  );
};
