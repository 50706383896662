import { FormControl, Grid, MenuItem, Select, InputLabel, TextField } from '@mui/material';
import { Dayjs } from 'dayjs';
import { useFormikContext } from 'formik';
import { DatePicker } from 'components';
import { IRound } from 'global';
import { useGetDrivers } from 'hooks/useGetDrivers';
import { useTranslation } from 'hooks/useTranslation';
import { useGetCars } from 'hooks/useGetCars';

interface MainInfoProps {
  edit: boolean;
}

export const MainInfo = ({ edit = false }: MainInfoProps) => {
  // const [pickedDriver, setPickedDriver] = useState<Driver | null>(null);
  // const [pickedCar, setPickedCar] = useState<ICar | null>(null);
  const { t } = useTranslation();
  const {
    setFieldValue,
    handleChange,
    values: { inRoadFrom, inRoadTo, driver, kmCountEnd, kmCountStart, car }
  } = useFormikContext<IRound>();

  const driversData = useGetDrivers({});
  const drivers = driversData.data || [];

  const carsData = useGetCars({});
  const cars = carsData.data || [];

  const handleChangeFrom = (e: Dayjs | null) => setFieldValue('inRoadFrom', e);
  const handleChangeTo = (e: Dayjs | null) => setFieldValue(`inRoadTo`, e);

  // const howManyDays = Math.floor((Date.parse(inRoadTo) - Date.parse(inRoadFrom)) / 86400000) + 1;

  // const costPerDay =
  //   pickedDriver && pickedDriver?.agreement.paymentPeroid === 'daily'
  //     ? Number(pickedDriver?.agreement.amount) * howManyDays + pickedDriver?.agreement.currency
  //     : pickedDriver && pickedDriver?.agreement.amount + pickedDriver?.agreement.currency + '/mc';

  return (
    <>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={5}>
          <FormControl fullWidth>
            <InputLabel>{t('inputs.driver')}</InputLabel>
            <Select
              required
              value={driver.id}
              label={t('inputs.driver')}
              onChange={(e) => {
                const pickedDriver = drivers?.find((el) => el.id === e.target.value);
                if (pickedDriver) {
                  // setPickedDriver(pickedDriver);
                  setFieldValue('driver', {
                    id: e.target.value,
                    name: pickedDriver.details.name
                  });
                }
              }}
            >
              {drivers?.map((el) => (
                <MenuItem key={el.id} value={el.id}>
                  {el.details.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={5}>
          <FormControl fullWidth>
            <InputLabel>{t('inputs.cars')}</InputLabel>
            <Select
              required
              value={car.id}
              label={t('inputs.cars')}
              onChange={(e) => {
                const pickedCar = cars?.find((el) => el.id === e.target.value);
                if (pickedCar) {
                  // setPickedCar(pickedCar);
                  setFieldValue('car', {
                    id: e.target.value,
                    plates: pickedCar.plates
                  });
                }
              }}
            >
              {cars?.map((el) => (
                <MenuItem key={el.id} value={el.id}>
                  {el.plates}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} md={3}>
          <DatePicker
            value={inRoadFrom}
            label={t('inputs.timeMin')}
            handleChange={handleChangeFrom}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <DatePicker value={inRoadTo} label={t('inputs.timeMax')} handleChange={handleChangeTo} />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            value={kmCountStart}
            name="kmCountStart"
            label={t('inputs.kmCountStart')}
            onChange={handleChange}
          />
        </Grid>
        {edit && (
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              value={kmCountEnd}
              name="kmCountEnd"
              label={t('inputs.kmCountEnd')}
              onChange={handleChange}
            />
          </Grid>
        )}
      </Grid>
      {/* <Grid item xs={12} my={2}>
        <AppCard>
          <>
            TODO - count cost of round by options - only car cost/ driver cost + car cost / custom
            <Typography>Dlugosc wyjazdu: {howManyDays} dni</Typography>
            <Typography>Koszt kierowcy : {costPerDay} </Typography>
          </>
        </AppCard>
      </Grid> */}
    </>
  );
};
