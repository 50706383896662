import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../../store';
import { initialState } from './filtersReducer.const';
import {
  CargoFilters,
  CargoSearch,
  CarriersFilters,
  CarriersSearch,
  CarsFilters,
  CarsSearch,
  ClientsFilters,
  ClientsSearch,
  DriversFilters,
  DriversSearch,
  RoundsFilters,
  RoundsSearch,
  ShipmentsFilters,
  ShipmentsSearch
} from './filtersReducer.types';

export const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setClientsSearch: (state, action: PayloadAction<ClientsSearch>) => {
      return { ...state, clientsSearch: action.payload };
    },
    setClientsFilters: (state, action: PayloadAction<ClientsFilters>) => {
      return { ...state, clientsFilters: action.payload };
    },
    setCarriersSearch: (state, action: PayloadAction<CarriersSearch>) => {
      return { ...state, carriersSearch: action.payload };
    },
    setCarriersFilters: (state, action: PayloadAction<CarriersFilters>) => {
      return { ...state, carriersFilters: action.payload };
    },
    setCarsSearch: (state, action: PayloadAction<CarsSearch>) => {
      return { ...state, carsSearch: action.payload };
    },
    setCarsFilters: (state, action: PayloadAction<CarsFilters>) => {
      return { ...state, carsFilters: action.payload };
    },
    setDriversSearch: (state, action: PayloadAction<DriversSearch>) => {
      return { ...state, driversSearch: action.payload };
    },
    setDriversFilters: (state, action: PayloadAction<DriversFilters>) => {
      return { ...state, driversFilters: action.payload };
    },
    setCargoFilters: (state, action: PayloadAction<CargoFilters>) => {
      return { ...state, cargoFilters: action.payload };
    },
    setCargoSearch: (state, action: PayloadAction<CargoSearch>) => {
      return { ...state, cargoSearch: action.payload };
    },
    setShipmentsFilters: (state, action: PayloadAction<ShipmentsFilters>) => {
      return { ...state, shipmentsFilters: action.payload };
    },
    setShipmentsSearch: (state, action: PayloadAction<ShipmentsSearch>) => {
      return { ...state, shipmentsSearch: action.payload };
    },
    setRoundsFilters: (state, action: PayloadAction<RoundsFilters>) => {
      return { ...state, roundsFilters: action.payload };
    },
    setRoundsSearch: (state, action: PayloadAction<RoundsSearch>) => {
      return { ...state, roundsSearch: action.payload };
    }
  }
});

export const {
  setClientsSearch,
  setClientsFilters,
  setCarriersSearch,
  setCarriersFilters,
  setCarsSearch,
  setCarsFilters,
  setDriversSearch,
  setDriversFilters,
  setCargoFilters,
  setCargoSearch,
  setShipmentsFilters,
  setShipmentsSearch,
  setRoundsFilters,
  setRoundsSearch
} = filtersSlice.actions;

export const selectCount = (state: RootState) => state.filters;

export default filtersSlice.reducer;
