import { Translation } from 'hooks/useTranslation';
import { IInvoice, InvoiceActions, InvoiceElementDetails } from '../types';

export const invoicesStatus = {
  paid: 'paid',
  delay: 'delay',
  wating: 'waiting',
  exposed: 'exposed',
  debtCollection: 'debtCollection'
};

export const units = {
  szt: 'szt',
  kg: 'kg',
  godz: 'godz',
  dni: 'dni',
  mc: 'mc',
  km: 'km',
  m2: 'm2'
};

export const vats = {
  23: '23',
  8: '8',
  7: '7',
  5: '5',
  0: '0'
  // zw: 'zw',
  // np: 'np',
  // dontShow: 'dontShow'
};

export const invoiceTypes = {
  note: 'note',
  invoice: 'invoice'
};

export const paymentMethods = {
  przelew: 'przelew',
  kartaPlatnicza: 'kartaPlatnicza',
  gotowka: 'gotowka',
  zaPobraniem: 'zaPobraniem',
  payPal: 'payPal',
  payU: 'payU',
  dotpay: 'dotpay',
  czek: 'czek',
  weksel: 'weksel',
  kompensata: 'kompensata'
};

export const deadlinesTime = {
  '1day': '1day',
  '3days': '3days',
  '5days': '5days',
  '7days': '7days',
  '14days': '14days',
  '21days': '21days',
  '30days': '30days',
  '45days': '45days',
  '60days': '60days',
  '75days': '75days',
  '90days': '90days',
  '1month': '1month',
  immediately: 'immediately',
  pickDate: 'pickDate'
};

export const languages = {
  polski: 'polski',
  english: 'english'
};

export const invoicesMenuOptions = (t: Translation) => [
  {
    label: t('menu.editData'),
    option: 'editData' as InvoiceActions
  }
];

export const initialInvoiceDetalis: InvoiceElementDetails = {
  id: '1',
  title: '',
  quantity: 1,
  discount: 0,
  unit: null,
  priceNetto: 0,
  vat: '7',
  valueNetto: '0',
  valueBrutto: '0'
};

export const initialInvoice: IInvoice = {
  id: '',
  company: '',
  createdAt: new Date(),
  updatedAt: new Date(),
  type: invoiceTypes.invoice,
  number: '',
  dateOfIssue: '',
  place: '',
  status: invoicesStatus.exposed,
  dateOfSell: '',
  client: null,
  user: null,
  currency: 'PLN',
  details: [initialInvoiceDetalis],
  paymentMethod: paymentMethods.przelew,
  deadline: deadlinesTime['7days'],
  language: languages.polski,
  comments: '',
  senderName: '',
  reciverName: ''
};
