import { Theme } from '@mui/material';

export const tableRowsColor = (
  index: number,
  theme: Theme
): {
  bgcolor: string;
} => {
  const evenRows =
    theme.palette.mode === 'dark' ? theme.palette.grey[500] : theme.palette.common.white;

  const oddRows =
    theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[700];

  return {
    bgcolor: index % 2 === 0 ? evenRows : oddRows
  };
};
