import { Typography, Stack, Grid } from '@mui/material';
import { StandardTable } from 'components';
import { Translation } from 'hooks/useTranslation';
import { CargoData, IShipment } from 'global';
import { tableHeaders } from './Board.const';
import { Row } from './components/_Row';
import { BoardDate } from './Board';

export type Options = 'loading' | 'unloading';

const tableOptions = (t: Translation) => [
  {
    type: 'loading' as Options,
    isToday: true,
    label: t('inputs.loadings')
  },
  {
    type: 'loading' as Options,
    isToday: false,
    label: t('inputs.loadings')
  },
  {
    type: 'unloading' as Options,
    isToday: true,
    label: t('inputs.unloadings')
  },
  {
    type: 'unloading' as Options,
    isToday: false,
    label: t('inputs.unloadings')
  }
];

export const getTables = (t: Translation, shipments: IShipment[], pickDate: BoardDate) =>
  tableOptions(t)
    .filter((el) => {
      if (pickDate === BoardDate.today) return el.isToday;
      if (pickDate === BoardDate.tomorrow) return !el.isToday;
      return null;
    })
    .map(({ label, type, isToday }) => {
      const options = getShipments(type, isToday, shipments);

      return (
        <Grid key={label} item xs={12} md={6}>
          <Stack>
            <Typography variant="h6" my={1}>
              {label}
            </Typography>
            <StandardTable
              headers={tableHeaders}
              items={options.map((el, index) => (
                <Row key={el.id} item={el} index={index} type={type} />
              ))}
              emptyState={t('info.noElements')}
            />
          </Stack>
        </Grid>
      );
    });

export const checkDateRange = ({ timeMax, timeMin }: CargoData, eventData: string): boolean => {
  const min = timeMin ? new Date(timeMin.toString()).toDateString() : '';
  const max = timeMax ? new Date(timeMax.toString()).toDateString() : '';

  return eventData === min || eventData === max || false;
};

export const getShipments = (event: Options, isToday: boolean, items: IShipment[]): IShipment[] => {
  const today = new Date();
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  const eventData = (isToday ? today : tomorrow).toDateString();

  const data = items
    .filter((item) => {
      const isAnyEventActive = item.eventsOrder[event].filter((el) =>
        checkDateRange(el, eventData)
      );

      return isAnyEventActive.length > 0;
    })
    .map((item) => {
      const activeCargoDataArray = item.eventsOrder[event].filter((el) =>
        checkDateRange(el, eventData)
      );
      const shipment: IShipment = {
        ...item,
        eventsOrder: { ...item.eventsOrder, [event]: activeCargoDataArray }
      };

      return shipment;
    });

  return data;
};
