import { TableCell, TableRow, useTheme } from '@mui/material';
import { nanoid } from '@reduxjs/toolkit';
import { ICargo, tableRowsColor } from 'global';
import { useTranslation } from 'hooks/useTranslation';
import { tableCells } from './CargoTable.const';

interface RowProps {
  cargo: ICargo;
  index: number;
}

export const Row = ({ cargo, index }: RowProps) => {
  const { t, d, n } = useTranslation();
  const theme = useTheme();
  const cells = tableCells(cargo, d, t, n, theme);

  return (
    <TableRow sx={tableRowsColor(index, theme)}>
      {cells.map(({ value, width }, cellIndex) => (
        <TableCell
          key={nanoid()}
          align={cellIndex === cells.length - 1 ? 'right' : 'inherit'}
          component="th"
          scope="row"
          sx={{ width }}
        >
          {value}
        </TableCell>
      ))}
    </TableRow>
  );
};
