import { Checkbox, TextField, Autocomplete } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { ICargo } from 'global';
import { useAppSelector } from 'store/hooks';
import { useGetCargo } from 'hooks/useGetCargo';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface Props {
  setCargos: (items: ICargo[]) => void;
}

export const MultipleAutocomplete = ({ setCargos }: Props) => {
  const { data } = useGetCargo({});

  //   .filter(
  //     (el) => el.status !== CargoStatus.toSell
  //   );
  return (
    <Autocomplete
      multiple
      options={data || []}
      disableCloseOnSelect
      onChange={(event: any, newValue: ICargo[]) => {
        setCargos(newValue);
      }}
      getOptionLabel={(cargo: ICargo) => `${cargo.id}`}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.id}
        </li>
      )}
      fullWidth
      renderInput={(params) => <TextField {...params} label="Cargos" placeholder="Pick cargo" />}
    />
  );
};
