import { Stack, TextField, InputAdornment, IconButton } from '@mui/material';
import { useFormik } from 'formik';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { CarsSearch } from 'store/reducers/filtersReducer/filtersReducer.types';
import { initialCarsSearchValues } from 'store/reducers/filtersReducer/filtersReducer.const';
import { KeyboardEvent, useEffect } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { setCarsSearch } from 'store/reducers/filtersReducer/filtersReducer';
import { useTranslation } from 'hooks/useTranslation';

export const Search = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const formik = useFormik<CarsSearch>({
    initialValues: initialCarsSearchValues,
    onSubmit: () => {}
  });

  const initState = useAppSelector((store) => store.filters.carsSearch);

  useEffect(() => {
    formik.setValues(initState);
    // eslint-disable-next-line
  }, [initState]);

  const handleSubmit = (values: CarsSearch) => dispatch(setCarsSearch(values));

  const handleKeyPress = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      handleSubmit(formik.values);
    }
  };

  const {
    values: { plates },
    handleChange
  } = formik;

  return (
    <Stack direction="row">
      {formik.values !== initialCarsSearchValues && (
        <IconButton
          onClick={() => {
            formik.setValues(initialCarsSearchValues);
            handleSubmit(initialCarsSearchValues);
          }}
        >
          <ClearIcon />
        </IconButton>
      )}
      <TextField
        sx={{ width: '200px' }}
        fullWidth
        label={t('inputs.plates')}
        size="small"
        name="plates"
        value={plates}
        onChange={handleChange}
        onKeyDown={(e) => handleKeyPress(e)}
        InputProps={{
          endAdornment: (
            <InputAdornment
              sx={{ cursor: 'pointer' }}
              position="end"
              onClick={() => handleSubmit(formik.values)}
            >
              <SearchIcon />
            </InputAdornment>
          )
        }}
      />
    </Stack>
  );
};
