import { ICompany } from '../types';
import { CompanyStatus, CompanyType, Payment, Tiers } from '../enum';

export const initialAdress = {
  adressLine1: '',
  adressLine2: '',
  postcode: '',
  city: '',
  country: ''
};

export const initialOrderSettings = {
  numberSystem: {
    prefix: 'ZL'
  },
  statute: '',
  comments: '',
  paymentComments: '',
  paymentTerm: {
    toChange: false,
    term: ''
  }
};

export const initialCompany: ICompany = {
  id: '',
  companyId: '',
  name: '',
  nip: '',
  createdAt: new Date(),
  updatedAt: new Date(),
  currency: 'PLN',
  logo: '',
  tier: Tiers.trial,
  type: CompanyType.transport,
  payment: Payment.year,
  comissions: [],
  paidTill: '',
  details: {
    adress: initialAdress,
    phone: '',
    fax: '',
    website: '',
    email: '',
    bankNumber: '',
    bankName: '',
    isCorespondenceAdressDiffrent: false,
    correspondenceAdress: initialAdress
  },
  status: 'active' as CompanyStatus,
  users: [],
  cars: [],
  orders: [],
  cargo: [],
  drivers: [],
  clients: [],
  carriers: [],
  invoices: [],
  shipments: [],
  rounds: [],
  orderSettings: initialOrderSettings
};
