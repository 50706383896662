import { Stack } from '@mui/material';
import { MainInfo } from './partials';

interface AddUserFormProps {
  edit: boolean;
}

export const AddUserForm = ({ edit = false }: AddUserFormProps): JSX.Element => (
  <Stack rowGap={3}>
    <MainInfo edit={edit} />
  </Stack>
);
