import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material';
import { useFormikContext } from 'formik';
import { ErrorMessage } from 'components';
import { CarBody, CarType, ICar } from 'global';
import { useGetUsers } from 'hooks/useGetUsers';
import { useTranslation } from 'hooks/useTranslation';

interface MainInfoProps {
  edit: boolean;
}

export const MainInfo = ({ edit = false }: MainInfoProps) => {
  const {
    values: {
      plates,
      guardian,
      details: { body, type }
    },
    handleChange,
    errors,
    setFieldValue
  } = useFormikContext<ICar>();
  const { t } = useTranslation();

  const { data } = useGetUsers({});
  const users = data || [];

  return (
    <Grid container rowGap={2}>
      <Grid item xs={12}>
        <Typography component="span" variant="h5">
          {t('subheaders.mainInfo')}
        </Typography>
      </Grid>
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={12} md={3}>
          <TextField
            fullWidth
            size="small"
            label={t('inputs.plates')}
            name="plates"
            disabled={edit}
            value={plates}
            onChange={handleChange}
            error={!!errors.plates}
          />
          <ErrorMessage name="plates" />
        </Grid>
        <Grid item xs={12} md={3}>
          <FormControl fullWidth size="small">
            <InputLabel>{t('inputs.carType')}</InputLabel>
            <Select
              value={type}
              name="details.type"
              label={t('inputs.carType')}
              onChange={handleChange}
            >
              {Object.keys(CarType).map((el) => (
                <MenuItem key={el} value={el}>
                  {el}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={3}>
          <FormControl fullWidth size="small">
            <InputLabel>{t('inputs.bodyType')}</InputLabel>
            <Select
              value={body}
              name="details.body"
              label={t('inputs.bodyType')}
              onChange={handleChange}
            >
              {Object.keys(CarBody).map((el) => (
                <MenuItem key={el} value={el}>
                  {el}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={3}>
          <FormControl fullWidth size="small">
            <InputLabel>{t('inputs.response')}</InputLabel>
            <Select
              value={guardian.id}
              label={t('inputs.response')}
              onChange={(e) => {
                const guardianUser = users.find((el) => el.id === e.target.value);
                if (guardianUser) {
                  setFieldValue('guardian', {
                    id: guardianUser.id,
                    name: guardianUser.details.name
                  });
                }
              }}
            >
              {Object.values(users).map((el) => (
                <MenuItem key={el.userId} value={el.id}>
                  {el.details.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </Grid>
  );
};
