import { FormControl, Grid, InputLabel, MenuItem, Select, Stack, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { AppCard } from 'components';
import { ClientStatus, IClient } from 'global';
import { useTranslation } from 'hooks/useTranslation';

export const Status = () => {
  const {
    values: { status },
    handleChange
  } = useFormikContext<IClient>();
  const { t } = useTranslation();

  return (
    <AppCard>
      <Stack rowGap={2}>
        <Typography variant="h6">{t('inputs.status')}</Typography>
        <Grid container spacing={2}>
          <Grid item xs={6} md={3}>
            <FormControl fullWidth size="small">
              <InputLabel>{t('inputs.status')}</InputLabel>
              <Select
                value={status}
                name="status"
                label={t('inputs.status')}
                onChange={handleChange}
              >
                {Object.values(ClientStatus).map((el) => (
                  <MenuItem key={el} value={el}>
                    {el}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Stack>
    </AppCard>
  );
};
