import { ICompanyUser } from 'global';
import { Translation } from 'hooks/useTranslation';
import EditIcon from '@mui/icons-material/Edit';
import { DialogTypes } from 'pages/Drivers/Drivers.types';
import { Theme } from '@mui/material';
import { getStatusChip } from './UsersTable.helpers';

export const tableHeaders = (t: Translation) => [
  t('inputs.fullName'),
  t('inputs.phone'),
  t('inputs.email'),
  t('inputs.role'),
  t('inputs.status'),
  ''
];

export const tableCells = (
  handleOpen: (dialogType: DialogTypes, data?: ICompanyUser) => void,
  user: ICompanyUser,
  t: Translation,
  theme: Theme
) => [
  {
    value: user.details.name,
    width: '20%'
  },
  {
    value: user.details.phone,
    width: '20%'
  },
  {
    value: user.details.email,
    width: '50%'
  },
  {
    value: user.role,
    width: '5%'
  },
  {
    value: getStatusChip(user.status, t),
    width: '8%'
  },
  {
    value: (
      <EditIcon
        onClick={() => handleOpen('EDIT', user)}
        sx={{ cursor: 'pointer', color: theme.palette.grey[400] }}
      />
    ),
    width: '2%'
  }
];
