import { Translation } from 'hooks/useTranslation';
import * as yup from 'yup';

export const validationSchema = (t: Translation) =>
  yup.object({
    price: yup.object({
      value: yup.number().required(t('errors.required'))
    }),
    guardian: yup.object({
      id: yup.string().required(t('errors.required'))
    }),
    client: yup.object({
      id: yup.string().required(t('errors.required')),
      orderId: yup.string().required(t('errors.required')),
      dispo: yup.string().required(t('errors.required'))
    }),
    distance: yup.number().required(t('errors.required')),
    events: yup.array().min(2, t('errors.required'))
  });

export const loadingUnloadingValidationSchema = (t: Translation) =>
  yup.object().shape({
    company: yup.string().required(t('errors.required')),
    position: yup.object({
      company: yup.string().required(t('errors.required')),
      country: yup.object({
        name: yup.string().required(t('errors.required'))
      }),
      postcode: yup.string().required(t('errors.required')),
      city: yup.string().required(t('errors.required')),
      adressLine1: yup.string().required(t('errors.required'))
    }),
    timeMin: yup.string().nullable().required(t('errors.required')),
    timeMax: yup.string().nullable().required(t('errors.required'))
  });
