import axios from 'axios';
import { toast } from 'react-hot-toast';
import { ComissionYear } from 'pages';
import authHeader from './auth-header';
import { servicesEnv } from './const';

export interface BoardStats {
  user: {
    actual: number;
    last: number;
  };
  company: {
    actual: number;
    last: number;
  };
}

const API_URL = `${servicesEnv}v1/api/results`;

class ResultsService {
  getCompanyComissions = async (company: string): Promise<ComissionYear> => {
    const res = await axios.get(`${API_URL}/company-comission/${company}`, {
      headers: authHeader()
    });

    if (res.data.error) {
      toast.error(res.data.error);
      return {};
    }

    return res.data;
  };
  getBoardResults = async (company: string, user: string): Promise<BoardStats | undefined> => {
    const res = await axios.get(
      `${API_URL}/board?${new URLSearchParams({
        company,
        user
      })}`,
      { headers: authHeader() }
    );

    if (res.data.error) {
      toast.error(res.data.error);
      return undefined;
    }

    return res.data;
  };
}
export default new ResultsService();
