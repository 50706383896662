import { RoundCostTypes } from '../enum';
import { Bills, IRound } from '../types';

export const initialBill: Bills = {
  type: RoundCostTypes.fuel,
  date: new Date(),
  amount: '',
  currency: 'PLN',
  quantity: '',
  id: ''
};

export const initialRound: IRound = {
  id: '',
  roundId: '',
  createdAt: new Date(),
  updatedAt: new Date(),
  addedBy: '',
  driver: {
    id: '',
    name: ''
  },
  car: { plates: '', id: '' },
  company: '',
  inRoadFrom: '',
  inRoadTo: '',
  shipments: [],
  kmCountStart: '',
  kmCountEnd: '',
  bills: [],
  costs: {
    leasing: '',
    fuel: '',
    driver: '',
    driverAdditional: '',
    additional: '',
    others: ''
  }
};
