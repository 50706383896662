import { Grid, Typography, Stack } from '@mui/material';
import { CountryNameWithFlag } from 'components/CountryNameWithFlag';
import { Position } from 'global';
import PlaceIcon from '@mui/icons-material/Place';

interface PositionInfoProps {
  data: Position;
}

export const PositionInfo = ({
  data: {
    coordinates: { lat, lng },
    company,
    country,
    postcode,
    city,
    adressLine1,
    adressLine2
  }
}: PositionInfoProps): JSX.Element => (
  <Grid item xs={12}>
    <Stack direction="row" spacing={2}>
      <PlaceIcon />
      <Stack>
        <CountryNameWithFlag data={country} />
        <Typography>
          {company} {adressLine1} {adressLine2}, {postcode} {city}
        </Typography>
        {lat !== 0 && lng !== 0 && (
          <Typography>
            {lat} / {lng}
          </Typography>
        )}
      </Stack>
    </Stack>
  </Grid>
);
