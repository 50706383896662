import {
  Collapse as MuiCollapse,
  Grid,
  Stack,
  Typography,
  TableRow,
  useTheme,
  TableCell
} from '@mui/material';
import { AppCard, StandardTable } from 'components';
import { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import { Link } from 'react-router-dom';

interface CollapseProps {
  label: string;
  positions: Position[];
  linkName: string;
}

interface Position {
  number: string;
  id: string;
}

export const Collapse = ({ label, positions, linkName }: CollapseProps): JSX.Element => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();

  return (
    <Grid item xs={12}>
      <AppCard>
        <>
          <Stack direction="row" alignItems="center" mb={1}>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
            <Typography>{label}</Typography>
          </Stack>
          <MuiCollapse in={open} timeout="auto" unmountOnExit>
            <StandardTable
              headers={(t) => ['number', 'link']}
              items={positions.map(({ number, id }, index) => (
                <TableRow
                  sx={{
                    '& > *': { borderBottom: 'unset' },
                    bgcolor: index % 2 === 0 ? 'white' : theme.palette.primary.light
                  }}
                >
                  <TableCell component="th" scope="row">
                    {number}
                  </TableCell>
                  <TableCell align="right">
                    <IconButton size="small" component={Link} to={`/app/${linkName}/${id}`}>
                      <ArrowCircleRightIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            />
          </MuiCollapse>
        </>
      </AppCard>
    </Grid>
  );
};
