import { Role, UserStatus } from 'global/enum';
import { ICompanyUser } from 'global/types';

export const companyUser: ICompanyUser = {
  userId: '',
  company: '',
  shipments: [],
  password: '',
  comissions: [],
  role: Role.user,
  status: UserStatus.active,
  details: {
    name: '',
    phone: '',
    email: ''
  },
  id: '',
  createdAt: new Date(),
  updatedAt: new Date()
};
