import dayjs, { Dayjs } from 'dayjs';

export const getFromToTimes = (min: Dayjs | null, max: Dayjs | null): string | undefined => {
  if (min && max) {
    const minTime = dayjs(min).format('DD/MM/YY');
    const maxTime = dayjs(max).format('DD/MM/YY');

    if (minTime === maxTime) {
      return `${dayjs(min).format('DD.MM HH:mm')} - ${dayjs(max).format('HH:mm')}`;
    }
    return `${dayjs(min).format('DD.MM HH:mm')} - ${dayjs(max).format('DD.MM HH:mm')}`;
  }
  return undefined;
};

export const createTodayDateWithoutTime = (): string => {
  const yourDate = new Date();
  const date = yourDate.toISOString().split('T')[0];
  return date;
};

export const dayjsToDate = (date: Dayjs | null): string =>
  date ? dayjs(date).format('DD.MM.YY HH:mm') : '';
