import { Stack, Chip } from '@mui/material';
import { useGetUsers } from 'hooks/useGetUsers';
import { useTranslation } from 'hooks/useTranslation';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setCargoFilters } from 'store/reducers/filtersReducer/filtersReducer';

export const Picked = () => {
  const filters = useAppSelector((store) => store.filters.cargoFilters);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { status, guardian } = filters;

  const { data } = useGetUsers({});
  const pickedGuardian = data?.find((el) => el.id === guardian)?.details?.name || '';

  const clearStatus = () => dispatch(setCargoFilters({ ...filters, status: '' }));
  const clearGuardian = () => dispatch(setCargoFilters({ ...filters, guardian: '' }));

  return (
    <Stack direction="row" spacing={1} mt={1} justifyContent="flex-end">
      {status && (
        <Chip
          size="small"
          label={t(`cargoStatus.${status}`)}
          variant="outlined"
          onDelete={clearStatus}
          color="primary"
        />
      )}
      {guardian && (
        <Chip
          size="small"
          label={pickedGuardian}
          variant="outlined"
          onDelete={clearGuardian}
          color="primary"
        />
      )}
    </Stack>
  );
};
