import { configureStore } from '@reduxjs/toolkit';
import auth from './reducers/authReducer/authReducer';
import filters from './reducers/filtersReducer/filtersReducer';
import view from './reducers/viewReducer/viewReducer';
import company from './reducers/companyReducer/companyReducer';
import user from './reducers/userReducer/userReducer';

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const store = configureStore({
  reducer: {
    auth,
    filters,
    view,
    company,
    user
  }
});
