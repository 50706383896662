import { useLocation, useNavigate } from 'react-router';
import { AppCard, DetailsInfo, FullDialog, Loader } from 'components';
import { Grid, Typography, Button, Stack } from '@mui/material';
import { useState, useEffect } from 'react';

import { ICarrier, initialCarrierValues } from 'global';
import carrierService from 'services/carrier-service';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'hooks/useTranslation';
import { Form, FormikProvider, useFormik } from 'formik';
import { carrierActions, CarrierActions } from './Carrier.helpers';
import { validationSchema } from '../Carriers/components/AddCarrier/AddCarrier.const';
import { Collapse } from './Collapse/Collapse';

export const Carrier = () => {
  const [edit, setEdit] = useState<CarrierActions | null>(null);
  const [carrier, setCarrier] = useState<ICarrier | null>(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  const getCarrierData = async () => {
    const carrierId = location.pathname.replace('/app/carriers/', '');

    const getCarrier = await carrierService
      .getCarrier(carrierId)
      .catch(() => toast.error('Problem przy pobieraniu danych klienta'));

    if (typeof getCarrier !== 'string') {
      setCarrier(getCarrier);
      formik.setValues(getCarrier);
    }
    setLoading(false);
  };

  useEffect(() => {
    getCarrierData();
  }, []);

  const hadleOpen = (action: CarrierActions) => setEdit(action);
  const handleClose = () => setEdit(null);
  const { t } = useTranslation();

  const formik = useFormik<ICarrier>({
    initialValues: carrier || initialCarrierValues,
    validationSchema: validationSchema(t),
    onSubmit: async (values) => {
      await carrierService.editCarrier(values);
      formik.resetForm();
      handleClose();
    }
  });

  if (loading) {
    return <Loader />;
  }

  if (!carrier) {
    return <Typography>Błędne id przewoźnika</Typography>;
  }

  const dialogProps = carrierActions.find((el) => el.type === edit);

  return (
    <AppCard>
      <Grid container rowGap={3}>
        <Stack direction="row" spacing={2}>
          <Button variant="outlined" onClick={() => navigate(-1)}>
            Back
          </Button>
          {carrierActions.map(({ type, title }) => (
            <Button key={title} onClick={() => hadleOpen(type)} variant="contained">
              {title}
            </Button>
          ))}
        </Stack>
        <Grid item xs={12}>
          <Typography variant="h4" mb={1}>
            {carrier.name}
          </Typography>
          <Typography>Nip: {carrier.nip}</Typography>
        </Grid>
        <Grid item xs={12}>
          <DetailsInfo details={carrier.details} />
        </Grid>
        <Grid item xs={12}>
          <Collapse
            label={`Orders (${carrier.orders.length})`}
            positions={carrier.orders.map(({ id, clientId }) => ({ id, number: clientId }))}
            linkName="orders"
          />
        </Grid>
        <Grid item xs={12}>
          <Collapse
            label={`Invoices (${carrier.invoices.length})`}
            positions={carrier.invoices.map(({ id, number }) => ({ id, number }))}
            linkName="invoices"
          />
        </Grid>
        {dialogProps && (
          <FullDialog handleClose={handleClose} open={Boolean(edit)} title={dialogProps.title}>
            <FormikProvider value={formik}>
              <Stack rowGap={3}>
                <Form onSubmit={formik.handleSubmit} id="carrierEditForm">
                  {dialogProps.chlidren}
                </Form>
                <Button form="carrierEditForm" type="submit" variant="contained">
                  {t('buttons.save')}
                </Button>
              </Stack>
            </FormikProvider>
          </FullDialog>
        )}
      </Grid>
    </AppCard>
  );
};
