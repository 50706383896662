import TimeToLeaveIcon from '@mui/icons-material/TimeToLeave';
import MapIcon from '@mui/icons-material/Map';
import EditRoadIcon from '@mui/icons-material/EditRoad';
import HomeIcon from '@mui/icons-material/Home';
import { Translation } from 'hooks/useTranslation';
import HandshakeIcon from '@mui/icons-material/Handshake';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ArticleIcon from '@mui/icons-material/Article';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import GroupIcon from '@mui/icons-material/Group';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import SettingsIcon from '@mui/icons-material/Settings';
import SportsMotorsportsIcon from '@mui/icons-material/SportsMotorsports';
import DepartureBoardIcon from '@mui/icons-material/DepartureBoard';
import { companyRoles } from 'App/permissions';
import DraftsIcon from '@mui/icons-material/Drafts';
import HistoryIcon from '@mui/icons-material/History';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';

interface NavElement {
  path: string;
  label: string;
  icon: JSX.Element;
  policies: string[];
  admin?: boolean;
  driver?: boolean;
  space?: boolean;
}

export const navItem = (t: Translation): NavElement[] => [
  {
    path: '/board',
    label: t('headers.board'),
    icon: <HomeIcon />,
    policies: [companyRoles.both, companyRoles.spedition, companyRoles.transport],
    space: true
  },
  {
    path: '/map',
    label: t('headers.map'),
    icon: <MapIcon />,
    policies: []
  },
  {
    path: '/clients',
    label: t('headers.clients'),
    icon: <HandshakeIcon />,
    policies: [companyRoles.both, companyRoles.spedition, companyRoles.transport]
  },
  {
    path: '/cargo',
    label: t('headers.cargo'),
    icon: <EditRoadIcon />,
    policies: [companyRoles.both, companyRoles.spedition, companyRoles.transport],
    space: true
  },
  {
    path: '/drivers',
    label: t('headers.drivers'),
    icon: <SportsMotorsportsIcon />,
    policies: [companyRoles.both, companyRoles.transport]
  },
  {
    path: '/rounds',
    label: t('headers.rounds'),
    icon: <HistoryIcon />,
    policies: [companyRoles.both, companyRoles.transport]
  },
  {
    path: '/fleet',
    label: t('headers.fleet'),
    icon: <TimeToLeaveIcon />,
    policies: [companyRoles.both, companyRoles.transport],
    space: true
  },
  {
    path: '/carriers',
    label: t('headers.carriers'),
    icon: <LocalShippingIcon />,
    policies: [companyRoles.both, companyRoles.spedition],
    space: true
  },
  {
    path: '/shipments',
    label: t('headers.shipments'),
    icon: <DepartureBoardIcon />,
    policies: [companyRoles.both, companyRoles.transport],
    space: true
  },
  {
    path: '/orders',
    label: t('headers.orders'),
    icon: <ArticleIcon />,
    policies: [companyRoles.both, companyRoles.spedition],
    space: true
  },
  {
    path: '/invoices',
    label: t('headers.invoices'),
    icon: <MonetizationOnOutlinedIcon />,
    policies: [],
    space: true
  },
  {
    path: '/admin',
    label: t('headers.adminPanel'),
    icon: <GroupIcon />,
    policies: [companyRoles.both, companyRoles.spedition, companyRoles.transport],
    admin: true,
    space: true
  },
  {
    path: '/stats',
    label: t('headers.stats'),
    icon: <AnalyticsIcon />,
    policies: [companyRoles.both, companyRoles.spedition, companyRoles.transport],
    space: true
  },
  {
    path: '/settings',
    label: t('headers.adminPanel'),
    icon: <SettingsIcon />,
    policies: [companyRoles.both, companyRoles.spedition, companyRoles.transport],
    space: true
  },
  {
    path: '/driver-page',
    label: t('headers.driverPage'),
    icon: <DraftsIcon />,
    policies: [companyRoles.both, companyRoles.spedition, companyRoles.transport],
    driver: true
  },
  {
    path: '/support',
    label: t('headers.support'),
    icon: <ContactSupportIcon />,
    policies: [companyRoles.both, companyRoles.spedition, companyRoles.transport]
  }
];
