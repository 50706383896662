import { StandardTable } from 'components';
import { IOrder } from 'global';
import { tableHeaders } from './OrdersTable.const';
import { Row } from './Row';

interface OrdersTableProps {
  orders: IOrder[];
}

export const OrdersTable = ({ orders }: OrdersTableProps) => {
  const items = orders.map((order, index) => <Row key={order.id} order={order} index={index} />);

  return <StandardTable headers={tableHeaders} items={items} />;
};
