import { Box, Divider, Grid, Stack, Typography } from '@mui/material';
import { AppCard } from 'components';
import { useAppSelector } from 'store/hooks';

export const Sender = () => {
  // const company = useAppSelector((store) => store.company.company);

  // const {
  //   name,
  //   details: {
  //     nip,
  //     bankName,
  //     bankNumber,
  //     adress: { adressLine1, adressLine2, postcode, city, country }
  //   }
  // } = company;

  return (
    <Grid item xs={12} md={6}>
      {/* <AppCard fullHeight>
        <Stack rowGap={1}>
          <Typography variant="h6" fontWeight="bold">
            Sprzedawca
          </Typography>
          <Divider />
          <Box>
            <Typography fontWeight="bold">{name}</Typography>
            <Typography>NIP: {nip}</Typography>
            <Typography>
              {bankNumber} {bankName}
            </Typography>
            <Typography>
              {adressLine1} {adressLine2}
            </Typography>
            <Typography>
              {postcode} {city} {country}
            </Typography>
          </Box>
        </Stack>
      </AppCard> */}
    </Grid>
  );
};
