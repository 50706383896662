import { Grid } from '@mui/material';
import { Details, MainInfo, PaymentDetails, Tiers } from './partials';

interface CompanySettingsFormProps {
  disabled: boolean;
}

export const CompanySettingsForm = ({ disabled }: CompanySettingsFormProps): JSX.Element => (
  <Grid container rowGap={1}>
    <MainInfo />
    <Details disabled={disabled} />
    <Tiers disabled={disabled} />
    <PaymentDetails disabled={disabled} />
  </Grid>
);
