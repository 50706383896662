import { Grid, Stack, Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { AppCard } from '../AppCard';

interface EmptyStateComponentProps {
  text: string;
  small?: boolean;
}

export const EmptyStateComponent = ({ text, small = false }: EmptyStateComponentProps) => (
  <Grid container>
    <Grid item xs={12}>
      <AppCard>
        <Stack direction="row" spacing={3} p={small ? 2 : 5}>
          <InfoOutlinedIcon />
          <Typography>{text}</Typography>
        </Stack>
      </AppCard>
    </Grid>
  </Grid>
);
