import { Stack, Chip } from '@mui/material';
import { useTranslation } from 'hooks/useTranslation';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setDriversFilters } from 'store/reducers/filtersReducer';

export const Picked = () => {
  const filters = useAppSelector((store) => store.filters.driversFilters);
  const search = useAppSelector((store) => store.filters.driversSearch);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { name } = search;
  const { status } = filters;

  const clearStatus = () => dispatch(setDriversFilters({ ...filters, status: '' }));

  return (
    <Stack direction="row" spacing={1} mt={1} justifyContent="flex-end">
      {name ? (
        <Chip
          size="small"
          label={`Name: ${name}`}
          variant="outlined"
          // onDelete={clearPlates}
          color="primary"
        />
      ) : null}
      {status ? (
        <Chip
          size="small"
          label={t(`driverStatus.${status}`)}
          variant="outlined"
          onDelete={clearStatus}
          color="primary"
        />
      ) : null}
    </Stack>
  );
};
