import * as yup from 'yup';

export const validationSchema = yup.object({
  newPassword: yup
    .string()
    .min(6, 'Password should be of minimum 6 characters length')
    .required('Password is required'),
  newPasswordAgain: yup
    .string()
    .min(6, 'Password should be of minimum 6 characters length')
    .required('Password is required')
});

export const editPassowrdInitialValues = {
  newPassword: '',
  newPasswordAgain: ''
};
