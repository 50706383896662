import { IComission, ICar, IShipment } from 'global';
import { Grid, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { useTranslation } from 'hooks/useTranslation';
import { useEffect } from 'react';
import { useAppSelector } from 'store/hooks';
import { nanoid } from '@reduxjs/toolkit';
import { costOfCar, getSummedPrice } from './_Summary.helpers';

interface SummaryProps {
  edit: boolean;
  pickedCar: ICar | null;
}

export const Summary = ({ edit = false, pickedCar }: SummaryProps) => {
  const {
    values: {
      cargo,
      price,
      carCost,
      distance,
      comission,
      carCost: { custom, value, currency, customValue },
      distance: { emptyKm, loadedKm }
    },
    setFieldValue
  } = useFormikContext<IShipment>();
  const { t, n } = useTranslation();

  const companyId = useAppSelector((store) => store.company.company?.id) || '';
  const userId = useAppSelector((store) => store.user.user?.id);
  const companyCurrency = useAppSelector((store) => store.company.company?.currency) || '';
  // const sumDistance = Number(emptyKm) + Number(loadedKm) || 0;
  const prices = cargo.map((el) => el.price.value + el.price.currency);

  // const costOfCar = async () => {
  //   const pickedCarPricePerKm = pickedCar ? pickedCar.details.costPerKm.value : '';
  //   const pickedCarCurrencyPerKm = pickedCar ? pickedCar.details.costPerKm.currency : '';

  //   const carPrice = custom
  //     ? await convertCurrency(customValue, currency, companyCurrency)
  //     : await convertCurrency(
  //         String(sumDistance * Number(pickedCarPricePerKm)),
  //         pickedCarCurrencyPerKm,
  //         companyCurrency
  //       );

  //   setFieldValue('carCost', {
  //     custom: custom,
  //     value: carPrice,
  //     currency: companyCurrency,
  //     customValue: customValue
  //   });
  // };

  // const getSummedPrice = async () => {
  //   const promises = cargo.map((el) =>
  //     convertCurrency(el.price.value, el.price.currency, companyCurrency)
  //   );

  //   Promise.all(promises).then((values) => {
  //     const sum = values.reduce((partialSum, a) => Number(partialSum) + Number(a), 0);

  //     setFieldValue('price', {
  //       value: sum,
  //       currency: companyCurrency,
  //       date: new Date()
  //     });
  //   });
  // };

  useEffect(() => {
    const setValues = async () => {
      const summedPrice = await getSummedPrice(cargo, companyCurrency);
      const carPrice = await costOfCar(pickedCar, carCost, distance, companyCurrency);

      const comissionValue = Number(summedPrice) - Number(carPrice);
      const newComission: IComission = edit
        ? {
            comissionId: comission?.id || '',
            id: comission?.id || '',
            createdAt: comission?.createdAt || new Date(),
            updatedAt: new Date(),
            company: companyId,
            user: userId || '',
            shipment: null,
            order: null,
            value: comissionValue,
            currency: companyCurrency,
            date: new Date()
          }
        : {
            comissionId: nanoid(),
            id: '',
            createdAt: new Date(),
            updatedAt: new Date(),
            company: companyId,
            user: userId || '',
            shipment: null,
            order: null,
            value: comissionValue,
            currency: companyCurrency,
            date: new Date()
          };

      setFieldValue('comission', newComission);
      setFieldValue('carCost', {
        custom,
        value: carPrice,
        currency: companyCurrency,
        customValue
      });
      setFieldValue('price', {
        value: summedPrice,
        currency: companyCurrency,
        date: new Date()
      });
    };

    setValues();
  }, []);

  return (
    <Grid item xs={12} container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5" component="span">
          {t('subheaders.summary')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>
          Ceny {prices.join()} = w sumie {n(Number(price.value), companyCurrency)}
        </Typography>
        <Typography>
          cena za km
          {n(
            Math.floor(Number(price.value) / (Number(emptyKm) + Number(loadedKm))),
            companyCurrency
          )}
          / km
        </Typography>
        <Typography> koszt auta {n(Number(carCost.value), companyCurrency)} </Typography>
        <Typography>
          marza {n(Number(price.value) - Number(carCost.value), companyCurrency)}{' '}
        </Typography>
      </Grid>
    </Grid>
  );
};
