import { Button, Stack } from '@mui/material';
import { useTranslation } from 'hooks/useTranslation';
import { useFormikContext } from 'formik';
import { IClient } from 'global';
import { AddClientForm } from './AddClientForm';

interface AddClientProps {
  edit?: boolean;
}

export const AddClient = ({ edit = false }: AddClientProps): JSX.Element => {
  const { t } = useTranslation();
  const { isSubmitting } = useFormikContext<IClient>();

  return (
    <Stack rowGap={3} mb={4}>
      <AddClientForm edit={edit} />
      <Button disabled={isSubmitting} form="clientAddForm" type="submit" variant="contained">
        {t('buttons.save')}
      </Button>
    </Stack>
  );
};
