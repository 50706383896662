import { TableCell, TableRow, useTheme } from '@mui/material';
import { nanoid } from '@reduxjs/toolkit';
import { useTranslation } from 'hooks/useTranslation';
import { IDriver, tableRowsColor } from 'global';
import { DialogTypes } from 'pages/Drivers/Drivers.types';
import { tableCells } from './DriversTable.const';

interface RowProps {
  driver: IDriver;
  index: number;
  handleOpen: (dialogType: DialogTypes, data?: IDriver) => void;
}

export const Row = ({ driver, index, handleOpen }: RowProps): JSX.Element => {
  const { t, d } = useTranslation();
  const theme = useTheme();
  const cells = tableCells(handleOpen, driver, t, d, theme);

  return (
    <TableRow sx={tableRowsColor(index, theme)}>
      {cells.map(({ value, width }, cellIndex) => (
        <TableCell
          key={nanoid()}
          component="th"
          scope="row"
          align={cellIndex === cells.length - 1 ? 'right' : 'inherit'}
          sx={{ width }}
        >
          {value}
        </TableCell>
      ))}
    </TableRow>
  );
};
