import { useFormikContext } from 'formik';
import { ICompany } from 'global';
import { useTranslation } from 'hooks/useTranslation';
import { Grid, TextField, Typography, FormControlLabel, Checkbox } from '@mui/material';
import { AppCard } from 'components';
import { Corespondence } from './_Corespondence';

interface DetailsProps {
  disabled: boolean;
}

export const Details = ({ disabled }: DetailsProps) => {
  const {
    values: {
      details: {
        adress: { adressLine1, adressLine2, postcode, city, country },
        phone,
        fax,
        website,
        email,
        isCorespondenceAdressDiffrent
      }
    },
    handleChange,
    setFieldValue
  } = useFormikContext<ICompany>();
  const { t } = useTranslation();

  const handleChangeCorespondence = (e: any) =>
    setFieldValue('details.isCorespondenceAdressDiffrent', e.target.checked);

  return (
    <Grid item xs={12}>
      <AppCard>
        <>
          <Typography mb={1} fontWeight="bold">
            Dane
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                size="small"
                label={t('inputs.adressLine1')}
                name="details.adress.adressLine1"
                value={adressLine1}
                onChange={handleChange}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                size="small"
                label={t('inputs.adressLine2')}
                name="details.adress.adressLine2"
                value={adressLine2}
                onChange={handleChange}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                size="small"
                label={t('inputs.postcode')}
                name="details.adress.postcode"
                value={postcode}
                onChange={handleChange}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                size="small"
                label={t('inputs.city')}
                name="details.adress.city"
                value={city}
                onChange={handleChange}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                size="small"
                label={t('inputs.country')}
                name="details.adress.country"
                value={country}
                onChange={handleChange}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                size="small"
                label={t('inputs.email')}
                name="details.email"
                value={email}
                onChange={handleChange}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                size="small"
                label={t('inputs.fax')}
                name="details.fax"
                value={fax}
                onChange={handleChange}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                size="small"
                label={t('inputs.website')}
                name="details.website"
                value={website}
                onChange={handleChange}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                size="small"
                label={t('inputs.phone')}
                name="details.phone"
                value={phone}
                onChange={handleChange}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={disabled}
                    name="details.isCorespondenceAdressDiffrent"
                    checked={isCorespondenceAdressDiffrent}
                    onChange={handleChangeCorespondence}
                  />
                }
                label={t('inputs.corespondenceQuestion')}
              />
            </Grid>
            {isCorespondenceAdressDiffrent && <Corespondence disabled={disabled} />}
          </Grid>
        </>
      </AppCard>
    </Grid>
  );
};
