import { useState } from 'react';
import { useFormik, FormikProvider, Form } from 'formik';
import { ICar, initialCar } from 'global';
import { useTranslation } from 'hooks/useTranslation';
import carService from 'services/car.service';
import { Button } from '@mui/material';
import { toast } from 'react-hot-toast';
import { useGetCars } from 'hooks/useGetCars';
import { useGetTokenData } from 'hooks/useGetTokenData';
import { FullDialog, StandardLayout } from 'components';
import { AddCar, FleetTable, Search } from './components';
import { validationSchema } from './components/AddCar/AddCar.const';
import { setMainValues } from './Fleet.helpers';

export const Fleet = (): JSX.Element => {
  const [openAdding, setOpenAdding] = useState(false);
  const token = useGetTokenData();

  const { t } = useTranslation();
  const { refetch } = useGetCars({});

  const formik = useFormik<ICar>({
    initialValues: initialCar,
    validationSchema: validationSchema(t),
    onSubmit: async (values) => {
      const newCar = setMainValues(values, token);

      const res = await carService.addCar(newCar).catch(() => {
        toast.error(t('errors.addCar'));
      });

      if (!res) return;

      toast.success(t('success.carAdded'));
      formik.resetForm();
      handleClose();
      refetch();
    }
  });

  const handleOpen = () => setOpenAdding(true);
  const handleClose = () => {
    formik.resetForm();
    setOpenAdding(false);
  };

  return (
    <StandardLayout
      search={<Search />}
      button={
        <Button variant="contained" onClick={handleOpen}>
          {t('buttons.addCar')}
        </Button>
      }
      content={
        <>
          {openAdding && (
            <FullDialog handleClose={handleClose} open={openAdding} title={t('buttons.addCar')}>
              <FormikProvider value={formik}>
                <Form onSubmit={formik.handleSubmit} id="carAddForm">
                  <AddCar />
                </Form>
              </FormikProvider>
            </FullDialog>
          )}
          <FleetTable />
        </>
      }
    />
  );
};
