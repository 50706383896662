import { Grid, Stack, TextField, IconButton, Box } from '@mui/material';
import { useFormikContext } from 'formik';
import { CarrierCar, ICarrier } from 'global';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { AppCard, Autocomplete, ErrorMessage } from 'components';
import { DimensionsAndCapacity } from './_DimensionsAndCapacity';

interface CarrierCarElementProps {
  car: CarrierCar;
}

export const CarrierCarElement = ({ car }: CarrierCarElementProps) => {
  const {
    values: { cars, dispo },
    handleChange,
    setFieldValue
  } = useFormikContext<ICarrier>();

  const index = cars.findIndex((el) => el.id === car.id);

  const deleteCarrierCar = () =>
    setFieldValue(
      'cars',
      cars.filter((el) => el.id !== car.id)
    );

  return (
    <AppCard>
      <Stack width="100%" direction="row" alignItems="center">
        <Grid item xs={12} container spacing={2}>
          <Grid item xs={4} md={3}>
            <TextField
              fullWidth
              size="small"
              label="driver"
              name={`cars[${index}].name`}
              value={cars[index].name}
              onChange={handleChange}
            />
            <ErrorMessage name={`cars[${index}].name`} />
          </Grid>
          <Grid item xs={4} md={3}>
            <TextField
              fullWidth
              label="phone"
              type="number"
              name={`cars[${index}].phone`}
              value={cars[index].phone}
              onChange={handleChange}
              size="small"
            />
            <ErrorMessage name={`cars[${index}].phone`} />
          </Grid>
          <Grid item xs={4} md={3}>
            <TextField
              fullWidth
              label="plates"
              name={`cars[${index}].plates`}
              value={cars[index].plates}
              onChange={handleChange}
              size="small"
            />
            <ErrorMessage name={`cars[${index}].plates`} />
          </Grid>
          <Grid item xs={4} md={3}>
            <Autocomplete
              small
              options={dispo.map((el) => el.name)}
              handleChange={(id: string | null) => setFieldValue(`cars[${index}].dispoId`, id)}
              label="Dyspozytor"
              value={cars[index].dispoId}
            />
          </Grid>
          <DimensionsAndCapacity index={index} />
        </Grid>
        <Box width="40px" height="40px">
          <IconButton onClick={deleteCarrierCar}>
            <DeleteOutlineIcon />
          </IconButton>
        </Box>
      </Stack>
    </AppCard>
  );
};
