import { TextField, Button, Stack, Typography, Box } from '@mui/material';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { ChangeEvent, useState } from 'react';
import authService from 'services/auth.service';
import { validateEmail } from 'global';
import { Message } from '../Login.model';

interface ResetPasswordProps {
  setLoginPage: () => void;
  setMessageData: (data: Message) => void;
}

export const ResetPassword = ({ setLoginPage, setMessageData }: ResetPasswordProps) => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');

  const isEmailValid = () => !validateEmail(email);

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
    setEmail(e.target.value);

  const handleSubmit = async () => {
    setLoading(true);
    const res = await authService.forgotPassword(email).catch(() => {
      setMessageData({
        status: 'error',
        text: 'Something went wrong'
      });
    });

    if (res) {
      setMessageData({
        status: 'success',
        text: 'Na podany email zostało wysłane nowe hasło. Prosze użyc go do zalogowania'
      });
      setLoginPage();
    }
    setLoading(false);
  };

  return (
    <Stack rowGap={3} py={10}>
      <Box textAlign="center">
        <LockOpenIcon style={{ fontSize: '4rem' }} />
      </Box>
      <Typography>
        Na podany przez Ciebie email (o ile istnije w bazie danych) wysłany zostanie email z nowym
        hasłem tymczasowym. Po ponownym zalogowaniu zalecamy zmiane hasła.
      </Typography>
      <TextField
        fullWidth
        label="Email"
        required
        inputProps={{ inputMode: 'email' }}
        type="email"
        value={email}
        onChange={handleChange}
      />
      <Button
        color="primary"
        variant="contained"
        fullWidth
        disabled={loading || isEmailValid()}
        onClick={handleSubmit}
      >
        Zmień hasło
      </Button>
      <Button variant="outlined" onClick={setLoginPage}>
        <KeyboardBackspaceIcon />
      </Button>
    </Stack>
  );
};
