import { Translation } from 'hooks/useTranslation';
import { MainInfo } from '../Cargo/components/AddCargo/AddCargoFrom/partials';

export type CargoActions = 'editCargo';

// add documents , edit cargodata,

export const cargoActions = (t: Translation) => [
  {
    type: 'editCargo' as CargoActions,
    chlidren: <MainInfo />,
    title: t('cargoActions.editCargo')
  }
];
