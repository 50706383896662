import { CarrierStatus } from '../enum/_carrier';
import { CarrierCar, Dispo, ICarrier } from '../types';
import { initialAdress } from './_company';

export const initialCarrierValues: ICarrier = {
  name: '',
  id: '',
  currency: 'PLN',
  company: '',
  status: CarrierStatus.new,
  dispo: [],
  ocp: '',
  createdAt: new Date(),
  updatedAt: new Date(),
  addedBy: '',
  nip: '',
  cars: [],
  ocpTill: null,
  ocpFrom: null,
  details: {
    adress: {
      adressLine1: '',
      adressLine2: '',
      postcode: '',
      city: '',
      country: ''
    },
    phone: '',
    fax: '',
    website: '',
    email: '',
    bankNumber: '',
    bankName: '',

    isCorespondenceAdressDiffrent: false,
    correspondenceAdress: initialAdress
  },
  invoices: [],
  orders: []
};

export const initialDispo: Dispo = {
  id: '',
  name: '',
  phone: '',
  email: ''
};

export const initialCarrierCar: CarrierCar = {
  id: '',
  name: '',
  dispoId: '',
  phone: '',
  plates: '',
  dimensions: {
    height: '',
    length: '',
    width: '',
    palletsCapacity: '',
    ldm: ''
  },
  capacity: {
    carWeight: '',
    cargoCapacity: ''
  }
};
