export enum DriverStatus {
  active = 'active',
  inactive = 'inactive'
}

export enum AgreementTypes {
  UOP = 'UOP',
  B2B = 'B2B',
  UZ = 'UZ'
}

export enum PaymentPeroid {
  monthly = 'monthly',
  daily = 'daily'
}
