import { Typography, Stack, Button, Divider, TableRow, useTheme, TableCell } from '@mui/material';
import { nanoid } from '@reduxjs/toolkit';
import { Form, FormikProvider, useFormik, useFormikContext } from 'formik';
import { IClient, IWorker, initialWorker } from 'global';
import { ErrorMessage, StandardTable } from 'components';
import { useTranslation } from 'hooks/useTranslation';
import { useGetTokenData } from 'hooks/useGetTokenData';
import { tableRowsColor } from 'global/utils/_colors';
import EditIcon from '@mui/icons-material/Edit';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import { useState } from 'react';
import clientService from 'services/client.service';
import { toast } from 'react-hot-toast';
import { ActionDialog } from './_ActionDialog';

interface WorkersProps {
  clientMode?: () => void;
}

export const Workers = ({ clientMode = undefined }: WorkersProps): JSX.Element => {
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState<IWorker | undefined>(undefined);
  const { t } = useTranslation();
  const token = useGetTokenData();
  const theme = useTheme();

  const workerFormik = useFormik<IWorker>({
    initialValues: editData || { ...initialWorker, id: nanoid() },
    // validationSchema: workerValidationSchema(t),
    onSubmit: () => {}
  });

  const handleClickOpen = (data?: IWorker) => {
    if (data) {
      setEditData(data);
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEditData(undefined);
  };

  const { values, setFieldValue } = useFormikContext<IClient>();
  const { workers } = values;

  const editClientCall = async (updatedClient: IClient) => {
    const res = await clientService.editClient(updatedClient).catch(() => {
      toast.error('Somethnig went wrong with editing the client');
    });
    if (!res) return;
    toast.success('Client edited');
    if (clientMode) clientMode();
  };

  const removeWorker = (id: string) => {
    const newArray = workers.filter((el) => el.id !== id);
    if (clientMode) {
      editClientCall({ ...values, workers: newArray });
    } else {
      setFieldValue('workers', newArray);
    }
  };

  const workersAfterAdd = (worker: IWorker) => [
    ...workers,
    { ...worker, id: nanoid(), addedBy: token.user.id }
  ];

  const workersAfterEdit = (worker: IWorker) => {
    const index = workers.findIndex((el) => el.id === worker.id);
    const newArray = [...workers];
    newArray.splice(index, 1, worker);
    return newArray;
  };

  const addWorker = (worker: IWorker) => setFieldValue('workers', workersAfterAdd(worker));

  const editWorker = (worker: IWorker) => {
    setFieldValue('workers', workersAfterEdit(worker));
  };

  const clientModeAdd = async (worker: IWorker) => {
    const updatedClient: IClient = { ...values, workers: workersAfterAdd(worker) };

    await editClientCall(updatedClient);
  };

  const clientModeEdit = async (worker: IWorker) => {
    const updatedClient: IClient = { ...values, workers: workersAfterEdit(worker) };

    await editClientCall(updatedClient);
  };

  const handleAddEditClient = (worker: IWorker) => {
    switch (true) {
      case !clientMode && !editData:
        addWorker(worker);
        break;
      case !clientMode && Boolean(editData):
        editWorker(worker);
        break;
      case clientMode && !editData:
        clientModeAdd(worker);
        break;
      case clientMode && Boolean(editData):
        clientModeEdit(worker);
        break;
      default:
        break;
    }
  };

  return (
    <Stack rowGap={2}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h6">{t('subheaders.workers')}</Typography>
        <Button variant="contained" onClick={() => handleClickOpen()}>
          {t('buttons.addWorker')}
        </Button>
      </Stack>
      {!clientMode && <Divider />}
      <StandardTable
        headers={() => ['name', 'phone', 'email', 'role', '', '']}
        items={workers.map((el, index) => {
          const { name, phone, email, role, id } = el;
          return (
            <TableRow sx={tableRowsColor(index, theme)}>
              <TableCell component="th" scope="row">
                {name}
              </TableCell>
              <TableCell component="th" scope="row">
                {phone}
              </TableCell>
              <TableCell component="th" scope="row">
                {email}
              </TableCell>
              <TableCell component="th" scope="row">
                {role}
              </TableCell>
              <TableCell align="right" component="th" scope="row">
                {index !== 0 && (
                  <PersonRemoveIcon sx={{ cursor: 'pointer' }} onClick={() => removeWorker(id)} />
                )}
              </TableCell>
              <TableCell align="right" component="th" scope="row">
                <EditIcon sx={{ cursor: 'pointer' }} onClick={() => handleClickOpen(el)} />
              </TableCell>
            </TableRow>
          );
        })}
      />
      {workers.length === 0 && <ErrorMessage name="workers" />}
      {open && (
        <FormikProvider value={workerFormik}>
          <Form onSubmit={workerFormik.handleSubmit} id="workerForm">
            <ActionDialog
              handleClose={handleClose}
              open={open}
              title={editData ? t('buttons.editWorker') : t('buttons.addWorker')}
              editData={editData}
              handleAddEditClient={handleAddEditClient}
            />
          </Form>
        </FormikProvider>
      )}
    </Stack>
  );
};
