import { Details } from 'global';
import { Stack, Typography, Grid } from '@mui/material';
import FaxIcon from '@mui/icons-material/Fax';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import LanguageIcon from '@mui/icons-material/Language';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import { AppCard } from 'components/AppCard';

interface DetailsInfoProps {
  details: Details;
}

export const DetailsInfo = ({
  details: {
    adress: { adressLine1, adressLine2, postcode, city, country },
    phone,
    fax,
    website,
    email,
    bankNumber,
    bankName,
    correspondenceAdress
  }
}: DetailsInfoProps) => (
  <Grid container rowGap={2} spacing={2}>
    <Grid item xs={12} md={correspondenceAdress ? 4 : 6}>
      <AppCard fullHeight>
        <>
          <Typography fontWeight="bold">Dane firmy</Typography>
          <Typography>
            {adressLine1} {adressLine2}
          </Typography>
          <Typography>
            {postcode} {city} {country}
          </Typography>
        </>
      </AppCard>
    </Grid>
    {correspondenceAdress && (
      <Grid item xs={12} md={4}>
        <AppCard fullHeight>
          <>
            <Typography fontWeight="bold">Dane do korespondencji</Typography>
            <Typography>
              {correspondenceAdress.adressLine1} {correspondenceAdress.adressLine2}
            </Typography>
            <Typography>
              {correspondenceAdress.postcode}
              {correspondenceAdress.city}
              {correspondenceAdress.country}
            </Typography>
          </>
        </AppCard>
      </Grid>
    )}
    <Grid item xs={12} md={correspondenceAdress ? 4 : 6}>
      <AppCard fullHeight>
        <>
          <Typography fontWeight="bold"> Kontakt</Typography>
          <Stack direction="row" spacing={1} alignItems="center">
            <LocalPhoneIcon sx={{ fontSize: '0.9rem' }} />
            <Typography>{phone}</Typography>
          </Stack>
          <Stack direction="row" spacing={1} alignItems="center">
            <FaxIcon sx={{ fontSize: '0.9rem' }} />
            <Typography>{fax}</Typography>
          </Stack>
          <Stack direction="row" spacing={1} alignItems="center">
            <AlternateEmailIcon sx={{ fontSize: '0.9rem' }} />
            <Typography>{email}</Typography>
          </Stack>
          <Stack direction="row" spacing={1} alignItems="center">
            <LanguageIcon sx={{ fontSize: '0.9rem' }} />
            <Typography>{website}</Typography>
          </Stack>
        </>
      </AppCard>
    </Grid>

    {/* <Grid item xs={12} md={4}>
      <Typography fontWeight="bold">Dane do płatności</Typography>
      <Typography>{bankNumber}</Typography>
      <Typography>{bankName}</Typography>
    </Grid> */}
  </Grid>
);
