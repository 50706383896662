import { StandardTable } from 'components';
import { ICarrier } from 'global';
import { tableHeaders } from './CarriersTable.const';
import { Row } from './Row';
import { Filter, Picked } from '../../Filters';

interface CarriersTableProps {
  carriers: ICarrier[];
}

export const CarriersTable = ({ carriers }: CarriersTableProps) => {
  const items = carriers.map((carrier, index) => (
    <Row key={carrier.id} carrier={carrier} index={index} />
  ));

  return (
    <StandardTable filters={<Filter />} picked={<Picked />} headers={tableHeaders} items={items} />
  );
};
