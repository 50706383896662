import { Stack, TextField, InputAdornment, IconButton } from '@mui/material';
import { useFormik } from 'formik';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { KeyboardEvent, useEffect } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import {
  DriversSearch,
  initialDriversSearchValues,
  setDriversSearch
} from 'store/reducers/filtersReducer';
import { useTranslation } from 'hooks/useTranslation';

export const Search = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const formik = useFormik<DriversSearch>({
    initialValues: initialDriversSearchValues,
    onSubmit: () => {}
  });

  const initState = useAppSelector((store) => store.filters.driversSearch);

  useEffect(() => {
    formik.setValues(initState);
    // eslint-disable-next-line
  }, []);

  const handleSubmit = (values: DriversSearch) => dispatch(setDriversSearch(values));

  const handleKeyPress = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      handleSubmit(formik.values);
    }
  };

  const {
    values: { name },
    handleChange
  } = formik;

  return (
    <Stack direction="row">
      {formik.values !== initialDriversSearchValues && (
        <IconButton
          onClick={() => {
            formik.setValues(initialDriversSearchValues);
            handleSubmit(initialDriversSearchValues);
          }}
        >
          <ClearIcon />
        </IconButton>
      )}

      <TextField
        sx={{ width: '200px' }}
        fullWidth
        label={t('inputs.fullName')}
        size="small"
        name="name"
        value={name}
        onChange={handleChange}
        onKeyDown={(e) => handleKeyPress(e)}
        InputProps={{
          endAdornment: (
            <InputAdornment
              sx={{ cursor: 'pointer' }}
              position="end"
              onClick={() => handleSubmit(formik.values)}
            >
              <SearchIcon />
            </InputAdornment>
          )
        }}
      />
    </Stack>
  );
};
