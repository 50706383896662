import { Button, Stack } from '@mui/material';
import { useTranslation } from 'hooks/useTranslation';
import { useFormikContext } from 'formik';
import { ICargo } from 'global';
import { AddCargoForm } from './AddCargoFrom';

export const AddCargo = (): JSX.Element => {
  const { t } = useTranslation();
  const { isSubmitting } = useFormikContext<ICargo>();

  return (
    <Stack>
      <Stack rowGap={3}>
        <AddCargoForm />
        <Button variant="contained" disabled={isSubmitting} type="submit" form="addCargoForm">
          {t('buttons.save')}
        </Button>
      </Stack>
    </Stack>
  );
};
