import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { Dayjs } from 'dayjs';
import { useFormikContext } from 'formik';
import { AppCard, DatePicker, ErrorMessage } from 'components';
import {
  Currencies,
  deadlinesTime,
  IInvoice,
  invoiceTypes,
  languages,
  paymentMethods
} from 'global';
import { useTranslation } from 'hooks/useTranslation';

interface MainInfoProps {
  edit?: boolean;
}

export const MainInfo = ({ edit = false }: MainInfoProps) => {
  const {
    values: {
      type,
      number,
      dateOfIssue,
      place,
      dateOfSell,
      currency,
      paymentMethod,
      deadline,
      senderName,
      reciverName,
      comments,
      language
    },
    handleChange,
    errors,
    setFieldValue
  } = useFormikContext<IInvoice>();
  const { t } = useTranslation();

  const setDateOfIssue = (newValue: Dayjs | null) => {
    setFieldValue('dateOfIssue', newValue);
  };

  const setDateOfSell = (newValue: Dayjs | null) => {
    setFieldValue('dateOfSell', newValue);
  };

  return (
    <Grid item xs={12}>
      <AppCard>
        <Stack rowGap={4}>
          <Typography variant="h6" fontWeight="bold">
            {t('details.mainInfo')}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6} md={3}>
              <FormControl fullWidth>
                <InputLabel>Typ</InputLabel>
                <Select value={type} name="type" label="Typ" onChange={handleChange}>
                  {Object.values(invoiceTypes).map((el) => (
                    <MenuItem key={el} value={el}>
                      {el}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} md={3}>
              <TextField
                fullWidth
                label="number"
                name="number"
                value={number}
                onChange={handleChange}
                required
                error={!!errors.number}
              />
              <ErrorMessage name="number" />
            </Grid>
            <Grid item xs={6} md={3}>
              <FormControl fullWidth>
                <InputLabel>Waluta</InputLabel>
                <Select value={currency} name="currency" label="Waluta" onChange={handleChange}>
                  {Object.values(Currencies).map((el) => (
                    <MenuItem key={el} value={el}>
                      {el}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} md={3}>
              <TextField
                fullWidth
                label="place"
                name="place"
                value={place}
                onChange={handleChange}
                required
                error={!!errors.place}
              />
              <ErrorMessage name="place" />
            </Grid>
            <Grid item xs={6} md={3}>
              <FormControl fullWidth>
                <InputLabel>Metoda płatności</InputLabel>
                <Select
                  value={paymentMethod}
                  name="paymentMethod"
                  label="Metoda płatności"
                  onChange={handleChange}
                >
                  {Object.values(paymentMethods).map((el) => (
                    <MenuItem key={el} value={el}>
                      {el}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} md={3}>
              <FormControl fullWidth>
                <InputLabel>Termin płatności</InputLabel>
                <Select
                  value={deadline}
                  name="deadline"
                  label="Termin płatności"
                  onChange={handleChange}
                >
                  {Object.values(deadlinesTime).map((el) => (
                    <MenuItem key={el} value={el}>
                      {el}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} md={3}>
              <DatePicker
                value={dateOfIssue}
                handleChange={setDateOfIssue}
                label="data wystawienia"
              />
              <ErrorMessage name="unloadingTimeMax" />
            </Grid>
            <Grid item xs={6} md={3}>
              <DatePicker value={dateOfSell} handleChange={setDateOfSell} label="data sprzedazy" />
              <ErrorMessage name="unloadingTimeMax" />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Dodadkowe uwagi"
                name="comments"
                multiline
                minRows={2}
                value={comments}
                onChange={handleChange}
                required
              />
              <ErrorMessage name="comments" />
            </Grid>
            <Grid item xs={3}>
              <FormControl fullWidth>
                <InputLabel>Język</InputLabel>
                <Select value={language} name="language" label="Język" onChange={handleChange}>
                  {Object.values(languages).map((el) => (
                    <MenuItem key={el} value={el}>
                      {el}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>{' '}
          </Grid>
        </Stack>
      </AppCard>
    </Grid>
  );
};
