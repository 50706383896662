import { Divider, Grid, Stack, TextField, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { AppCard, Autocomplete, ErrorMessage } from 'components';
import { IInvoice } from 'global';
import { useAppSelector } from 'store/hooks';

export const Reciver = (): JSX.Element => {
  const {
    values: { client },
    handleChange,
    setFieldValue
  } = useFormikContext<IInvoice>();

  // const handleChangeClient = (newValue: string | null) => {
  //   const client = clients.find((el) => el.name === newValue);
  //   if (client) {
  //     setFieldValue('client', client);
  //   } else {
  //     setFieldValue('client', null);
  //   }
  // };

  return (
    <Grid item xs={12} md={6}>
      <AppCard fullHeight>
        <Stack rowGap={1}>
          <Typography variant="h6" fontWeight="bold">
            Nabywca
          </Typography>
          <Divider />
          <Grid container spacing={2} mt={1}>
            {/* <Grid item xs={12}>
              <Autocomplete
                options={['', ...clients.map((el) => el.name)]}
                label={'Wybierz ...'}
                value={client?.name || ''}
                handleChange={handleChangeClient}
              />
            </Grid> */}
            {client && (
              <>
                <Grid item xs={6}>
                  {/* <TextField
                    size="small"
                    fullWidth
                    label="NIP"
                    name="client.details.nip"
                    value={client.details.nip}
                    onChange={handleChange}
                    required
                    //   error={!!errors.client.details.nip}
                  />
                  <ErrorMessage name="client.client.details.nip" /> */}
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    size="small"
                    fullWidth
                    label="adressLine1"
                    name="client.details.adress.adressLine1"
                    value={client.details.adress.adressLine1}
                    onChange={handleChange}
                    required
                    //   error={!!errors.details?.adress?.adress}
                  />
                  {/* <ErrorMessage name="client.details.adress.adress" /> */}
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    size="small"
                    fullWidth
                    label="adressLine2"
                    name="client.details.adress.adressLine2"
                    value={client.details.adress.adressLine2}
                    onChange={handleChange}
                    required
                    //   error={!!errors.details?.adress?.adressNumb}
                  />
                  {/* <ErrorMessage name="client.details.adress.adressNumb" /> */}
                </Grid>{' '}
                <Grid item xs={6}>
                  <TextField
                    size="small"
                    fullWidth
                    label="postcode"
                    name="client.details.adress.postcode"
                    value={client.details.adress.postcode}
                    onChange={handleChange}
                    required
                    //   error={!!errors.details?.adress?.postcode}
                  />
                  <ErrorMessage name="client.details.adress.postcode" />
                </Grid>{' '}
                <Grid item xs={6}>
                  <TextField
                    size="small"
                    fullWidth
                    label="city"
                    name="client.details.adress.city"
                    value={client.details.adress.city}
                    onChange={handleChange}
                    required
                    //   error={!!errors.details?.adress?.city}
                  />
                  <ErrorMessage name="client.details.adress.city" />
                </Grid>{' '}
                <Grid item xs={6}>
                  <TextField
                    size="small"
                    fullWidth
                    label="country"
                    name="client.details.adress.country"
                    value={client.details.adress.country}
                    onChange={handleChange}
                    required
                    //   error={!!errors.details?.adress?.adressNumb}
                  />
                  <ErrorMessage name="client.details.adress.country" />
                </Grid>
              </>
            )}
          </Grid>
        </Stack>
      </AppCard>
    </Grid>
  );
};
