import { useState, ChangeEvent } from 'react';
import { AlertMessage, EmptyStateComponent, Loader, StandardTable } from 'components';
import { useTranslation } from 'hooks/useTranslation';
import { useTheme, TablePagination } from '@mui/material';
import { useGetCargo } from 'hooks/useGetCargo';
import { tableHeaders } from './CargoTable.const';
import { getLegend } from './CargoTable.helper';
import { Row } from './Row';
import { Filters, Picked } from '../Filters';

export const CargoTable = (): JSX.Element => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const theme = useTheme();
  const { t } = useTranslation();

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const { data, isLoading, error, isFetching } = useGetCargo({});

  if (isLoading || isFetching) return <Loader />;
  if (error || !data)
    return <AlertMessage message="Problem przy pobieraniu danych" status="error" />;
  if (data.length === 0) {
    return <EmptyStateComponent text={t('info.noCargo')} />;
  }

  const rows = data.map((cargo, index) => <Row key={cargo.id} cargo={cargo} index={index} />);

  const rowsToShow = rows.filter(
    (_, index) => index < (page + 1) * rowsPerPage && index >= page * rowsPerPage
  );

  return (
    <>
      {getLegend(t, theme)}
      <StandardTable
        filters={<Filters />}
        picked={<Picked />}
        headers={tableHeaders}
        items={rowsToShow}
      />
      {rows.length > 0 && (
        <TablePagination
          sx={{
            bgcolor:
              theme.palette.mode === 'dark' ? theme.palette.grey[600] : theme.palette.grey[100]
          }}
          rowsPerPageOptions={[20, 50, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </>
  );
};
