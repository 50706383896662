import {
  Stack,
  TextField,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  InputAdornment,
  IconButton
} from '@mui/material';
import { useFormik } from 'formik';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { CarriersSearch } from 'store/reducers/filtersReducer/filtersReducer.types';
import {
  carriersSearchCategory,
  initialCarrierSearchValues
} from 'store/reducers/filtersReducer/filtersReducer.const';
import { KeyboardEvent, useEffect } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { setCarriersSearch } from 'store/reducers/filtersReducer/filtersReducer';

export const Search = () => {
  const dispatch = useAppDispatch();
  const formik = useFormik<CarriersSearch>({
    initialValues: initialCarrierSearchValues,
    onSubmit: () => {}
  });

  const handleSubmit = (values: CarriersSearch) => dispatch(setCarriersSearch(values));

  const handleKeyPress = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      handleSubmit(formik.values);
    }
  };

  const {
    values: { type, value },
    handleChange,
    setFieldValue
  } = formik;

  const initState = useAppSelector((store) => store.filters.carriersSearch);

  useEffect(() => {
    formik.setValues(initState);
  }, []);

  return (
    <Stack direction="row">
      {formik.values !== initialCarrierSearchValues && (
        <IconButton
          onClick={() => {
            formik.setValues(initialCarrierSearchValues);
            handleSubmit(initialCarrierSearchValues);
          }}
        >
          <ClearIcon />
        </IconButton>
      )}
      <FormControl sx={{ width: '150px' }} size="small">
        <InputLabel>Search by</InputLabel>
        <Select
          value={type}
          name="type"
          label="Search by"
          onChange={(e) => {
            handleChange(e);
            setFieldValue('value', '');
          }}
        >
          {Object.values(carriersSearchCategory).map((el) => (
            <MenuItem value={el}>{el}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        sx={{ width: '200px' }}
        fullWidth
        label="value"
        size="small"
        name="value"
        value={value}
        onChange={handleChange}
        onKeyDown={(e) => handleKeyPress(e)}
        InputProps={{
          endAdornment: (
            <InputAdornment
              sx={{ cursor: 'pointer' }}
              position="end"
              onClick={() => handleSubmit(formik.values)}
            >
              <SearchIcon />
            </InputAdornment>
          )
        }}
      />
    </Stack>
  );
};
