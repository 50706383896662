import {
  Collapse as MuiCollapse,
  Grid,
  Stack,
  Typography,
  TableRow,
  TableCell,
  useTheme
} from '@mui/material';
import { StandardTable } from 'components';
import { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Bills } from 'global';
import { Translation, useTranslation } from 'hooks/useTranslation';
import Card from '@mui/material/Card';
import { tableRowsColor } from 'global/utils/_colors';

interface CollapseProps {
  bills: Bills[];
}

export const tableHeaders = (t: Translation): string[] => ['id', 'typ', 'amount'];

export const BillsCollapse = ({ bills }: CollapseProps): JSX.Element => {
  const [open, setOpen] = useState(false);
  const { t, n, d } = useTranslation();
  const theme = useTheme();

  return (
    <Grid item xs={12}>
      <Card variant="outlined">
        <Stack direction="row" alignItems="center">
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
          <Typography>
            {t('headers.bills')}({bills.length})
          </Typography>
        </Stack>
        <MuiCollapse in={open} timeout="auto" unmountOnExit>
          <StandardTable
            headers={() => tableHeaders(t)}
            items={bills.map(({ id, date, type, currency, amount }, index) => (
              <TableRow key={id} sx={tableRowsColor(index, theme)}>
                <TableCell component="th" scope="row" width="25%">
                  {d(date)}
                </TableCell>
                <TableCell component="th" scope="row" width="25%">
                  {type}
                </TableCell>
                <TableCell component="th" scope="row" width="50%" align="right">
                  {n(Number(amount), currency)}
                </TableCell>
              </TableRow>
            ))}
          />
        </MuiCollapse>
      </Card>
    </Grid>
  );
};
