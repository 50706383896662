import { toast } from 'react-hot-toast';
import { UseQueryResult, useQuery } from 'react-query';
import usersService from 'services/users.service';
import { ICompanyUser, HookProps } from 'global';
import { useAppSelector } from 'store/hooks';
import { useTranslation } from './useTranslation';

export const useGetUsers = ({
  page = 1,
  rowsPerPage = 20
}: HookProps): UseQueryResult<ICompanyUser[], unknown> => {
  const { t } = useTranslation();

  const companyId = useAppSelector((store) => store.company.company?.id) || '';

  return useQuery(
    'users',
    () =>
      usersService.getUsers({
        name: '',
        status: '',
        company: companyId,
        sortBy: '',
        projectBy: '',
        limit: rowsPerPage,
        page
      }),
    {
      refetchOnWindowFocus: false,
      onSuccess() {},
      onError() {
        toast.error(t('errors.getData'));
      }
    }
  );
};
