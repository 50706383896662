import { ICargo } from 'global';
import { useTranslation } from 'hooks/useTranslation';
import { Stack, Typography } from '@mui/material';

interface CargoMainInfoDataProps {
  cargo: ICargo;
}

export const CargoMainInfoData = ({
  cargo: {
    distance,
    guardian: { name },
    status,
    weight
  }
}: CargoMainInfoDataProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Stack>
      <Typography>
        {t('inputs.guardian')}: {name}
      </Typography>
      <Typography>
        {t('inputs.status')}: {t(`cargoStatus.${status}`)}
      </Typography>
      <Typography>
        {t('inputs.distance')}: {distance} km
      </Typography>
      <Typography>
        {t('inputs.weight')}: {weight} kg
      </Typography>
    </Stack>
  );
};
