import { Stack, Chip } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setCarriersFilters } from 'store/reducers/filtersReducer/filtersReducer';

export const Picked = () => {
  const filters = useAppSelector((store) => store.filters.carriersFilters);
  const search = useAppSelector((store) => store.filters.carriersSearch);
  const dispatch = useAppDispatch();

  const { value, type } = search;
  const { status } = filters;

  const clearStatus = () => dispatch(setCarriersFilters({ ...filters, status: '' }));

  return (
    <Stack direction="row" spacing={1} mt={1}>
      {value ? (
        <Chip
          size="small"
          label={`By ${type}: ${value}`}
          variant="outlined"
          // onDelete={clearPlates}
          color="primary"
        />
      ) : null}
      {status ? (
        <Chip
          size="small"
          label={status}
          variant="outlined"
          onDelete={clearStatus}
          color="primary"
        />
      ) : null}
    </Stack>
  );
};
