import { Grid, InputLabel, FormControl, Select, MenuItem } from '@mui/material';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { initialCarrierFilterValues } from 'store/reducers/filtersReducer/filtersReducer.const';
import { CarriersFilters } from 'store/reducers/filtersReducer/filtersReducer.types';
import { setCarriersFilters } from 'store/reducers/filtersReducer/filtersReducer';
import { CarrierStatus } from 'global/enum/_carrier';

export const Filter = (): JSX.Element => {
  const [values, setValues] = useState<CarriersFilters>(initialCarrierFilterValues);

  const filters = useAppSelector((store) => store.filters.carriersFilters);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setValues(filters);
  }, [filters]);

  return (
    <Grid container>
      <Grid item xs={12} md={3}>
        <FormControl fullWidth size="small">
          <InputLabel>Status</InputLabel>
          <Select
            value={values.status}
            name="status"
            label="Status"
            onChange={(e) =>
              dispatch(setCarriersFilters({ ...values, status: e.target.value as CarrierStatus }))
            }
          >
            <MenuItem value="">All</MenuItem>
            {Object.values(CarrierStatus).map((el) => (
              <MenuItem key={el} value={el}>
                {el}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};
