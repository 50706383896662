import axios from 'axios';
import { IClient, IInitialClient } from 'global';
import { toast } from 'react-hot-toast';
import authHeader from './auth-header';
import { servicesEnv } from './const';

interface GetClientsProps {
  name: string;
  nip: string;
  status: string;
  company: string;
  sortBy: string;
  projectBy: string;
  limit: number;
  page: number;
}

const API_URL = `${servicesEnv}v1/api/clients`;
class ClientService {
  async getClients({
    name = '',
    nip = '',
    status = '',
    company = '',
    sortBy = '',
    projectBy = '',
    limit = 20,
    page = 1
  }: GetClientsProps): Promise<IClient[]> {
    const res = await axios.get(
      `${API_URL}?${new URLSearchParams({
        name,
        nip,
        status,
        company,
        sortBy,
        projectBy,
        limit: String(limit),
        page: String(page)
      })}`,
      {
        headers: authHeader()
      }
    );

    if (res.data.error) {
      toast.error(res.data.error);
      return [];
    }

    return res.data.results;
  }
  async getClient(id: string): Promise<IClient | undefined> {
    const res = await axios.get(`${API_URL}/${id}`, { headers: authHeader() });

    if (res.data.error) {
      toast.error(res.data.error);
      return undefined;
    }

    return res.data;
  }
  async addClient(client: IInitialClient): Promise<boolean> {
    const res = await axios.post(API_URL, client, { headers: authHeader() });

    if (res.data.error) {
      toast.error(res.data.error);
      return false;
    }

    return true;
  }
  async editClient(client: IClient): Promise<boolean> {
    const res = await axios.patch(`${API_URL}/${client.id}`, client, { headers: authHeader() });
    if (res.data.error) {
      toast.error(res.data.error);
      return false;
    }

    return res.data;
  }
}
export default new ClientService();
