import { CssBaseline, Stack } from '@mui/material';
import { QueryClient, QueryClientProvider } from 'react-query';
import { AppTranslation, IntlProviderWrapper } from './AppTranslation/AppTranslation';
import { AppToast } from './AppToast';
import { Router } from './Router/Router';
import { AppAuth } from './AppAuth/AppAuth';

const queryClient = new QueryClient();

// const stripePromise = loadStripe(
//   'pk_test_51AROWSJX9HHJ5bycpEUP9dK39tXufyuWogSUdeweyZEXy3LC7M8yc5d9NlQ96fRCVL0BlAu7Nqt4V7N5xZjJnrkp005fDiTMIr'
// );

export const App = (): JSX.Element => {
  return (
    <QueryClientProvider client={queryClient}>
      <IntlProviderWrapper>
        <AppTranslation>
          <AppAuth>
            <Stack minHeight="100vh">
              {/* <Elements stripe={stripePromise} options={options}>
              <CheckoutForm />
            </Elements> */}
              <CssBaseline />
              <Router />
              <AppToast />
            </Stack>
          </AppAuth>
        </AppTranslation>
      </IntlProviderWrapper>
    </QueryClientProvider>
  );
};
