import { Layout } from 'Layout/Layout';
import { Route, Routes } from 'react-router';
import { NoRoute } from 'pages';
import { PrivateRoute } from '../PrivateRoute/PrivateRoute';
import { appRoutes } from './Routes';

export const Router = (): JSX.Element => (
  <Routes>
    <Route path="/" element={<Layout />}>
      {appRoutes.map(({ path, component, policy }) => {
        return (
          <Route
            key={path}
            path={path}
            element={<PrivateRoute policy={policy}>{component}</PrivateRoute>}
          />
        );
      })}
    </Route>
    <Route path="*" element={<NoRoute />} />
  </Routes>
);
