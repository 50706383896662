import {
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  useTheme,
  IconButton,
  Box,
  Stack
} from '@mui/material';
import { useState } from 'react';
import { nanoid } from '@reduxjs/toolkit';
import { Translation, useTranslation } from 'hooks/useTranslation';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import FilterListIcon from '@mui/icons-material/FilterList';
import { EmptyStateComponent } from '../../EmptyStateComponent';
import { Drawer, Main } from './StandardTable.styled';

interface StandardTableProps {
  headers: (t: Translation) => string[];
  items: JSX.Element[];
  emptyState?: string;
  filters?: JSX.Element;
  picked?: JSX.Element;
}

export const StandardTable = ({
  headers,
  items,
  emptyState = '',
  filters = undefined,
  picked = undefined
}: StandardTableProps) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const theme = useTheme();

  const handleDrawerOpen = () => setOpen(true);

  const handleDrawerClose = () => setOpen(false);

  return (
    <Stack>
      {filters && (
        <Stack direction="row" justifyContent="space-between" alignItems="center" mb={1}>
          <Box>{picked}</Box>
          <IconButton onClick={open ? handleDrawerClose : handleDrawerOpen}>
            {open ? <ChevronRightIcon /> : <FilterListIcon />}
          </IconButton>
        </Stack>
      )}
      <Box position="relative">
        <Main open={open} theme={theme}>
          <TableContainer sx={{ border: items.length === 0 ? 0 : 1 }}>
            <Table size="small">
              <TableHead
                sx={{
                  bgcolor:
                    theme.palette.mode === 'dark'
                      ? theme.palette.grey[800]
                      : theme.palette.common.black
                }}
              >
                <TableRow>
                  {headers(t).map((el, index) => (
                    <TableCell
                      sx={{ color: 'white', fontFamily: theme.typography.button }}
                      key={nanoid()}
                      align={index === headers(t).length - 1 && index !== 0 ? 'right' : 'inherit'}
                    >
                      {el}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>{items}</TableBody>
            </Table>
            {items.length === 0 && <EmptyStateComponent small text={emptyState || 'No elements'} />}
          </TableContainer>
        </Main>
        <Drawer
          variant="persistent"
          anchor="right"
          open={open}
          theme={theme}
          PaperProps={{ style: { padding: '10px' } }}
        >
          {filters}
        </Drawer>
      </Box>
    </Stack>
  );
};
