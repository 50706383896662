import { IShipment } from 'global';
import { Grid, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { useTranslation } from 'hooks/useTranslation';
import { showEventsElements } from './_helpers';

export const EventsOrder = () => {
  const {
    values: { cargo, eventsOrder },
    setFieldValue
  } = useFormikContext<IShipment>();
  const { t } = useTranslation();

  const moveOrder = (index: number, up: boolean, isLoading: boolean) => {
    const array = { ...eventsOrder };
    array[isLoading ? 'loading' : 'unloading'].splice(
      up ? index - 1 : index + 1,
      0,
      array[isLoading ? 'loading' : 'unloading'].splice(index, 1)[0]
    );
    setFieldValue('eventsOrder', array);
  };

  return (
    <Grid item xs={12}>
      {cargo.length > 0 && (
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h6" component="span">
              {t('inputs.loadings')}
            </Typography>
            {showEventsElements(eventsOrder.loading, true, moveOrder)}
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" component="span">
              {t('inputs.unloadings')}
            </Typography>
            {showEventsElements(eventsOrder.unloading, false, moveOrder)}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
