import { Grid, Button, Breadcrumbs, Typography, Stack } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'hooks/useTranslation';
import { StandardView } from 'components';
import { buttons } from './AdminPanel.const';

export const AdminPanel = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <StandardView
      breadcrumbs={
        <Breadcrumbs>
          <Typography fontWeight="bold" color="text.primary">
            {t('headers.adminPanel')}
          </Typography>
          ,
        </Breadcrumbs>
      }
      content={
        <Grid container spacing={2}>
          {buttons(t).map(({ path, label }) => (
            <Grid key={path} item xs={12} md={6}>
              <Button sx={{ width: '100%' }} variant="contained" component={Link} to={path}>
                {label}
              </Button>
            </Grid>
          ))}
        </Grid>
      }
    />
  );
};
