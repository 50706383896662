import { Stack, Typography } from '@mui/material';
import { Country } from 'global';

interface CountryNameWithFlagProps {
  data: Country;
}

export const CountryNameWithFlag = ({ data: { code } }: CountryNameWithFlagProps): JSX.Element => (
  <Stack spacing={1} direction="row" alignItems="center">
    <img
      loading="lazy"
      width="20"
      src={`https://flagcdn.com/w20/${code.toLowerCase()}.png`}
      srcSet={`https://flagcdn.com/w40/${code.toLowerCase()}.png 2x`}
      alt=""
    />
    <Typography> {code}</Typography>
  </Stack>
);
