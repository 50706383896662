import { IDecodedToken } from 'global';
import { Avatar, IconButton, Menu, Box, MenuItem, Divider, ListItemIcon } from '@mui/material';
import { useState, MouseEvent } from 'react';
import Logout from '@mui/icons-material/Logout';
import { LanguageSwitch } from 'App/AppTranslation/LanguageSwitch';
import { useAppDispatch } from 'store/hooks';
import { logOut } from 'store/reducers/authReducer/authReducer';
import { useNavigate } from 'react-router';
import { useTranslation } from 'hooks/useTranslation';
import authService from 'services/auth.service';
import { CompanyData } from './CompanyData';
import { avatarProps, menuPaperProps } from './UserAvatar.const';
import { ModeSwitch } from './ModeSwitch';

interface UserAvatarProps {
  token: IDecodedToken;
}

export const UserAvatar = ({ token }: UserAvatarProps): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const { t } = useTranslation();

  const handleClick = (event: MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const logOutFunction = async (): Promise<void> => {
    const refreshToken = localStorage.getItem('cc-refresh');
    await authService.logout(refreshToken || '');
    dispatch(logOut());
    navigate('/');
  };

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <IconButton onClick={handleClick} size="small">
          <Avatar {...avatarProps(token.user.name)} />
        </IconButton>
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={menuPaperProps}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <CompanyData />
        <Divider />
        <MenuItem>
          <LanguageSwitch />
        </MenuItem>
        <ModeSwitch />
        <MenuItem onClick={logOutFunction}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          {t('buttons.logout')}
        </MenuItem>
      </Menu>
    </>
  );
};
