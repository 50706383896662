import { Stack } from '@mui/material';
import { Adress, Contact, Dispo, MainInfo } from './partials';
import { CarrierCar } from './partials/_CarrierCar';

export const AddCarrierForm = (): JSX.Element => (
  <Stack rowGap={2}>
    <MainInfo />
    <Contact />
    <Adress />
    <Dispo />
    <CarrierCar />
  </Stack>
);
