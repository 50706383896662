import { CargoEvent, CargoStatus, CargoTypes, LoadingUnloadingStatus, PalletsTypes } from '../enum';
import { CargoData, CargoDetails, ICargo, ValueAndCurrency } from '../types';

export const initialValueAndCurrency: ValueAndCurrency = {
  value: '',
  currency: 'PLN',
  date: new Date()
};

export const initialCargoDetails: CargoDetails = {
  id: '',
  adr: false,
  adrCode: '',
  ref: '',
  weight: '',
  quantity: '',
  palletsType: PalletsTypes.europ,
  cargoType: CargoTypes.neutral,
  comments: '',
  defaultSize: '',
  forChange: false,
  forChangeQuantity: ''
};

export const initialCargo: ICargo = {
  id: '',
  cargoId: 'x',
  addedBy: '',
  company: '',
  createdAt: new Date(),
  updatedAt: new Date(),
  documents: {
    order: '',
    cmr: '',
    other: []
  },
  client: {
    name: '',
    id: '',
    orderId: '',
    dispo: ''
  },
  description: '',
  distance: '',
  guardian: {
    id: '',
    name: ''
  },
  status: CargoStatus.quotation,
  weight: '',
  accessories: [],
  events: [],
  price: initialValueAndCurrency
};

export const initialCargoAction: CargoData = {
  id: '',
  cargoDataId: '',
  type: CargoEvent.loading,
  company: '',
  status: LoadingUnloadingStatus.toDo,
  position: {
    coordinates: {
      lat: 0,
      lng: 0
    },
    company: '',
    country: {
      name: '',
      code: ''
    },
    postcode: '',
    city: '',
    adressLine1: '',
    adressLine2: ''
  },
  timeMin: null,
  timeMax: null,
  details: [initialCargoDetails]
};
