import { Button, Breadcrumbs, Typography, Stack } from '@mui/material';
import { useState } from 'react';
import { FullDialog, StandardLayout } from 'components';
import { useFormik, FormikProvider, Form } from 'formik';
import { IComission, initialShipment, IShipment } from 'global';
import { useTranslation } from 'hooks/useTranslation';
import { toast } from 'react-hot-toast';
import shipmentService, { AddNewShipment } from 'services/shipment.service';
import { useGetShipments } from 'hooks/useGetShipments';
import { useGetTokenData } from 'hooks/useGetTokenData';
import comissionService from 'services/comission.service';
import { setMainValues } from './Shipments.helpers';
import { ShipmentsTable, AddShipmentForm } from './components';

export const Shipments = (): JSX.Element => {
  const [openAdding, setOpenAdding] = useState(false);
  const { refetch } = useGetShipments({});
  const token = useGetTokenData();

  const handleOpen = () => setOpenAdding(true);
  const handleClose = () => {
    setOpenAdding(false);
    formik.resetForm();
  };
  const { t } = useTranslation();

  const formik = useFormik<IShipment>({
    initialValues: initialShipment,
    //   validationSchema: validationSchema(t),
    onSubmit: async (values) => {
      const newShipment = setMainValues(values, token);

      const shipmentComission: IComission = { ...newShipment.comission };

      const shipmentData: AddNewShipment = {
        ...newShipment,
        cargo: newShipment.cargo.map((el) => el.id),
        eventsOrder: {
          loading: newShipment.eventsOrder.loading.map((el) => el.id),
          unlodaing: newShipment.eventsOrder.unloading.map((el) => el.id)
        }
      };
      delete shipmentData.comission;

      const res = await shipmentService.addShipment(shipmentData).catch(() => {
        toast.error(t('errors.addShipment'));
      });

      if (!res) return;

      const comission = await comissionService.addComission(res, {
        ...shipmentComission,
        shipment: res
      });

      if (!comission) {
        toast.error(t('errors.addComission'));
        return;
      }

      toast.success(t('success.shipmentAdded'));
      formik.resetForm();
      handleClose();
      refetch();
    }
  });

  return (
    <StandardLayout
      breadcrumbs={
        <Breadcrumbs>
          <Typography fontWeight="bold" color="text.primary">
            {t('headers.shipments')}
          </Typography>
          ,
        </Breadcrumbs>
      }
      //   search={<Search />}
      //   filters={<Filter />}
      //   picked={<Picked />}
      button={
        <Button variant="contained" onClick={handleOpen}>
          {t('buttons.addShipment')}
        </Button>
      }
      content={
        <>
          {openAdding && (
            <FullDialog
              handleClose={handleClose}
              open={openAdding}
              title={t('buttons.addShipment')}
            >
              <FormikProvider value={formik}>
                <Form onSubmit={formik.handleSubmit} id="shipmentAddForm">
                  <Stack rowGap={2}>
                    <AddShipmentForm />
                  </Stack>
                </Form>
              </FormikProvider>
            </FullDialog>
          )}
          <ShipmentsTable />
        </>
      }
    />
  );
};
