import { useEffect, useState } from 'react';
import { IDecodedToken, isTokenValid } from 'global';
import { Loader } from 'components';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import { logIn, logOut } from 'store/reducers/authReducer/authReducer';
import { setUser } from 'store/reducers/userReducer/userReducer';
import { setCompany } from 'store/reducers/companyReducer/companyReducer';
import { Grid, useTheme } from '@mui/material';
import jwtDecode from 'jwt-decode';
import usersService from 'services/users.service';
import companyService from 'services/company.service';
import authService from 'services/auth.service';

interface AppAuthProps {
  children: JSX.Element;
}

export const AppAuth = ({ children }: AppAuthProps): JSX.Element => {
  const [loading, setLoading] = useState(true);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const theme = useTheme();

  useEffect(() => {
    async function validation() {
      const loggedUser = localStorage.getItem('cc');

      if (loggedUser) {
        const token = JSON.parse(loggedUser);
        const decodedToken = jwtDecode<IDecodedToken>(token);
        if (isTokenValid(decodedToken)) {
          const user = await usersService.getUser(decodedToken.user.id);
          if (user) dispatch(setUser(user));
          const company = await companyService.getCompany(decodedToken.company.id);
          if (company) dispatch(setCompany(company));
          dispatch(logIn(decodedToken));
        } else {
          const res = await authService.refreshToken();
          if (!res) {
            dispatch(logOut());
            navigate('/');
          }
        }
      } else {
        dispatch(logOut());
        navigate('/');
      }

      setLoading(false);
    }
    validation();
  }, [dispatch, navigate]);

  if (loading) {
    return (
      <Grid
        container
        minHeight="100vh"
        justifyContent="center"
        alignItems="center"
        bgcolor={theme.palette.grey[300]}
      >
        <Loader />
      </Grid>
    );
  }

  return children;
};
