import TextField from '@mui/material/TextField';
import { Autocomplete as MuiAutocomplete } from '@mui/material';

interface AutocompleteProps {
  options: string[];
  handleChange: (id: string | null) => void;
  label: string;
  value: string | undefined;
  small?: boolean;
}

export const Autocomplete = ({
  options,
  handleChange,
  label,
  value,
  small = false
}: AutocompleteProps): JSX.Element => (
  <MuiAutocomplete
    value={value || ''}
    size={small ? 'small' : 'medium'}
    onChange={(event: any, newValue: string | null) => {
      handleChange(newValue);
    }}
    options={options}
    fullWidth
    renderInput={(params) => <TextField {...params} label={label} />}
  />
);
