import { Grid, InputLabel, FormControl, Select, MenuItem } from '@mui/material';
import { ClientStatus } from 'global';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setClientsFilters } from 'store/reducers/filtersReducer/filtersReducer';
import { useTranslation } from 'hooks/useTranslation';

export const Filter = (): JSX.Element => {
  const filters = useAppSelector((store) => store.filters.clientsFilters);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  return (
    <Grid container>
      <Grid item xs={12} md={3}>
        <FormControl fullWidth size="small">
          <InputLabel>{t('inputs.status')}</InputLabel>
          <Select
            value={filters.status}
            name="status"
            label={t('inputs.status')}
            onChange={(e) =>
              dispatch(setClientsFilters({ ...filters, status: e.target.value as ClientStatus }))
            }
          >
            <MenuItem value="">{t('inputs.all')}</MenuItem>
            {Object.values(ClientStatus).map((el) => (
              <MenuItem key={el} value={el}>
                {t(`clientStatus.${el}`)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};
