import {
  Grid,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  Switch,
  Stack,
  InputAdornment,
  Divider,
  MenuItem
} from '@mui/material';
import { Dayjs } from 'dayjs';
import { useFormikContext } from 'formik';
import { CountrySelect, DatePicker, ErrorMessage } from 'components';
import { AgreementTypes, Currencies, IDriver, DriverStatus, PaymentPeroid } from 'global';
import { useTranslation } from 'hooks/useTranslation';

interface DriverProps {
  edit: boolean;
}

export const Driver = ({ edit = false }: DriverProps) => {
  const {
    values: {
      details: { name, phone, nationality, comments },
      email,
      password,
      status,
      agreement: { agreementTill, currency, agreementType, amount, paymentPeroid },
      adr
    },
    handleChange,
    setFieldValue
  } = useFormikContext<IDriver>();
  const { t } = useTranslation();

  return (
    <Grid container rowGap={2}>
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={12}>
          <Typography component="span" variant="h5">
            {t('subheaders.mainInfo')}
          </Typography>
          <Divider sx={{ my: 1 }} />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            size="small"
            label={t('inputs.fullName')}
            name="details.name"
            value={name}
            onChange={handleChange}
          />
          <ErrorMessage name="details.name" />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            label={t('inputs.phone')}
            size="small"
            name="details.phone"
            value={phone}
            onChange={handleChange}
          />
          <ErrorMessage name="details.phone" />
        </Grid>
        <Grid item xs={12} md={4}>
          <CountrySelect
            label={t('inputs.nationality')}
            value={nationality}
            small
            setCountry={(country: string, code: string) =>
              setFieldValue('details.nationality', country)
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Typography component="span" variant="h5">
            {t('subheaders.accessData')}
          </Typography>
          <Divider sx={{ my: 1 }} />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            label={t('inputs.email')}
            type="email"
            size="small"
            name="email"
            value={email}
            onChange={handleChange}
          />
          <ErrorMessage name="email" />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            label={t('buttons.password')}
            size="small"
            name="password"
            value={password}
            onChange={handleChange}
          />
          <ErrorMessage name="password" />
        </Grid>
      </Grid>
      {edit && (
        <Grid item xs={12} container spacing={2}>
          <Grid item xs={12}>
            <Typography component="span" variant="h5">
              {t('subheaders.status')}
            </Typography>
            <Divider sx={{ my: 1 }} />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl fullWidth size="small">
              <InputLabel>{t('inputs.status')}</InputLabel>
              <Select
                value={status}
                name="status"
                label={t('inputs.status')}
                onChange={handleChange}
              >
                {Object.values(DriverStatus).map((el) => (
                  <MenuItem key={el} value={el}>
                    {el}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      )}

      <Grid item xs={12} container spacing={2}>
        <Grid item xs={12}>
          <Typography component="span" variant="h5">
            {t('subheaders.employment')}
          </Typography>
          <Divider sx={{ my: 1 }} />
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth size="small">
            <InputLabel>{t('inputs.agreementType')}</InputLabel>
            <Select
              value={agreementType}
              name="agreement.agreementType"
              label={t('inputs.agreementType')}
              onChange={handleChange}
            >
              {Object.values(AgreementTypes).map((el) => (
                <MenuItem key={el} value={el}>
                  {el}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <DatePicker
            small
            label={t('inputs.agreementTill')}
            value={agreementTill}
            handleChange={(newValue: Dayjs | null) =>
              setFieldValue('agreement.agreementTill', newValue)
            }
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth size="small">
            <InputLabel>{t('inputs.paymentPeroid')}</InputLabel>
            <Select
              value={paymentPeroid}
              name="agreement.paymentPeroid"
              label={t('inputs.paymentPeroid')}
              onChange={handleChange}
            >
              {Object.values(PaymentPeroid).map((el) => (
                <MenuItem key={el} value={el}>
                  {t(`peroid.${el}`)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            size="small"
            label={t('inputs.amount')}
            name="agreement.amount"
            value={amount}
            onChange={handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <FormControl fullWidth size="small">
                    <Select value={currency} name="agreement.currency" onChange={handleChange}>
                      {Object.values(Currencies).map((el) => (
                        <MenuItem key={el} value={el}>
                          {el}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </InputAdornment>
              )
            }}
          />
        </Grid>
      </Grid>

      <Grid item xs={12} container spacing={2}>
        <Grid item xs={12}>
          <Typography component="span" variant="h5">
            {t('subheaders.additional')}
          </Typography>
          <Divider sx={{ my: 1 }} />
        </Grid>
        <Grid item xs={12} md={4}>
          <Stack direction="row" alignItems="center">
            <Typography>ADR</Typography>
            <Switch checked={adr} onChange={handleChange} name="adr" />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            size="small"
            multiline
            minRows={2}
            label={t('inputs.comments')}
            name="details.comments"
            value={comments}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
