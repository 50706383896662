import { Form, FormikProvider, useFormik } from 'formik';
import { AlertMessage, StandardLayout } from 'components';
import { ICompany } from 'global';
import { useTranslation } from 'hooks/useTranslation';
import { useAppSelector } from 'store/hooks';
import { Typography, Breadcrumbs, Link as MuiLink } from '@mui/material';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import companyService from 'services/company.service';
import { Link } from 'react-router-dom';
import { OrderSettingsForm } from './OrderSettingsForm';

export const OrdersSettings = (): JSX.Element => {
  const [showMsg, setShowMsg] = useState(false);
  const [editMode, setEditMode] = useState(false);
  // const company = useAppSelector((store) => store.company.company);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  // const formik = useFormik<Company>({
  //   initialValues: company,
  //   // validationSchema: validationSchema(t),
  //   onSubmit: (values) => {
  //     handleSubmit(values);
  //   }
  // });

  const handleSubmit = async (values: ICompany) => {
    await companyService.editCompany({ ...values, updatedAt: new Date() });
    // dispatch(editCompany(values));
    setShowMsg(true);
    setEditMode(false);
  };

  const toggleEditMode = () => {
    if (!editMode) {
      setShowMsg(false);
    }
    setEditMode(!editMode);
  };

  return (
    <StandardLayout
      breadcrumbs={
        <Breadcrumbs>
          <MuiLink component={Link} underline="hover" color="inherit" to="/admin">
            {t('headers.adminPanel')}
          </MuiLink>
          <Typography color="text.primary">Order settings</Typography>,
        </Breadcrumbs>
      }
      content={
        <div />
        // <FormikProvider value={formik}>
        //   <Form onSubmit={formik.handleSubmit} id="userAddForm">
        //     <Stack rowGap={2}>
        //       <BackButton
        //         others={
        //           <Stack direction="row" spacing={2}>
        //             <Button variant="outlined" onClick={toggleEditMode}>
        //               {editMode ? 'Close' : 'Edit'}
        //             </Button>
        //             {editMode && (
        //               <Button variant="contained" form="userAddForm" type="submit">
        //                 {t('buttons.save')}
        //               </Button>
        //             )}
        //           </Stack>
        //         }
        //       />
        //       {showMsg && <AlertMessage message="Zmieniono dane" status="success" />}
        //       <OrderSettingsForm disabled={!editMode} />
        //     </Stack>
        //   </Form>
        // </FormikProvider>
      }
    />
  );
};
