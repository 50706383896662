import { TableCell, TableRow, useTheme } from '@mui/material';
import { nanoid } from '@reduxjs/toolkit';
import { ICarrier } from 'global';
import { useNavigate } from 'react-router';
import { tableCells } from './CarriersTable.const';

interface RowProps {
  carrier: ICarrier;
  index: number;
}

export const Row = ({ carrier, index }: RowProps): JSX.Element => {
  const navigate = useNavigate();
  const theme = useTheme();
  const cells = tableCells(carrier, navigate, theme);

  return (
    <TableRow
      sx={{
        bgcolor: index % 2 === 0 ? 'white' : theme.palette.primary.light
      }}
    >
      {cells.map(({ value, width }, cellIndex) => (
        <TableCell
          component="th"
          scope="row"
          key={nanoid()}
          align={cellIndex === cells.length - 1 ? 'right' : 'inherit'}
          sx={{ width }}
        >
          {value}
        </TableCell>
      ))}
    </TableRow>
  );
};
