import { Toolbar, IconButton, Stack, useTheme, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useAppSelector } from 'store/hooks';
import { Loader } from 'components';
import logo from 'images/logosmall.png';
import { UserAvatar } from './UserAvatar/UserAvatar';
import { AppBar } from './Header.styled';

interface HeaderProps {
  open: boolean;
  handleDrawerOpen: () => void;
}

export const Header = ({ open, handleDrawerOpen }: HeaderProps): JSX.Element => {
  const theme = useTheme();
  const token = useAppSelector((store) => store.auth.data);
  if (!token) return <Loader />;

  return (
    <AppBar position="fixed" open={open}>
      <Toolbar
        sx={{
          paddingLeft: '12px !important',
          bgcolor:
            theme.palette.mode === 'dark' ? theme.palette.common.black : theme.palette.common.white
        }}
      >
        <Stack direction="row" spacing={3}>
          <IconButton
            onClick={handleDrawerOpen}
            sx={{
              ...(open && { display: 'none' })
            }}
          >
            <MenuIcon />
          </IconButton>{' '}
          <Stack direction="row" spacing={1}>
            <Typography
              component="img"
              loading="lazy"
              height="40px"
              src={logo}
              alt=""
              color="black"
            />
            <Typography
              color={
                theme.palette.mode === 'dark'
                  ? theme.palette.common.white
                  : theme.palette.common.black
              }
              variant="h6"
              component="h1"
            >
              Cargo control
            </Typography>
          </Stack>
        </Stack>
        <Stack direction="row" flexGrow={1} alignItems="center" justifyContent="flex-end">
          <UserAvatar token={token} />
        </Stack>
      </Toolbar>
    </AppBar>
  );
};
