import { useAppSelector } from 'store/hooks';
import { permissions, Policy } from './permissions';

export const usePermission = (policy: Policy): boolean => {
  const token = useAppSelector((store) => store.auth.data);
  if (!token) return false;

  const roles = [token.company.type];

  return roles.reduce<boolean>((isAllowed, role) => {
    return isAllowed || permissions[role]?.includes(policy);
  }, false);
};
