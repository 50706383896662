import { Button, Breadcrumbs, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import driverService from 'services/driver.service';
import { toast } from 'react-hot-toast';
import { useFormik, FormikProvider, Form } from 'formik';
import { IDriver, initialDriverValues } from 'global';
import { useTranslation } from 'hooks/useTranslation';
import { useGetTokenData } from 'hooks/useGetTokenData';
import { useGetDrivers } from 'hooks/useGetDrivers';
import { FullDialog, StandardLayout } from 'components';
import { AddDriver, DriversTable, Filter, Picked, Search } from './components';
import { driverValidationSchema } from './components/AddDriver/AddDriver.const';
import { DialogProps, DialogTypes } from './Drivers.types';
import { setMainValues } from './Drivers.helpers';

export const Drivers = (): JSX.Element => {
  const [openDialog, setOpenDialog] = useState<DialogProps | null>(null);
  const token = useGetTokenData();

  const handleOpen = (dialogType: DialogTypes, data?: IDriver) => {
    setOpenDialog({ type: dialogType, values: data || initialDriverValues });
  };
  const handleClose = () => setOpenDialog(null);
  const { t } = useTranslation();
  const { refetch } = useGetDrivers({});

  const driverFormik = useFormik<IDriver>({
    initialValues: initialDriverValues,
    validationSchema: driverValidationSchema(t),
    onSubmit: async (values) => {
      if (openDialog?.type === 'ADD') {
        const driverData = setMainValues(values, token);
        const res = await driverService.addDriver(driverData).catch(() => {
          toast.error(t('errors.addDriver'));
        });
        if (!res) return;
      }
      if (openDialog?.type === 'EDIT') {
        const res = await driverService.editDriver(values).catch(() => {
          toast.error(t('errors.editDriver'));
        });
        if (!res) return;
      }

      driverFormik.resetForm();
      handleClose();
      refetch();
    }
  });

  useEffect(() => {
    if (openDialog) {
      driverFormik.setValues(openDialog.values);
    }
    // eslint-disable-next-line
  }, [openDialog]);

  return (
    <StandardLayout
      breadcrumbs={
        <Breadcrumbs>
          <Typography fontWeight="bold" color="text.primary">
            {t('headers.drivers')}
          </Typography>
          ,
        </Breadcrumbs>
      }
      search={<Search />}
      button={
        <Button variant="contained" onClick={() => handleOpen('ADD')}>
          {t('buttons.addDriver')}
        </Button>
      }
      content={
        <>
          {openDialog?.type && (
            <FullDialog
              handleClose={handleClose}
              open={Boolean(openDialog.type)}
              title={openDialog.type === 'ADD' ? t('buttons.addDriver') : 'edit driver'}
            >
              <FormikProvider value={driverFormik}>
                <Form onSubmit={driverFormik.handleSubmit} id="driverAddForm">
                  <AddDriver edit={openDialog.type === 'EDIT'} />
                </Form>
              </FormikProvider>
            </FullDialog>
          )}
          <DriversTable handleOpen={handleOpen} />
        </>
      }
    />
  );
};
