import { IconButton, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { MouseEvent, useState } from 'react';
import { ICar } from 'global';
import { useTranslation } from 'hooks/useTranslation';
import { FleetCarInfoActions, fleetCarInfoActions } from './CarInfo.helpers';

const ITEM_HEIGHT = 48;

interface EditMenuProps {
  car: ICar;
  handleOpen: (car: ICar, type: FleetCarInfoActions | null) => void;
}

export const EditMenu = ({ car, handleOpen }: EditMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { t } = useTranslation();

  const options = fleetCarInfoActions(t);

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch'
          }
        }}
      >
        {options.map(({ title, type }) => (
          <MenuItem
            key={title}
            onClick={() => {
              console.log(car, type);
              handleOpen(car, type);
              handleClose();
            }}
          >
            {title}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
