import { useLocation, useNavigate } from 'react-router';
import { AppCard } from 'components';
import { Button, Grid, Typography } from '@mui/material';
import { useAppSelector } from 'store/hooks';

export const Invoice = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const invoiceId = location.pathname.replace('/app/invoices/', '');
  // const invoice = useAppSelector((store) => store.invoices.invoices).find(
  //   (el) => el.id === invoiceId
  // );

  // if (!invoice) {
  //   return <Typography>Błędne id klienta</Typography>;
  // }

  return (
    <AppCard>
      <Grid container rowGap={2}>
        <Grid item xs={12}>
          <Button variant="outlined" onClick={() => navigate(-1)}>
            Back
          </Button>
        </Grid>
        {/* <Grid item xs={12}>
            <Typography variant="h5">{invoice.id}</Typography>
          </Grid> */}
        <Grid item xs={12} md={6}>
          details
        </Grid>
      </Grid>
    </AppCard>
  );
};
