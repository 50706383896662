import { Grid, Stack, Box, IconButton, Typography, Card } from '@mui/material';
import { CargoData } from 'global';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { LoadingUnloadingInfo } from 'components';

export const showEventsElements = (
  data: CargoData[],
  isLoading: boolean,
  moveOrder: (index: number, up: boolean, isLoading: boolean) => void
): JSX.Element => (
  <Grid container spacing={1}>
    {data.map((el, index) => (
      <Grid key={el.id} item xs={12} md={6}>
        <Card variant="outlined" sx={{ p: 1 }}>
          <Stack direction="row" alignItems="center">
            <Typography fontWeight="bold" mr={1}>
              {index + 1}.
            </Typography>
            <Box flexGrow={1}>
              <LoadingUnloadingInfo data={el} />
            </Box>
            <Box width="80px">
              {index !== 0 && (
                <IconButton
                  sx={{ width: '40px' }}
                  onClick={() => moveOrder(index, true, isLoading)}
                >
                  <ArrowUpwardIcon />
                </IconButton>
              )}
              {index < data.length - 1 && (
                <IconButton
                  sx={{ width: '40px' }}
                  onClick={() => moveOrder(index, false, isLoading)}
                >
                  <ArrowDownwardIcon />
                </IconButton>
              )}
            </Box>
          </Stack>
        </Card>
      </Grid>
    ))}
  </Grid>
);
