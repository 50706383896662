import { Stack, Box, Typography, Grid } from '@mui/material';
import { useTranslation } from 'hooks/useTranslation';

interface StandardViewProps {
  breadcrumbs?: JSX.Element;
  buttons?: JSX.Element;
  createdAt?: Date;
  updatedAt?: Date;
  content?: JSX.Element;
}

export const StandardView = ({
  breadcrumbs = undefined,
  buttons = undefined,
  createdAt = undefined,
  updatedAt = undefined,
  content = undefined
}: StandardViewProps): JSX.Element => {
  const { t, d } = useTranslation();

  return (
    <Stack rowGap={1}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        {breadcrumbs}
        <Box>
          {createdAt && (
            <Typography align="right" variant="caption" component="p">
              {t('inputs.addedAt')} {d(String(createdAt), true)}
            </Typography>
          )}
          {updatedAt && (
            <Typography align="right" variant="caption" component="p">
              {updatedAt !== createdAt && `${t('inputs.editedAt')}  ${d(updatedAt, true)}`}
            </Typography>
          )}
        </Box>
      </Stack>

      <Grid container spacing={1}>
        <Grid item xs={12}>
          {buttons}
        </Grid>
        {content}
      </Grid>
    </Stack>
  );
};
