import { Button, Grid, Breadcrumbs, Typography } from '@mui/material';
import { useState } from 'react';
import { FullDialog, InvoiceDoc, StandardLayout } from 'components';
import { useFormik, FormikProvider, Form } from 'formik';
import { IInvoice, initialInvoice } from 'global';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { nanoid } from '@reduxjs/toolkit';
import { useGetTokenData } from 'hooks/useGetTokenData';
import { useTranslation } from 'hooks/useTranslation';
import { AddInvoice } from './components/AddInvoice/AddInvoice';
import { InvoicesList } from './components/InvoicesList';
import { validationSchema } from './components/AddInvoice/AddInvoice.const';

export const Invoices = () => {
  const [openAdding, setOpenAdding] = useState(false);
  const handleOpen = () => setOpenAdding(true);
  const token = useGetTokenData();

  const { t } = useTranslation();

  const formik = useFormik<IInvoice>({
    initialValues: initialInvoice,
    validationSchema: validationSchema(t),
    onSubmit: (values) => {
      handleSubmit(values);
    }
  });

  const closeWithReset = () => {
    formik.resetForm();
    setOpenAdding(false);
  };

  const handleSubmit = async (values: IInvoice) => {
    const invoiceData = { ...values, id: nanoid(), company: token.company.id };
    formik.resetForm();
    closeWithReset();
  };

  return (
    <StandardLayout
      breadcrumbs={
        <Breadcrumbs>
          <Typography color="text.primary">{t('headers.invoices')}</Typography>,
        </Breadcrumbs>
      }
      button={
        <Button variant="contained" onClick={handleOpen} sx={{ color: 'white' }}>
          add invoice
        </Button>
      }
      content={
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit} id="invoiceAddForm">
            {openAdding && (
              <FullDialog handleClose={closeWithReset} open={openAdding} title="add invoice">
                <AddInvoice />
              </FullDialog>
            )}
            <InvoicesList />
          </Form>
        </FormikProvider>
      }
    />
  );
};
