import { IconButton, TableCell, TableRow, Button, useTheme } from '@mui/material';
import { useState } from 'react';
import { FullDialog, OrderDoc } from 'components';
import { useFormikContext } from 'formik';
import { useTranslation } from 'hooks/useTranslation';
import { IOrder } from 'global';
import { useNavigate } from 'react-router';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import orderService from 'services/order.service';
import cargoService from 'services/cargo.service';
import { nanoid } from '@reduxjs/toolkit';

interface RowProps {
  order: IOrder;
  index: number;
}

export const Row = ({ order, index }: RowProps): JSX.Element => {
  // const [action, setAction] = useState<OrderActions | null>(null);
  const navigate = useNavigate();
  const theme = useTheme();
  const { values, setValues, resetForm } = useFormikContext<IOrder>();

  // const changeActionStatus = (pickedAction: OrderActions) => {
  //   setValues(order);
  //   setAction(pickedAction);
  // };
  // const handleClose = () => setAction(null);

  const { t } = useTranslation();

  const { id, number } = order;

  const editButton = (
    <Button
      variant="contained"
      onClick={async () => {
        const data = { ...values, updatedAt: new Date() };

        await orderService.editOrder(data);
        // .then((res) => console.log(res))
        // .catch((err) => console.log(err));

        values.cargo.forEach(async (el) => {
          const editedCargo = {
            ...el
            // car: values.car
            // prices: el.prices
          };

          // await cargoService.editCargo(editedCargo);
          // .then((res) => console.log(res))
          // .catch((err) => console.log(err));
        });

        resetForm();
        // handleClose();
      }}
    >
      {t('buttons.save')}
    </Button>
  );

  const tableCells = [
    {
      value: (
        <IconButton onClick={() => navigate(`/app/orders/${id}`)}>
          <ManageSearchIcon />
        </IconButton>
      ),
      width: '5%'
    },
    {
      value: number,
      width: '10%'
    },
    // {
    //   value: (
    //     <IconButton>
    //       <PDFDownloadLink
    //         document={<OrderDoc order={order} company={company} />}
    //         fileName={`${order.number}.pdf`}
    //         style={{ color: 'black', textDecoration: 'none', fontSize: '0.8rem' }}
    //       >
    //         {({ blob, url, loading, error }) => (loading ? 'Ładuje dokument...' : <DownloadIcon />)}
    //       </PDFDownloadLink>
    //     </IconButton>
    //   ),
    //   width: '80%'
    // },
    {
      // value: <Menu options={ordersMenuOptions(t)} changeActionStatus={changeActionStatus} />,
      value: '',
      width: '40px',
      right: true
    }
  ];

  return (
    <>
      <TableRow
        sx={{
          '& > *': { borderBottom: 'unset' },
          bgcolor: index % 2 === 0 ? 'white' : theme.palette.primary.light
        }}
      >
        {tableCells.map(({ value, width, right }) => (
          <TableCell
            key={nanoid()}
            component="th"
            scope="row"
            align={right ? 'right' : 'inherit'}
            sx={{ width }}
          >
            {value}
          </TableCell>
        ))}
      </TableRow>
      {/* {action && <FullDialog {...createDialogProps(handleClose, action, editButton, t)} />} */}
    </>
  );
};
