import { useLocation } from 'react-router';
import { DetailsInfo, AppCard, FullDialog, Loader, AlertMessage, StandardView } from 'components';
import {
  Grid,
  Typography,
  Button,
  Stack,
  Breadcrumbs,
  Link as MuiLink,
  useTheme,
  ButtonGroup
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'hooks/useTranslation';
import { Form, FormikProvider, useFormik } from 'formik';
import { IClient, initialClient } from 'global';
import clientService from 'services/client.service';
import { toast } from 'react-hot-toast';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import { Collapse } from './components/Collapse';
import { validationSchema } from '../Clients/components/AddClient/AddClient.const';
import { Workers } from '../Clients/components/AddClient/AddClientForm/partials/Workers/_Workers';
import { getStatusIcon } from '../Clients/components/ClientsTable/ClientsTable.const';
import { clientActions, ClientActions } from './Client.helpers';

export const Client = (): JSX.Element => {
  const [edit, setEdit] = useState<ClientActions | null>(null);
  const location = useLocation();
  const clientId = location.pathname.replace('/clients/', '');
  const { t } = useTranslation();
  const theme = useTheme();
  const user = useAppSelector((store) => store.user.user);

  const { data, isLoading, error, isFetching, refetch } = useQuery(
    ['client', clientId],
    () => clientService.getClient(clientId),
    {
      refetchOnWindowFocus: false,
      onSuccess(res) {
        if (res) {
          formik.setValues(res);
        }
      },
      onError() {
        toast.error(t('errors.getData'));
      }
    }
  );

  const hadleOpen = (action: ClientActions) => setEdit(action);
  const handleClose = () => {
    setEdit(null);
  };

  const formik = useFormik<IClient>({
    initialValues: data || initialClient,
    validationSchema: validationSchema(t),
    onSubmit: async (values) => {
      const res = await clientService.editClient(values).catch(() => {
        toast.error(t('errors.editClient'));
      });

      if (!res) return;

      toast.success(t('success.clientEdited'));
      formik.resetForm();
      await refetch();
      handleClose();
    }
  });

  if (isLoading || isFetching) return <Loader />;
  if (error || !data) return <AlertMessage message={t('errors.getData')} status="error" />;
  const clientActionsItems = clientActions(t);
  const dialogProps = clientActionsItems.find((el) => el.type === edit);

  const { createdAt, updatedAt } = data;

  const isAdmin = user?.role === 'admin';

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit} id="clientEditForm">
        <StandardView
          breadcrumbs={
            <Breadcrumbs>
              <MuiLink
                fontWeight="bold"
                component={Link}
                underline="hover"
                color="inherit"
                to="/clients"
              >
                {t('headers.clients')}
              </MuiLink>
              <Typography color="text.primary">{data.name}</Typography>,
            </Breadcrumbs>
          }
          createdAt={createdAt}
          updatedAt={updatedAt}
          buttons={
            <ButtonGroup variant="contained">
              {clientActionsItems.map(({ type, title, adminOnly }) => {
                if (adminOnly && !isAdmin) {
                  return <div />;
                }
                return (
                  <Button key={title} onClick={() => hadleOpen(type)}>
                    {title}
                  </Button>
                );
              })}
            </ButtonGroup>
          }
          content={
            <>
              <Grid item xs={12}>
                <Stack direction="row" spacing={2} alignItems="center" mb={1}>
                  {getStatusIcon(data.status, theme)}
                  <Typography variant="h4" mb={1}>
                    {data.name}
                    {'     '}
                    <Typography fontWeight="bold" component="span">
                      Nip: {data.nip}
                    </Typography>
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <DetailsInfo details={data.details} />
              </Grid>
              <Grid item xs={12}>
                <AppCard>
                  <Typography>
                    Temin płatności i waluta : {data.paymentTerm} dni / {data.currency}{' '}
                  </Typography>
                </AppCard>
              </Grid>
              <Grid item xs={12}>
                <AppCard>
                  <Workers
                    clientMode={() => {
                      refetch();
                    }}
                  />
                </AppCard>
              </Grid>
              <Grid item xs={12}>
                <Collapse
                  label={`Orders (${data.orders.length})`}
                  positions={data.orders.map(({ id, client: { orderId } }) => ({
                    id,
                    number: orderId
                  }))}
                  linkName="cargo"
                />
              </Grid>
              <Grid item xs={12}>
                <Collapse
                  label={`Invoices (${data.invoices.length})`}
                  positions={data.invoices.map(({ id, number }) => ({ id, number }))}
                  linkName="invoices"
                />
              </Grid>
              {dialogProps && (
                <FullDialog
                  handleClose={handleClose}
                  open={Boolean(edit)}
                  title={dialogProps.title}
                >
                  <Stack rowGap={3}>
                    {dialogProps.chlidren}
                    <Button
                      disabled={formik.isSubmitting}
                      form="clientEditForm"
                      type="submit"
                      variant="contained"
                    >
                      {t('buttons.save')}
                    </Button>
                  </Stack>
                </FullDialog>
              )}
            </>
          }
        />
      </Form>
    </FormikProvider>
  );
};
