import { Grid, Typography, TextField, Autocomplete } from '@mui/material';
import { useFormikContext } from 'formik';
import { ICargo } from 'global';
import { useTranslation } from 'hooks/useTranslation';
import ClearIcon from '@mui/icons-material/Clear';
import { useGetClients } from 'hooks/useGetClients';
import { ErrorMessage } from 'components';

export const ClientData = (): JSX.Element => {
  const {
    values: {
      client: { name, id, orderId, dispo }
    },
    handleChange,
    errors,
    setFieldValue
  } = useFormikContext<ICargo>();
  const { t } = useTranslation();
  const { data } = useGetClients({});
  const clients = data || [];

  const workersOptions = clients.find((el) => el.name === name)?.workers.map((el) => el.name) || [];

  return (
    <Grid item xs={12} container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5" component="span">
          {t('subheaders.clientData')}
        </Typography>
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          fullWidth
          size="small"
          label={t('inputs.orderId')}
          name="client.orderId"
          value={orderId}
          onChange={handleChange}
          error={!!errors.client?.orderId}
        />
        <ErrorMessage name="client.orderId" />
      </Grid>
      <Grid item xs={12} md={4}>
        <Autocomplete
          options={clients.map((el) => ({ label: el.name, id: el.id }))}
          size="small"
          onChange={(event: any, newValue: { label: string; id: string } | null) => {
            if (newValue) {
              setFieldValue('client.name', newValue.label);
              setFieldValue('client.id', newValue.id);
            }
          }}
          clearIcon={
            <ClearIcon
              onClick={() => {
                setFieldValue('client.name', '');
                setFieldValue('client.id', '');
                setFieldValue('client.dispo', '');
              }}
            />
          }
          value={{ label: name, id }}
          renderInput={(params) => <TextField {...params} label={t('inputs.company')} />}
        />
        <ErrorMessage name="client.id" />
      </Grid>
      <Grid item xs={12} md={4}>
        <Autocomplete
          disabled={Boolean(!name)}
          options={workersOptions}
          size="small"
          onChange={(event: any, newValue: string | null) => {
            if (newValue) {
              setFieldValue('client.dispo', newValue);
            } else {
              setFieldValue('client.dispo', '');
            }
          }}
          value={dispo}
          renderInput={(params) => <TextField {...params} label={t('inputs.company')} />}
        />{' '}
        <ErrorMessage name="client.dispo" />
      </Grid>
    </Grid>
  );
};
