import { Grid, TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { useFormikContext } from 'formik';
import { userRoles } from 'App/permissions';
import { AppCard, ErrorMessage } from 'components';
import { ICompanyUser, UserStatus } from 'global';
import { useTranslation } from 'hooks/useTranslation';

interface MainInfoProps {
  edit: boolean;
}

export const MainInfo = ({ edit = false }: MainInfoProps): JSX.Element => {
  const {
    values: {
      role,
      password,
      status,
      details: { name, phone, email }
    },
    handleChange,
    errors
  } = useFormikContext<ICompanyUser>();
  const { t } = useTranslation();

  console.log(edit);

  return (
    <AppCard>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="name"
            name="details.name"
            value={name}
            onChange={handleChange}
            required
            // error={!!errors.price}
          />
          {/* <ErrorMessage name="price" /> */}
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="phone"
            name="details.phone"
            value={phone}
            onChange={handleChange}
            required
            // error={!!errors.price}
          />
          {/* <ErrorMessage name="price" /> */}
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="email"
            name="details.email"
            value={email}
            onChange={handleChange}
            required
          />
        </Grid>
        {edit && (
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="password"
              name="password"
              value={password}
              onChange={handleChange}
              required
            />
          </Grid>
        )}
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel>role</InputLabel>
            <Select value={role} name="role" label="role" onChange={handleChange}>
              {/* {Object.values(roles).map((el) => ( */}
              {Object.values(userRoles).map((el) => (
                <MenuItem key={el} value={el}>
                  {el}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel>status</InputLabel>
            <Select value={status} name="status" label="status" onChange={handleChange}>
              {Object.values(UserStatus).map((el) => (
                <MenuItem key={el} value={el}>
                  {el}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </AppCard>
  );
};
