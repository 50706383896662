import Clear from '@mui/icons-material/Clear';
import { Box, Button, Divider, Grid, IconButton, Stack, Typography } from '@mui/material';
import { nanoid } from '@reduxjs/toolkit';
import { useFormikContext } from 'formik';
import { AppCard } from 'components';
import { IInvoice, initialInvoiceDetalis } from 'global';
import { useTranslation } from 'hooks/useTranslation';
import { Position } from './Position';

interface MainInfoProps {
  edit?: boolean;
}

export const Positions = ({ edit = false }: MainInfoProps) => {
  const {
    values: { details },
    setFieldValue
  } = useFormikContext<IInvoice>();
  const { t } = useTranslation();

  const addNewPosition = () =>
    setFieldValue('details', [...details, { ...initialInvoiceDetalis, id: nanoid() }]);

  const removePostion = (id: string) =>
    setFieldValue(
      'details',
      details.filter((el) => el.id !== id)
    );

  return (
    <Grid item xs={12}>
      <AppCard>
        <Stack rowGap={4}>
          <Stack direction="row" justifyContent="space-between" alignItems="center" mb={1}>
            <Typography variant="h6" fontWeight="bold">
              Pozycje
            </Typography>
            <Button variant="outlined" onClick={addNewPosition}>
              dodaj
            </Button>
          </Stack>
          <Grid container spacing={2}>
            {details.map((el, index) => (
              <Grid key={el.id} item xs={12}>
                <Stack direction="row" alignItems="center">
                  <Box flexGrow={1}>
                    <Position position={el} />
                  </Box>
                  <Box width="40px" height="40px">
                    {index > 0 && (
                      <IconButton onClick={() => removePostion(el.id)}>
                        <Clear />
                      </IconButton>
                    )}
                  </Box>
                </Stack>
              </Grid>
            ))}
          </Grid>
        </Stack>
      </AppCard>
    </Grid>
  );
};
