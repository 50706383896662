import { Dayjs } from 'dayjs';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

interface DateAndTimePickerProps {
  value: Dayjs | null;
  handleChange: (newValue: Dayjs | null) => void;
  label: string;
}

export const DateAndTimePicker = ({ value, handleChange, label }: DateAndTimePickerProps) => (
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <DateTimePicker
      label={label}
      ampm={false}
      value={value}
      onChange={handleChange}
      renderInput={(params) => <TextField fullWidth {...params} />}
    />
  </LocalizationProvider>
);
