import {
  ClientsFilters,
  FiltersState,
  CarriersFilters,
  CarsFilters,
  DriversFilters,
  CargoFilters,
  CargoSearch,
  ShipmentsFilters,
  ShipmentsSearch,
  RoundsFilters,
  RoundsSearch
} from './filtersReducer.types';

export const clientsSearchCategory = {
  name: 'name',
  nip: 'nip'
};

export const carriersSearchCategory = {
  name: 'name',
  nip: 'nip'
};

export const initialClientSearchValues = {
  value: '',
  type: clientsSearchCategory.name
};

export const initialClientFilterValues: ClientsFilters = {
  status: ''
};

export const initialCarrierSearchValues = {
  value: '',
  type: clientsSearchCategory.name
};

export const initialCarrierFilterValues: CarriersFilters = {
  status: ''
};

export const initialCarsSearchValues = {
  plates: ''
};

export const initialCarsFilterValues: CarsFilters = {
  status: [],
  country: ''
};

export const initialDriversSearchValues = {
  name: ''
};

export const initialDriversFilterValues: DriversFilters = {
  status: ''
};

export const initialCargoFilterValues: CargoFilters = {
  status: '',
  guardian: ''
};

export const initialCargoSearchValues: CargoSearch = {};

export const initialShipmentsFilterValues: ShipmentsFilters = {};

export const initialShipmentsSearchValues: ShipmentsSearch = {};

export const initialRoundsFilterValues: RoundsFilters = {};

export const initialRoundsSearchValues: RoundsSearch = {};

export const initialState: FiltersState = {
  clientsSearch: initialClientSearchValues,
  clientsFilters: initialClientFilterValues,
  carriersSearch: initialCarrierSearchValues,
  carriersFilters: initialCarrierFilterValues,
  carsSearch: initialCarsSearchValues,
  carsFilters: initialCarsFilterValues,
  driversSearch: initialDriversSearchValues,
  driversFilters: initialDriversFilterValues,
  cargoFilters: initialCargoFilterValues,
  cargoSearch: initialCargoSearchValues,
  shipmentsFilters: initialShipmentsFilterValues,
  shipmentsSearch: initialShipmentsSearchValues,
  roundsFilters: initialRoundsFilterValues,
  roundsSearch: initialRoundsSearchValues
};
