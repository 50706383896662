import { styled } from '@mui/material/styles';
import { Drawer as MuiDrawer, Theme } from '@mui/material';

const drawerWidth = 240;

export const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
  theme: Theme;
}>(({ open, theme }) => ({
  flexGrow: 1,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),

  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginRight: open ? drawerWidth : 0
  })
}));

export const Drawer = styled(MuiDrawer)<{
  theme: Theme;
}>(({ theme }) => ({
  width: drawerWidth,
  '& .MuiDrawer-paper': {
    width: drawerWidth,
    padding: '24px',
    position: 'absolute',
    border: `1px solid ${theme.palette.grey[400]}`
  }
}));
