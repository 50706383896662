import { AdditionalCarStatus, CarBody, CarStatus, CarType, ICar } from 'global';

export const initialCar: ICar = {
  carId: '',
  id: '',
  company: '',
  plates: '',
  guardian: {
    id: '',
    name: ''
  },
  createdAt: new Date(),
  updatedAt: new Date(),
  addedBy: '',
  status: 'empty' as CarStatus,
  additionalStatus: 'pause' as AdditionalCarStatus,
  details: {
    type: 'bus' as CarType,
    body: 'plane' as CarBody,
    capacity: {
      carWeight: '',
      cargoCapacity: ''
    },
    dimensions: {
      height: '',
      length: '',
      width: '',
      palletsCapacity: '',
      ldm: ''
    },
    costPerKm: {
      value: '',
      currency: 'EUR'
    },
    averageFuelConsumption: '',
    comments: '',
    counterStatus: ''
  },
  loads: [],
  position: {
    coordinates: {
      lat: 0,
      lng: 0
    },
    company: '',
    country: { name: '', code: '' },
    postcode: '',
    city: '',
    adressLine1: '',
    adressLine2: ''
  },
  rounds: [],
  documents: {
    ocp: '',
    ocpTo: null,
    overviewAt: '',
    technicalTill: null
  },
  accessories: []
};
