import { DriverStatus } from 'global';
import { Chip } from '@mui/material';
import { Translation } from 'hooks/useTranslation';

const getChipColor = (status: DriverStatus) => {
  switch (true) {
    case status === DriverStatus.active:
      return 'success';
    case status === DriverStatus.inactive:
      return 'error';
    default:
      return 'primary';
  }
};

export const getStatusChip = (status: DriverStatus, t: Translation) => (
  <Chip color={getChipColor(status)} label={t(`driverStatus.${status}`)} sx={{ width: '100%' }} />
);
