import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICompanyUser } from 'global';
import { initialState } from './userReducer.const';

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<ICompanyUser>) => {
      return { ...state, user: action.payload };
    }
  }
});

export const { setUser } = userSlice.actions;

export default userSlice.reducer;
