import { useRef, useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useTranslation } from 'hooks/useTranslation';
import { AddEditElement } from './_AddEditElement';
import { ActionDialogProps } from './_partials.types';

export const ActionDialog = ({
  handleClose,
  open,
  title,
  editData,
  isLoading
}: ActionDialogProps): JSX.Element => {
  const descriptionElementRef = useRef<HTMLElement>(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);
  const { t } = useTranslation();

  return (
    <Dialog
      PaperProps={{
        style: {
          width: '95%',
          minWidth: '95%',
          height: '95%',
          minHeight: '95%'
        }
      }}
      open={open}
      onClose={handleClose}
      scroll="paper"
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>
        <AddEditElement editData={editData} isLoading={isLoading} handleClose={handleClose} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t('buttons.back')}</Button>
        <Button variant="contained" type="submit" form="addPlace">
          {t('buttons.save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
