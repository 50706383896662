import { useFormikContext } from 'formik';
import { ICompany } from 'global';
import { useTranslation } from 'hooks/useTranslation';
import { Grid, TextField, Typography } from '@mui/material';

interface CorespondenceProps {
  disabled: boolean;
}

export const Corespondence = ({ disabled }: CorespondenceProps) => {
  const {
    values: {
      details: {
        correspondenceAdress: { adressLine1, adressLine2, postcode, city, country }
      }
    },
    handleChange
  } = useFormikContext<ICompany>();
  const { t } = useTranslation();

  return (
    <Grid item xs={12}>
      <Grid>
        <Typography mb={1} fontWeight="bold">
          Dane do korespondencji
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              size="small"
              label={t('inputs.adressLine1')}
              name="details.correspondenceAdress.adressLine1"
              value={adressLine1}
              onChange={handleChange}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              size="small"
              label={t('inputs.adressLine2')}
              name="details.correspondenceAdress.adressLine2"
              value={adressLine2}
              onChange={handleChange}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              fullWidth
              size="small"
              label={t('inputs.postcode')}
              name="details.correspondenceAdress.postcode"
              value={postcode}
              onChange={handleChange}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              fullWidth
              size="small"
              label={t('inputs.city')}
              name="details.correspondenceAdress.city"
              value={city}
              onChange={handleChange}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              fullWidth
              size="small"
              label={t('inputs.country')}
              name="details.correspondenceAdress.country"
              value={country}
              onChange={handleChange}
              disabled={disabled}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
