import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { CargoData, CargoEvent, ICargo, IShipment } from 'global';
import { useGetCargo } from 'hooks/useGetCargo';
import { useFormikContext } from 'formik';

interface Props {
  label: string;
  placeholder: string;
  disabled: boolean;
}

export const MultipleAutocomplete = ({ label, placeholder, disabled }: Props) => {
  const { data } = useGetCargo({});
  const { setFieldValue } = useFormikContext<IShipment>();

  // const cargos = useAppSelector((store) => store.cargo.cargo);
  // .filter(
  //   (el) => el.status !== CargoStatus.
  // );

  const handleCargos = (items: ICargo[]) => {
    const order: { loading: CargoData[]; unloading: CargoData[] } = {
      loading: [],
      unloading: []
    };

    items.forEach((item) => {
      item.events.forEach((el) => {
        if (el.type === CargoEvent.loading) {
          order.loading.push(el);
        } else {
          order.unloading.push(el);
        }
      });
    });

    setFieldValue('eventsOrder', order);
    setFieldValue('cargo', items);
  };

  return (
    <Autocomplete
      multiple
      disabled={disabled}
      size="small"
      options={data || []}
      disableCloseOnSelect
      onChange={(_: any, newValue: ICargo[]) => {
        handleCargos(newValue);
      }}
      getOptionLabel={(cargo: ICargo) => `${cargo.client.orderId} (${cargo.client.name})`}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            checkedIcon={<CheckBoxIcon fontSize="small" />}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {`${option.client.orderId} (${option.client.name})`}
        </li>
      )}
      fullWidth
      renderInput={(params) => <TextField {...params} label={label} placeholder={placeholder} />}
    />
  );
};
