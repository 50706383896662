export enum UserStatus {
  active = 'active',
  inactive = 'inactive'
}

export enum Tiers {
  trial = 'trial',
  basic = 'basic',
  pro = 'pro',
  full = 'full'
}

export enum Payment {
  monthly = 'monthly',
  quarter = 'quarter',
  year = 'year'
}

export enum CompanyType {
  spedition = 'spedition',
  transport = 'transport',
  both = 'both'
}

export enum PeopleCount {
  'six' = '6',
  'twelve' = '12',
  'eighteen' = '18',
  'twentyFour' = '24',
  'more' = '24+'
}

export enum CarsCount {
  'fiveteen' = '15',
  'thirty' = '30',
  'fourtyFive' = '45',
  'sixty' = '60',
  'more' = '60+'
}

export enum CompanyStatus {
  active = 'active',
  inactive = 'inactive'
}

export enum Role {
  driver = 'driver',
  user = 'user',
  admin = 'admin',
  accountant = 'accountant'
}
