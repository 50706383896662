import { Stack } from '@mui/material';
import { Driver } from './Partials/Driver';

interface AddDriverFormProps {
  edit?: boolean;
}

export const AddDriverForm = ({ edit = false }: AddDriverFormProps) => (
  <Stack rowGap={3}>
    <Driver edit={edit} />
  </Stack>
);
