import axios from 'axios';
import { IComission } from 'global';
import { toast } from 'react-hot-toast';
import authHeader from './auth-header';
import { servicesEnv } from './const';

const API_URL = `${servicesEnv}v1/api/comission`;

class ComissionService {
  addComission = async (shipmentId: string, comission: IComission): Promise<boolean> => {
    const res = await axios.post(`${API_URL}/${shipmentId}`, comission, { headers: authHeader() });

    if (res.data.error) {
      toast.error(res.data.error);
      return false;
    }

    return true;
  };
  editComission = async (comission: IComission): Promise<boolean> => {
    const res = await axios.patch(`${API_URL}/${comission.id}`, comission, {
      headers: authHeader()
    });

    if (res.data.error) {
      toast.error(res.data.error);
      return false;
    }

    return true;
  };
}
export default new ComissionService();
