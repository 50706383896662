import {
  Collapse as MuiCollapse,
  Grid,
  Stack,
  Typography,
  TableRow,
  TableCell,
  useTheme
} from '@mui/material';
import { StandardTable } from 'components';
import { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { IShipment } from 'global';
import { Translation, useTranslation } from 'hooks/useTranslation';
import Card from '@mui/material/Card';
import { tableRowsColor } from 'global/utils/_colors';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { Link } from 'react-router-dom';

interface CollapseProps {
  shipments: IShipment[];
}

export const tableHeaders = (t: Translation): string[] => ['id', ''];

export const Shipments = ({ shipments }: CollapseProps): JSX.Element => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Grid item xs={12}>
      <Card variant="outlined">
        <Stack direction="row" alignItems="center">
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
          <Typography>
            {t('headers.shipments')} ({shipments.length})
          </Typography>
        </Stack>
        <MuiCollapse in={open} timeout="auto" unmountOnExit>
          <StandardTable
            headers={() => tableHeaders(t)}
            items={shipments.map((shipment, index) => {
              const { id } = shipment;

              return (
                <TableRow key={id} sx={tableRowsColor(index, theme)}>
                  <TableCell component="th" scope="row" width="25%">
                    {id}
                  </TableCell>
                  <TableCell component="th" scope="row" align="right" width="5%">
                    <Link to={`../shipments/${id}`} rel="noopener noreferrer">
                      <ManageSearchIcon />
                    </Link>
                  </TableCell>
                </TableRow>
              );
            })}
          />
        </MuiCollapse>
      </Card>
    </Grid>
  );
};
