import { useRef, useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { IWorker } from 'global';
import { useTranslation } from 'hooks/useTranslation';
import { useFormikContext } from 'formik';
import { AlertMessage } from 'components';
import { WorkerForm } from './_WorkerForm';

interface ActionDialogProps {
  handleClose: () => void;
  open: boolean;
  title: string;
  editData?: IWorker;
  handleAddEditClient: (worker: IWorker) => void;
}

export const ActionDialog = ({
  handleClose,
  open,
  title,
  editData = undefined,
  handleAddEditClient
}: ActionDialogProps) => {
  const descriptionElementRef = useRef<HTMLElement>(null);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);
  const { t } = useTranslation();

  const { values, resetForm } = useFormikContext<IWorker>();

  return (
    <Dialog
      PaperProps={{
        style: {
          width: '95%',
          minWidth: '95%',
          height: '45%',
          minHeight: '45%'
        }
      }}
      open={open}
      onClose={handleClose}
      scroll="paper"
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>
        <WorkerForm editData={editData} />
        {error && <AlertMessage message="Wypełnij wszystkie pola" status="error" />}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            resetForm();
            handleClose();
          }}
        >
          {t('buttons.back')}
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            // validation
            if (!values.email || !values.name || !values.phone) {
              setError(true);
              return;
            }
            handleAddEditClient(values);
            resetForm();
            handleClose();
          }}
        >
          {t('buttons.save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
