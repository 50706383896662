import axios from 'axios';
import { servicesEnv } from './const';

const API_URL = `${servicesEnv}v1/api/auth/`;

interface LoginProps {
  email: string;
  password: string;
}
class AuthService {
  async login(data: LoginProps): Promise<boolean> {
    const res = await axios.post(`${API_URL}login`, data);

    if (!res) return false;

    if (res.data.tokens.access.token) {
      localStorage.setItem('cc', JSON.stringify(res.data.tokens.access.token));
    }
    if (res.data.tokens.refresh.token) {
      localStorage.setItem('cc-refresh', JSON.stringify(res.data.tokens.refresh.token));
    }

    return true;
  }
  async logout(refreshToken: string): Promise<void> {
    await axios.post(`${API_URL}logout`, { refreshToken });
  }

  async editPassword(password: string): Promise<boolean> {
    const refreshToken = localStorage.getItem('cc-refresh');
    const res = await axios.post(`${API_URL}reset-password?token=${refreshToken}`, { password });

    if (res) return true;

    return false;
  }

  async forgotPassword(email: string): Promise<boolean> {
    const res = await axios.post(`${API_URL}forgot-password`, { email });
    if (res) return true;

    return false;
  }
  async refreshToken(): Promise<boolean> {
    const refreshToken = localStorage.getItem('cc-refresh');
    const res = await axios.post(`${API_URL}refresh-tokens`, { refreshToken });

    if (!res) return false;

    if (res.data.tokens.access.token) {
      localStorage.setItem('cc', JSON.stringify(res.data.tokens.access.token));
    }
    if (res.data.tokens.refresh.token) {
      localStorage.setItem('cc-refresh', JSON.stringify(res.data.tokens.refresh.token));
    }

    return true;
  }
}
export default new AuthService();
