import { Grid, Typography, TextField } from '@mui/material';
import { useFormikContext } from 'formik';
import { ICargo } from 'global';
import { useTranslation } from 'hooks/useTranslation';

export const Documents = () => {
  const {
    values: {
      documents: { order, cmr, other }
    },
    handleChange
  } = useFormikContext<ICargo>();
  const { t } = useTranslation();

  // const pickedCarrier = carriers.find((el) => el.name === carrierCompany);

  // const onChange = (event: React.ChangeEvent) => {
  //   const target = event.target as HTMLInputElement;
  //   const file: File = (target.files as FileList)[0];
  //   setFieldValue('order', file);
  // };

  return (
    <Grid item xs={12} container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5" component="span">
          {t('subheaders.documents')}
        </Typography>
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField size="small" fullWidth label={t('inputs.order')} disabled />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField size="small" fullWidth label={t('inputs.cmr')} disabled />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField size="small" fullWidth label={t('inputs.others')} disabled />
      </Grid>
    </Grid>
  );
};
