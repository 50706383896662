import { Translation } from 'hooks/useTranslation';
import * as Yup from 'yup';

export const validationSchema = (t: Translation) => {
  return Yup.object().shape({
    email: Yup.string().required(t('required')),
    password: Yup.string().required(t('required'))
  });
};

export const initialValues = {
  email: '',
  password: ''
};
