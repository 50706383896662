import { Link } from 'react-router-dom';
import { IShipment } from 'global';
import { DateTranslation, Translation } from 'hooks/useTranslation';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { FormattedNumber } from 'react-intl';

export const tableHeaders = (t: Translation) => [
  '',
  t('inputs.number'),
  t('inputs.guardian'),
  t('inputs.plates'),
  t('inputs.price')
];

export const tableCells = (shipment: IShipment, d: DateTranslation, t: Translation) => {
  const {
    number,
    guardian: { name },
    car: { plates },
    price: { value, currency }
  } = shipment;

  return [
    {
      value: (
        <Link to={`/shipments/${shipment.id}`} rel="noopener noreferrer">
          <ManageSearchIcon />
        </Link>
      ),
      width: '1%'
    },
    {
      value: number,
      width: '10%'
    },
    {
      value: name,
      width: '10%'
    },
    {
      value: plates,
      width: '69%'
    },
    {
      value: (
        <FormattedNumber
          value={Number(value)}
          // eslint-disable-next-line
          style="currency"
          currency={currency}
        />
      ),
      width: '5%'
    }
  ];
};
