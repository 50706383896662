import { TableCell, TableRow, useTheme } from '@mui/material';
import { nanoid } from '@reduxjs/toolkit';
import { useTranslation } from 'hooks/useTranslation';
import { ICompanyUser, tableRowsColor } from 'global';
import { DialogTypes } from '../../UserManagement.types';
import { tableCells } from './UsersTable.const';

interface RowProps {
  user: ICompanyUser;
  index: number;
  handleOpen: (dialogType: DialogTypes, data?: ICompanyUser) => void;
}

export const Row = ({ user, index, handleOpen }: RowProps): JSX.Element => {
  const { t } = useTranslation();
  const theme = useTheme();
  const cells = tableCells(handleOpen, user, t, theme);

  return (
    <TableRow sx={tableRowsColor(index, theme)}>
      {cells.map(({ value, width }, cellIndex) => (
        <TableCell
          key={nanoid()}
          component="th"
          scope="row"
          align={cellIndex === cells.length - 1 ? 'right' : 'inherit'}
          sx={{ width }}
        >
          {value}
        </TableCell>
      ))}
    </TableRow>
  );
};
