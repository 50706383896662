import { Dayjs } from 'dayjs';
import { Form, FormikProvider, useFormik, useFormikContext } from 'formik';
import { CountrySelect, DateAndTimePicker, ErrorMessage } from 'components';
import {
  CargoData,
  CargoEvent,
  findPosition,
  ICargo,
  initialCargoAction,
  initialCargoDetails
} from 'global';
import { useTranslation } from 'hooks/useTranslation';
import { Grid, TextField, Typography, Button } from '@mui/material';
import { nanoid } from '@reduxjs/toolkit';
import { loadingUnloadingValidationSchema } from 'pages/Cargo/Cargo.const';
import { useAppSelector } from 'store/hooks';
import { Details } from './_Details';
import { AutocompleteEvent } from './AutocompleteEvent';
import { AddEditElementProps } from './_partials.types';

export const AddEditElement = ({ editData, isLoading, handleClose }: AddEditElementProps) => {
  const { t } = useTranslation();
  const companyId = useAppSelector((store) => store.company.company?.id) || '';
  const formik = useFormik<CargoData>({
    initialValues: editData || { ...initialCargoAction, cargoDataId: nanoid(), company: companyId },
    validationSchema: loadingUnloadingValidationSchema(t),
    onSubmit: (values) => {
      handleSubmit(values);
    }
  });

  const {
    values: { events },
    setFieldValue
  } = useFormikContext<ICargo>();

  const handleSubmit = async (values: CargoData) => {
    const data = { ...values };
    // const newCoordinates = await findPosition(values.position.adressLine1, values.position.city);

    // if (newCoordinates) {
    //   data.position.coordinates = newCoordinates;
    // }

    if (editData) {
      const index = events.findIndex((el) => el.id === data.id);
      const newArray = [...events];
      newArray.splice(index, 1, data);
      setFieldValue('events', newArray);
    } else {
      setFieldValue('events', [
        ...events,
        { ...data, type: isLoading ? CargoEvent.loading : CargoEvent.unloading }
      ]);
    }
    handleClose();
  };

  const setCountry = (country: string, code: string) =>
    formik.setFieldValue(`position.country`, { name: country, code });
  const setTimeMin = (newValue: Dayjs | null) => formik.setFieldValue(`timeMin`, newValue);
  const setTimeMax = (newValue: Dayjs | null) => formik.setFieldValue(`timeMax`, newValue);

  const {
    handleChange,
    values: { position, timeMin, timeMax, details }
  } = formik;

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit} id="addPlace">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <AutocompleteEvent />
          </Grid>
          <Grid item xs={12}>
            <Typography component="span" variant="h5">
              {t('subheaders.mainInfo')}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label={t('details.company')}
              value={position.company}
              name="position.company"
              onChange={handleChange}
              error={!!formik.errors.position?.company}
            />
            <ErrorMessage name="position.company" />
          </Grid>
          <Grid item xs={12} md={2}>
            <CountrySelect
              value={position.country.name}
              label={t('details.country')}
              setCountry={setCountry}
            />
            <ErrorMessage name="position.country.name" />
          </Grid>
          <Grid item xs={12} md={2}>
            <TextField
              fullWidth
              label={t('details.postcode')}
              value={position.postcode}
              name="position.postcode"
              onChange={handleChange}
              error={!!formik.errors.position?.postcode}
            />
            <ErrorMessage name="position.postcode" />
          </Grid>
          <Grid item xs={12} md={2}>
            <TextField
              fullWidth
              label={t('details.city')}
              value={position.city}
              name="position.city"
              onChange={handleChange}
              error={!!formik.errors.position?.city}
            />
            <ErrorMessage name="position.city" />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label={t('details.adressLine1')}
              value={position.adressLine1}
              name="position.adressLine1"
              onChange={handleChange}
              error={!!formik.errors.position?.adressLine1}
            />
            <ErrorMessage name="position.adressLine1" />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label={t('details.adressLine2')}
              value={position.adressLine2}
              name="position.adressLine2"
              error={!!formik.errors.position?.adressLine2}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <DateAndTimePicker
              value={timeMin}
              handleChange={setTimeMin}
              label={t(`inputs.timeMin`)}
            />
            <ErrorMessage name="timeMin" />
          </Grid>
          <Grid item xs={12} md={2}>
            <DateAndTimePicker
              value={timeMax}
              handleChange={setTimeMax}
              label={t(`inputs.timeMax`)}
            />
            <ErrorMessage name="timeMax" />
          </Grid>
        </Grid>
        <Grid container mt={2} rowGap={1}>
          <Grid item xs={12}>
            <Button
              variant="contained"
              onClick={() => {
                const newArray = [...details];
                newArray.push({ ...initialCargoDetails, id: nanoid() });
                formik.setFieldValue('details', newArray);
              }}
            >
              {t('inputs.addMore')}
            </Button>
          </Grid>
          <Grid item xs={12} container rowGap={1}>
            {details.map((el) => (
              <Details key={el.id} data={el} />
            ))}
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
};
