import { StandardTable } from 'components';
import { ICompanyUser } from 'global';
import { Row } from './Row';
import { tableHeaders } from './UsersTable.const';
import { DialogTypes } from '../../UserManagement.types';

interface UsersTableProps {
  users: ICompanyUser[];
  handleOpen: (dialogType: DialogTypes, data?: ICompanyUser) => void;
}

export const UsersTable = ({ users, handleOpen }: UsersTableProps) => {
  const items = users.map((user, index) => (
    <Row key={user.userId} user={user} index={index} handleOpen={handleOpen} />
  ));

  return <StandardTable headers={tableHeaders} items={items} />;
};
