import toast, { useToaster, Toast, ToastType } from 'react-hot-toast';
import { Stack, IconButton, Alert, AlertColor } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

const getSeverity = (type: ToastType): AlertColor =>
  ['success', 'error'].includes(type) ? (type as AlertColor) : 'info';

const getOnlyVisibleToast = (toasts: Toast[]): Toast[] =>
  toasts.filter((singleToast) => singleToast.visible);

export const AppToast = (): JSX.Element => {
  const { toasts, handlers } = useToaster({ duration: 2000 });
  const { startPause, endPause } = handlers;

  return (
    <Stack
      spacing={2}
      onMouseLeave={endPause}
      onMouseEnter={startPause}
      sx={{ position: 'fixed', bottom: 20, left: 20, zIndex: 9999 }}
    >
      {getOnlyVisibleToast(toasts).map(({ id, type, message, ariaProps }) => (
        <Alert
          key={id}
          action={
            <IconButton size="small" color="inherit" onClick={() => toast.remove(id)}>
              <ClearIcon fontSize="inherit" />
            </IconButton>
          }
          elevation={6}
          severity={getSeverity(type)}
          {...ariaProps}
        >
          {String(message)}
        </Alert>
      ))}
    </Stack>
  );
};
