import { Button, Breadcrumbs, Typography } from '@mui/material';
import { useState } from 'react';
import { FullDialog, StandardLayout } from 'components';
import { useFormik, FormikProvider, Form } from 'formik';
import { IClient, IInitialClient, initialClient, initialClientFormValues } from 'global';
import { useTranslation } from 'hooks/useTranslation';
import clientService from 'services/client.service';
import { toast } from 'react-hot-toast';
import { useGetClients } from 'hooks/useGetClients';
import { useGetTokenData } from 'hooks/useGetTokenData';
import { validationSchema } from './components/AddClient/AddClient.const';
import { Search, AddClient, ClientsTable } from './components';
import { setMainValues } from './Clients.helpers';

export const Clients = (): JSX.Element => {
  const [openAdding, setOpenAdding] = useState(false);

  const handleOpen = () => setOpenAdding(true);
  const handleClose = () => {
    setOpenAdding(false);
    formik.resetForm();
  };
  const { t } = useTranslation();
  const { refetch } = useGetClients({});
  const token = useGetTokenData();

  const formik = useFormik<IInitialClient>({
    initialValues: initialClientFormValues,
    validationSchema: validationSchema(t),
    onSubmit: async (values) => {
      const clientData = setMainValues(values, token);

      const res = await clientService.addClient(clientData).catch(() => {
        toast.error('Somethnig went wrong with adding the client');
      });

      if (!res) return;

      toast.success('Client added');
      formik.resetForm();
      handleClose();
      refetch();
    }
  });

  return (
    <StandardLayout
      breadcrumbs={
        <Breadcrumbs>
          <Typography fontWeight="bold" color="text.primary">
            {t('headers.clients')}
          </Typography>
        </Breadcrumbs>
      }
      search={<Search />}
      button={
        <Button variant="contained" onClick={handleOpen}>
          {t('buttons.addClient')}
        </Button>
      }
      content={
        <>
          {openAdding && (
            <FullDialog handleClose={handleClose} open={openAdding} title={t('buttons.addClient')}>
              <FormikProvider value={formik}>
                <Form onSubmit={formik.handleSubmit} id="clientAddForm">
                  <AddClient />
                </Form>
              </FormikProvider>
            </FullDialog>
          )}
          <ClientsTable />
        </>
      }
    />
  );
};
