import { toast } from 'react-hot-toast';
import { UseQueryResult, useQuery } from 'react-query';
import clientService from 'services/client.service';
import { useAppSelector } from 'store/hooks';
import { useEffect } from 'react';
import { HookProps, IClient } from 'global';
import { clientsSearchCategory } from 'store/reducers/filtersReducer';
import { useTranslation } from './useTranslation';

export const useGetClients = ({
  page = 1,
  rowsPerPage = 20
}: HookProps): UseQueryResult<IClient[], unknown> => {
  const { t } = useTranslation();
  const filters = useAppSelector((store) => store.filters.clientsFilters);
  const search = useAppSelector((store) => store.filters.clientsSearch);

  const companyId = useAppSelector((store) => store.company.company?.id) || '';

  const query = useQuery(
    ['clients', companyId],
    () =>
      clientService.getClients({
        name: search.type === clientsSearchCategory.name ? search.value : '',
        nip: search.type === clientsSearchCategory.nip ? search.value : '',
        status: filters.status,
        company: companyId,
        sortBy: '',
        projectBy: '',
        limit: rowsPerPage,
        page
      }),
    {
      refetchOnWindowFocus: false,
      onSuccess() {},
      onError() {
        toast.error(t('errors.getData'));
      }
    }
  );

  useEffect(() => {
    query.refetch();
    // eslint-disable-next-line
  }, [filters, search, page, rowsPerPage]);

  return query;
};
