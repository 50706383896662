import { Stack } from '@mui/material';

import { MainInfo, LoadingUnloadingPlace } from './partials';

export const AddCargoForm = (): JSX.Element => (
  <Stack rowGap={3}>
    <MainInfo />
    <LoadingUnloadingPlace isLoading />
    <LoadingUnloadingPlace />
  </Stack>
);
