import { Card } from '@mui/material';

interface AppCardProps {
  children: JSX.Element;
  fullHeight?: boolean;
}

export const AppCard = ({ children, fullHeight = false }: AppCardProps): JSX.Element => (
  <Card variant="outlined" sx={{ p: 2, height: fullHeight ? '100%' : 'inherit' }}>
    {children}
  </Card>
);
