import { Typography, Stack } from '@mui/material';
import { Link } from 'react-router-dom';
import { IShipment, getFromToTimes } from 'global';
import { Translation } from 'hooks/useTranslation';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { Options } from './Board.helpers';

export const tableHeaders = (t: Translation) => ['Shipment', 'place', ''];

export const tableCells = (item: IShipment, type: Options) => [
  {
    value: item.number,
    width: '10%'
  },
  {
    value: item.eventsOrder[type].map((el) => (
      <Stack direction="row" justifyContent="space-between">
        <Typography>
          {el.position.company},{el.position.postcode},{el.position.adressLine1}
        </Typography>
        <Stack>
          <Typography variant="caption">{getFromToTimes(el.timeMin, el.timeMax)}</Typography>
        </Stack>
      </Stack>
    )),
    width: '85%'
  },
  {
    value: (
      <Link to={`/shipments/${item.id}`}>
        <ManageSearchIcon />
      </Link>
    ),
    width: '5%'
  }
];
