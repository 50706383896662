import { toast } from 'react-hot-toast';
import { UseQueryResult, useQuery } from 'react-query';
import driverService from 'services/driver.service';
import { IDriver, HookProps } from 'global';
import { useAppSelector } from 'store/hooks';
import { useEffect } from 'react';
import { useTranslation } from './useTranslation';

export const useGetDrivers = ({
  page = 1,
  rowsPerPage = 20
}: HookProps): UseQueryResult<IDriver[], unknown> => {
  const { t } = useTranslation();
  const filters = useAppSelector((store) => store.filters.driversFilters);
  const search = useAppSelector((store) => store.filters.driversSearch);

  const companyId = useAppSelector((store) => store.company.company?.id) || '';

  const query = useQuery(
    'drivers',
    () =>
      driverService.getDrivers({
        name: search.name,
        status: filters.status,
        company: companyId,
        sortBy: '',
        projectBy: '',
        limit: rowsPerPage,
        page
      }),
    {
      onSuccess() {},
      onError() {
        toast.error(t('errors.getData'));
      }
    }
  );

  useEffect(() => {
    query.refetch();
    // eslint-disable-next-line
  }, [filters, search, page, rowsPerPage]);

  return query;
};
