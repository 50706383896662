import { TableCell, TableRow, useTheme } from '@mui/material';
import { ICar, tableRowsColor } from 'global';
import { nanoid } from '@reduxjs/toolkit';
import { useTranslation } from 'hooks/useTranslation';
import { tableCells } from './FleetTable.const';
import { FleetCarInfoActions } from './CarInfo/CarInfo.helpers';

interface RowProps {
  car: ICar;
  index: number;
  handleOpen: (car: ICar, type: FleetCarInfoActions | null) => void;
}

export const Row = ({ car, index, handleOpen }: RowProps): JSX.Element => {
  const { d, t } = useTranslation();
  const theme = useTheme();
  const cells = tableCells(car, t, handleOpen, d, theme);

  return (
    <TableRow sx={tableRowsColor(index, theme)}>
      {cells.map(({ value, width }, cellIndex) => (
        <TableCell
          key={nanoid()}
          align={cellIndex === cells.length - 1 ? 'right' : 'inherit'}
          component="th"
          scope="row"
          sx={{ width }}
        >
          {value}
        </TableCell>
      ))}
    </TableRow>
  );
};
