import { Button, Box } from '@mui/material';
import { FormikProvider, useFormik } from 'formik';
import { findPosition, ICar } from 'global';
import { useTranslation } from 'hooks/useTranslation';
import carService from 'services/car.service';
import { toast } from 'react-hot-toast';
import { validationSchema } from 'pages/Fleet/components/AddCar/index';
import { FleetCarInfoActions, fleetCarInfoActions } from './CarInfo.helpers';

interface CarInfoFormProps {
  car: ICar;
  type: FleetCarInfoActions;
  handleClose: () => void;
}

export const CarInfoForm = ({ car, type, handleClose }: CarInfoFormProps) => {
  const { t } = useTranslation();

  const formik = useFormik<ICar>({
    initialValues: car,
    validationSchema: validationSchema(t),
    onSubmit: async (values) => {
      const editedCar = { ...values };

      if (type === 'editPosition') {
        const newCoordinates = await findPosition(car.position.adressLine1, car.position.city);
        if (newCoordinates) {
          editedCar.position = { ...values.position, coordinates: newCoordinates };
        } else {
          editedCar.position = { ...values.position, coordinates: { lat: 0, lng: 0 } };
        }
      }

      const res = await carService.editCar(editedCar).catch(() => {
        toast.error(t('errors.editCar'));
      });

      if (!res) return;
      handleClose();
    }
  });

  const options = fleetCarInfoActions(t);

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit} id="fleetCarInfoEdit">
        {options.find((el) => el.type === type)?.children}
      </form>
      <Box textAlign="right" mt={1}>
        <Button form="fleetCarInfoEdit" type="submit" variant="contained">
          {t('buttons.save')}
        </Button>
      </Box>
    </FormikProvider>
  );
};
