import { Convert } from 'easy-currencies';
import { toast } from 'react-hot-toast';

export const convertCurrency = async (
  value: string,
  from: string,
  to: string
): Promise<number | void> => {
  const result = await Convert(Number(value))
    .from(from)
    .to(to)
    .catch(() => {
      toast('error with convert currency');
    });

  return result;
};
